import { EMPTY, MAX } from 'constant';

const ellipsized = (message: string) => {
  return message.length > MAX.ELLIPSIS
    ? `${message.slice(EMPTY.NUMBER, MAX.ELLIPSIS)}...`
    : message;
};

const getWindowDimensions = () => {
  const hasWindow = typeof window !== 'undefined';

  const width = hasWindow ? window.innerWidth : null;
  const height = hasWindow ? window.innerHeight : null;
  return {
    width,
    height,
  };
};

const isArrayObjects = (arr:any) => {
  for (let i = 0; i < arr.length; i++) {
    if (typeof arr[i] !== 'object' || arr[i] === null || Array.isArray(arr[i])) {
      return false;
    }
  }
  return true;
};


/* 
Note
1. For date, you should convert the value (in their parent) to 'YYYY-MM-DD' 
or any other format (not recommended to compare with dayjs format). 
*/
const getIsDifferentData = (objOne: any, objTwo: any, propertyNames: Array<string>) => {
  let isDifferent = false;
  for (let propName of propertyNames) {
    if (Array.isArray(objOne[propName]) && Array.isArray(objTwo[propName])){
      if (!isArrayObjects(objOne[propName])){
        if (objOne[propName].length !== objTwo[propName].length) {
          isDifferent = true;
        } else {
          const sortedArrOne = objOne[propName].slice().sort();
          const sortedArrTwo = objTwo[propName].slice().sort();

          for (let i = 0; i < sortedArrOne.length; i++) {
            if (sortedArrOne[i] !== sortedArrTwo[i]) {
              isDifferent = true;
            }
          }
        }
      }
    } else {
      if (objOne[propName] !== objTwo[propName]) {
        isDifferent = true; 
      }
    }
  }
  return isDifferent;
};

const getBorderAttachmentStep = (data: any) => {
  switch (data) {
    case 1:
      return 'border-zinc-400';
    case 2:
      return 'border-red-400';
    case 3:
      return 'border-green-400';
  }
};

const getColorAttachmentStep = (data: any) => {
  switch (data) {
    case 1:
      return '#bbbbbb';
    case 2:
      return '#ED3F3F';
    case 3:
      return '#55A853';
  }
};

const downloadFile = (file: any) => {
  let csvURL = window.URL.createObjectURL(file);
  let tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', `${file?.name}`);
  tempLink.click();
};

const downloadFileUrl = (url: any) => {
  window.open(url);
};

const hexToRgba = (hex: string, alpha: number = 1): string => {
  hex = hex.replace('#', '');
  if (hex.length === 3) {
    hex = hex.split('').map(char => char + char).join('');
  }
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const getStyleByColor = (color: string) => {
  return {
    backgroundColor: hexToRgba(color, 0.2),
    borderColor: color,
    color: color,
  };
};

const getStyleBadge = (value: string, dataColor: any[]) => {
  const foundData = dataColor.find(data => value.toLowerCase().includes(data.value.toLowerCase()));
  return foundData
    ? getStyleByColor(foundData.color) : {};
};

const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength) + '...';
  }
};  

export { 
  ellipsized, 
  getWindowDimensions, 
  getIsDifferentData, 
  getBorderAttachmentStep, 
  getColorAttachmentStep,
  downloadFile,
  downloadFileUrl,
  hexToRgba,
  getStyleByColor,
  getStyleBadge,
  truncateText
};