import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { en } from './en';
import { id } from './id';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en
      },
      id: {
        translation: id
      }
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });