import { Button, Form } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Toast from 'components/Toast';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router';
import { usePostAddActionOnEditObligationMutation } from 'api/PermitMonitoring';
import { transformError } from 'utils/ErrorTransformer';
import ObligationFormItem from '../../ObligationFormItem';

const PermitMonitoringObligationAddActionOnEditForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const { obligationId } = useParams();
  const initialForm = {
    name: '',
    actions: [
      {
        name: '',
      },
    ],
  };

  const [postAddAction, {
    isError: isErrorPostAddAction,
    error: errorPostAddAction,
    isLoading: isLoadingProductDocObligations 
  }] = usePostAddActionOnEditObligationMutation();

  const addAction = () => {
    const actions = form.getFieldValue('actions');
    actions.push({ name: '' });
    form.setFieldValue('actions', actions);
  };

  const removeAction = (actionIndex: number) => {
    const actions = form.getFieldValue('actions');
    actions.splice(actionIndex, 1);
    form.setFieldValue('actions', actions);
  };

  const backToTabObligation = () => {
    const currentPath = location.pathname;
    const newPath = currentPath.split('/').slice(0, -1).join('/');
    navigate(`${newPath}`);
  };

  const onFinish = async () => {
    const actions = form.getFieldsValue().actions;
    const sendForm = {
      product_doc_obligation_actions: actions?.map((action: any) => ({
        product_doc_obligation_id: obligationId,
        name: action?.name,
      }))
    };
    await postAddAction(sendForm).unwrap().then(() => {
      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('permit_monitoring.obligation.success_submit_add_action')}`}
        />
      );

      backToTabObligation();
    })
      .catch(() => {
        toast.error(
          <Toast
            message={t('general.error_text')}
            detailedMessage={t('permit_monitoring.obligation.failed_submit_add_action')}
          />
        );
      });
  };

  const onFinishFailed = () => {
    toast.error(
      <Toast
        message={t('general.error_text')}
        detailedMessage={t(
          'manage_group.fill_form_validation_text'
        )}
      />
    );
  };

  useEffect(() => {
    const obligationString = localStorage.getItem('obligation-tmp');
    if (obligationString) {
      try {
        const obligation = JSON.parse(obligationString);
  
        if (obligation && typeof obligation === 'object' && String(obligation.id) === String(obligationId)) {
          form.setFieldsValue({
            ...initialForm,
            name: String(obligation.name)
          });
        } else {
          form.setFieldsValue(initialForm);
        }
      } catch (error) {}
    } else {
      form.setFieldsValue(initialForm);
    }
  }, [obligationId, initialForm]);
  

  useEffect(() => {
    if (isErrorPostAddAction){
      const errorMsg:any = errorPostAddAction;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorPostAddAction]);

  return (
    <>
      <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} requiredMark={false}>
        <div className="flex justify-between items-center mb-4">
          <span className="font-bold text-lg">
            {t('permit_name.obligation.add_action')}
          </span>
        </div>
        <div className="mb-5">
          <ObligationFormItem
            addAction={() => addAction()}
            removeAction={(actionIndex: number) => removeAction(actionIndex)}
            obligationNameDisabled={true}
          />
        </div>
    
        <div className='bg-[#d7d7d7] h-[1px] mb-4'/>
    
        <div className="flex justify-between items-center">
          <Button
            className='cancel-green bg-[#E5EDE5] text-[#55A853] border-[#55A853]'
            htmlType="button"
            onClick={backToTabObligation}
            disabled={isLoadingProductDocObligations}
          >
            {t('general.cancel_btn') }
          </Button>
          <Button
            type="text"
            className="bg-[#55A853] text-white font-bold"
            htmlType="submit"
            disabled={isLoadingProductDocObligations}
          >
            {t('general.submit_btn')}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default PermitMonitoringObligationAddActionOnEditForm;
