import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaTasks } from 'react-icons/fa';

const Block = (props:any) => {
  const { data, isLoading } = props;
  const { t } = useTranslation();

  return(
    <>
      {
        isLoading ? <Spin size='large'/> : 
          <div className="grid md:grid-cols-2 gap-5">
            <div>
              <p className="font-bold mb-2 truncate">{t('dashboard.title_block_1')}</p>
              <div className='bg-gradient-to-r from-[#54A853] to-[#8DC58C] rounded-lg p-2 h-[91%] p-5 text-white'>
                <div className='bg-[#FFF5D9] w-[70px] h-[70px] flex justify-center items-center rounded-[100px] mb-2'>
                  <FaTasks className='text-[40px] border-2 border-[#C29619] p-[8px] rounded-xl' color='#C29619' fill='#C29619'/>
                </div>
                <p>{t('dashboard.title_block_2')}</p>
                <p className='text-[48px] font-bold'>{data.high + data.medium + data.low}</p>
              </div>
            </div>
            <div>
              <p className="font-bold mb-2">{t('dashboard.permit_tier.title')}</p>
              <div className='h-[91%] grid grid-rows-3 gap-2'>
                <div className='bg-[#FFCCC7] flex items-center pl-5 rounded-lg'>
                  <div>
                    <p className='text-[12px]'>{t('dashboard.permit_tier.high')}</p>
                    <p className='text-[24px] font-bold'>{data.high}</p>
                  </div>
                </div>
                <div className='bg-[#FFF1E3] flex items-center pl-5 rounded-lg'>
                  <div>
                    <p className='text-[12px]'>{t('dashboard.permit_tier.medium')}</p>
                    <p className='text-[24px] font-bold'>{data.medium}</p>
                  </div>
                </div>
                <div className='bg-[#F6FFED] flex items-center pl-5 rounded-lg'>
                  <div>
                    <p className='text-[12px]'>{t('dashboard.permit_tier.low')}</p>
                    <p className='text-[24px] font-bold'>{data.low}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      }
    </>
  );
};

export default Block;