import { Base } from './Base';

const ManageCronjob = Base.injectEndpoints({
  endpoints: (build) => ({
    getCronjob: build.mutation({
      query: () => ({
        url: '/crons',
        method: 'GET',
      })
    }),
    getLogCronjob: build.mutation({
      query: (id: string) => ({
        url: `/crons/${id}/logs`,
        method: 'GET',
      })
    }),
    getDetailCronjob: build.mutation({
      query: (id: string) => ({
        url: `/crons/${id}`,
        method: 'GET',
      })
    }),
    putCronjob: build.mutation({
      query: ({id, body}: {id: string, body: any}) => ({
        url: `/crons/${id}`,
        method: 'PUT',
        body
      })
    }),
  })
});

export const { 
  useGetCronjobMutation,
  useGetLogCronjobMutation,
  useGetDetailCronjobMutation,
  usePutCronjobMutation,
} = ManageCronjob;