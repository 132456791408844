import type { CredentialType } from 'constant/Types/CredentialType';
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as App from '../../index';

import Credential from 'database/Credential';
import {
  DB_CREDENTIAL_REFRESH_TOKEN,
  DB_CREDENTIAL_TOKEN,
  DB_CREDENTIAL_USER,
  EMPTY
} from 'constant';

const credentialDb = new Credential();

const saveAuthToDb = createAsyncThunk(
  'auth/saveAuthToDb',
  async (payload: CredentialType, { rejectWithValue }) => {
    try {
      return await credentialDb.putData(payload);
    } catch (error: any) {
      rejectWithValue(EMPTY.STRING);
    }
  }
);

const getAuthFromDb = createAsyncThunk(
  'auth/getAuthFromDb',
  async (payload: CredentialType['_id'], { rejectWithValue }) => {
    try {
      return await credentialDb.getData(payload);
    } catch (error: any) {
      rejectWithValue(EMPTY.STRING);
    }
  }
);

const deleteAuthFromDb = createAsyncThunk(
  'auth/deleteAuthFromDb',
  async (payload: CredentialType['_id'], { rejectWithValue }) => {
    try {
      return await credentialDb.deleteData(payload);
    } catch (error: any) {
      rejectWithValue(EMPTY.STRING);
    }
  }
);

const logout = createAsyncThunk(
  'auth/logout',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(deleteAuthFromDb(DB_CREDENTIAL_TOKEN)).unwrap();
      await dispatch(deleteAuthFromDb(DB_CREDENTIAL_REFRESH_TOKEN)).unwrap();
      await dispatch(deleteAuthFromDb(DB_CREDENTIAL_USER)).unwrap();

      const { msalInstance } = App;
      const isLoginAzure = localStorage.getItem('isLoginAzure');
      if (isLoginAzure === 'true') {
        const accounts = msalInstance.getAllAccounts();
        msalInstance.logoutRedirect(
          {
            postLogoutRedirectUri: '/',
            account: accounts[0],
          },
        );
      }
      
      localStorage.clear();
      return { ok: true };
    } catch (error: any) {
      rejectWithValue(EMPTY.STRING);
    }
  }
);

export {
  saveAuthToDb,
  getAuthFromDb,
  deleteAuthFromDb,
  logout
};