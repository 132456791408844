import { RoleForm, RoleEditForm, ListPayloadType } from 'constant/Types/RoleType';
import { Base } from './Base';

const Role = Base.injectEndpoints({
  endpoints: (build) => ({
    postRole: build.mutation({
      query: (body: RoleForm) => ({
        url: '/roles',
        method: 'POST',
        body
      })
    }),
    putRole: build.mutation({
      query: (body: RoleEditForm) => ({
        url: `/roles/${body.id}`,
        method: 'PUT',
        body
      })
    }),
    listRolePagination: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/roles',
        method: 'GET',
        params
      })
    }),
    listRole: build.query<any, void>({
      query: () => ({
        url: '/roles/active',
        method: 'GET'
      })
    }),
    getRoleById: build.mutation<any, void>({
      query: (id: any) => ({
        url: `/roles/detail/${id}`,
        method: 'GET'
      })
    }),
    activateRole: build.mutation({
      query: (body: any) => ({
        url: `/roles/${body.id}`,
        method: 'PATCH',
        body: body.params
      })
    }),
  })
});

export const { 
  usePostRoleMutation, 
  useListRolePaginationMutation,
  useListRoleQuery, 
  useGetRoleByIdMutation, 
  usePutRoleMutation,
  useActivateRoleMutation } = Role;