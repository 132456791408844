import { Button, Form } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Toast from 'components/Toast';
import { toast } from 'react-toastify';
import { PlusOutlined } from '@ant-design/icons';
import { useLocation, useNavigate, useParams } from 'react-router';
import ObligationFormItem from '../ObligationFormItem';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { usePostProductDocObligationsMutation } from 'api/PermitMonitoring';
import { transformError } from 'utils/ErrorTransformer';
import StrictModeDroppable from 'components/StrictModeDroppable';

const PermitMonitoringObligationForm = () => {
  interface Action {
    name: string
  }

  interface Obligation {
    name: string,
    actions: Action[]
  }

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const { id } = useParams();
  const initialForm = {
    dynamicObligation: [
      {
        name: '',
        actions: [
          {
            name: '',
          },
        ],
      },
    ],
  };


  const [postProductDocObligations, {
    isError: isErrorPostProductDocObligations,
    error: errorPostProductDocObligations,
    isLoading: isLoadingProductDocObligations 
  }] = usePostProductDocObligationsMutation();

  const addObligation = () => {
    const newObligation = {
      name: '',
      actions: [
        {
          name: '',
        },
      ],
    };
    const newDynamicObligations = [...form.getFieldsValue()?.dynamicObligation];
    form.setFieldsValue({
      ...form,
      dynamicObligation: [...newDynamicObligations, newObligation],
    });
  };

  const removeObligation = (index: number) => {
    const obligations = form.getFieldValue('dynamicObligation');
    obligations.splice(index, 1);
    form.setFieldsValue({ dynamicObligation: obligations });
  };

  const addAction = (obligationIndex: number) => {
    const obligations = form.getFieldValue('dynamicObligation');
    obligations[obligationIndex].actions.push({ name: '' });
    form.setFieldsValue({ dynamicObligation: obligations });
  };

  const removeAction = (obligationIndex: number, actionIndex: number) => {
    const obligations = form.getFieldValue('dynamicObligation');
    obligations[obligationIndex].actions.splice(actionIndex, 1);
    form.setFieldsValue({ dynamicObligation: obligations });
  };

  const backToTabObligation = () => {
    const currentPath = location.pathname;
    const newPath = currentPath.split('/').slice(0, -2).join('/');
    navigate(`${newPath}?tab=2`);
  };

  const onFinish = async () => {
    const dataForm = form.getFieldsValue().dynamicObligation;
    const sendForm = {
      product_doc_obligations: dataForm.map((dataOb: Obligation, el: number) => ({
        product_doc_id: id,
        name: dataOb.name,
        index: el,
        product_doc_obligation_actions: dataOb.actions
      }))
    };
    await postProductDocObligations(sendForm).unwrap().then(() => {
      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('permit_name.obligation.success_submit_add_obligation')}`}
        />
      );

      backToTabObligation();
    })
      .catch(() => {
        toast.error(
          <Toast
            message={t('general.error_text')}
            detailedMessage={t('permit_name.obligation.failed_submit_add_obligation')}
          />
        );
      });
  };

  const onFinishFailed = () => {
    toast.error(
      <Toast
        message={t('general.error_text')}
        detailedMessage={t(
          'manage_group.fill_form_validation_text'
        )}
      />
    );
  };

  const dragEnded = (param:any) => {
    const { source, destination } = param;
    const temp = form.getFieldValue('dynamicObligation').map((el:any) => el);
    temp[source.index] = form.getFieldValue('dynamicObligation')[destination.index];
    temp[destination.index] = form.getFieldValue('dynamicObligation')[source.index];
    form.setFieldsValue({
      dynamicObligation: temp
    });
  };

  useEffect(() => {
    form.setFieldsValue(initialForm);
  }, []);

  useEffect(() => {
    if (isErrorPostProductDocObligations){
      const errorMsg:any = errorPostProductDocObligations;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorPostProductDocObligations]);

  return (
    <>
      <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} requiredMark={false}>
        <DragDropContext onDragEnd={dragEnded}>
          <StrictModeDroppable droppableId="obligations-wrapper">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} className='obligations' {...provided.droppableProps}>
                <Form.List name="dynamicObligation">
                  {(fields) => (
                    <>
                      <div ref={provided.innerRef} className="flex justify-between items-center mb-4">
                        <span className="font-bold text-lg">
                          {t('permit_name.obligation.add_obligation')}
                        </span>
                      </div>
                      {fields.map(({ key, name, ...restField }, obligationIndex) => (
                        <Draggable
                          draggableId={`obligation-${key}`}
                          index={obligationIndex}
                          key={key}>
                          {(_provided, _snapshot) => (
                            <div ref={_provided.innerRef} key={key} className="mb-5">
                              <ObligationFormItem
                                ref={_provided.innerRef}
                                dragHandleProps={_provided.dragHandleProps}
                                {..._provided.draggableProps}
                                snapshot={_snapshot}
                                restField={restField}
                                key={key}
                                name={name}
                                isOnlyOneData={fields.length > 1}
                                removeObligation={() => removeObligation(obligationIndex)}
                                addAction={() => addAction(obligationIndex)}
                                removeAction={(actionIndex: number) => removeAction(obligationIndex, actionIndex)}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                      )}
                    </>
                  )}
                </Form.List>
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
        <div onClick={addObligation} className='mb-4 cursor-pointer rounded-lg gap-2 p-4 border-2 hover:bg-slate-100 border-[#55A853] border-dashed flex items-center justify-center'>
          <PlusOutlined className='text-[#55A853] font-bold' />
          <span className='text-[#55A853] font-bold'>{t('permit_name.obligation.add_obligation')}</span>
        </div>
    
        <div className='bg-[#d7d7d7] h-[1px] mb-4'/>
    
        <div className="flex justify-between items-center">
          <Button
            className='cancel-green bg-[#E5EDE5] text-[#55A853] border-[#55A853]'
            htmlType="button"
            onClick={backToTabObligation}
            disabled={isLoadingProductDocObligations}
          >
            {t('general.cancel_btn') }
          </Button>
          <Button
            type="text"
            className="bg-[#55A853] text-white font-bold"
            htmlType="submit"
            disabled={isLoadingProductDocObligations}
          >
            {t('general.submit_btn')}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default PermitMonitoringObligationForm;
