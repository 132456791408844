import { Button, Card, DatePicker, Empty, Form, Input, Modal, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { FileOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router';
import { OptionType } from 'constant/Types/OptionType';
import { useDepartmentSearchMutation, useIssuerInstitutionsListMutation } from 'api/Options';
import { useEffect, useState } from 'react';
import { useGetComplianceStatusMutation, usePutPermitMonitoringMutation } from 'api/PermitMonitoring';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { toast } from 'react-toastify';
import Toast from 'components/Toast';
import { MdLink } from 'react-icons/md';
import { hasPermission } from 'utils/Permission';
import ProgressBar from '../../../../../components/ProgressBar';
import ModalInitiatePermitRequest from '../../../../Create/InitiatePermitRequest/ModalInitiatePermitRequest';

const PermitTabPermitMonitoringCrm = (props: any) => {
  const { id, form, isEdit, detail } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { confirm } = Modal;
  const { pathname } = useLocation();
  const [isOpenModalInitiate, setIsOpenModalInitiate] = useState(false);
  const [canCreateInitiate, setCanCreateInitiate] = useState(false);

  const [departmentList, {
    isLoading: isLoadingDepartment
  }] = useDepartmentSearchMutation();
  const [issuerInstitutionList] = useIssuerInstitutionsListMutation();
  const [complianceStatusList, {
    isLoading: isLoadingComplianceStatus
  }] = useGetComplianceStatusMutation();
  const [putPermit, {
    isLoading: isLoadingPutPermit
  }] = usePutPermitMonitoringMutation();

  const [departments, setDepartments] = useState<OptionType[]>([]);
  const [complianceStatus, setComplianceStatus] = useState<OptionType[]>([]);
  const [issuerInstitutions, setIssuerInstitutions] = useState<OptionType[]>([]);

  const getBorderStep = (data: any) => {
    switch (data) {
      case 1:
        return 'border-zinc-400';
      case 2:
        return 'border-red-400';
      case 3:
        return 'border-green-400';
    }
  };

  const getColorStep = (data: any) => {
    switch (data) {
      case 1:
        return '#bbbbbb';
      case 2:
        return '#ED3F3F';
      case 3:
        return '#55A853';
    }
  };

  const downloadFileUrl = (url: any) => {
    window.open(url);
  };

  const fetchComplianceStatus = async (value: any) => {
    if (value.length > 2 || !value) {
      let data = await complianceStatusList({}).unwrap();
      const newDataComplianceStatus = data.map((el: any) => {
        if (el) {
          return {
            label: el?.value,
            value: el?.value
          };
        }
        return null;
      });

      setComplianceStatus(newDataComplianceStatus.filter((v: any) => v));
    }
  };

  const fetchDepartment = async (value: any) => {
    if (value.length > 2 || !value) {
      const payload = {
        filters: `department@=${value}`
      };
      let { data } = await departmentList(payload).unwrap();
      const newDataDepartment = data.map((el: any) => {
        if (el) {
          return {
            label: el?.department,
            value: el?.department
          };
        }
        return null;
      });

      setDepartments(newDataDepartment.filter((v: any) => v));
    }
  };

  const fetchIssuerInstitutions = async (value: any) => {
    if (value?.length > 2 || !value) {
      const payload = {
        search: value ?? '',
        searchFields: 'name'
      };
      let { rows } = await issuerInstitutionList(payload).unwrap();
      const newDataIssuerInstitution = rows.map((el: any) => {
        if (el) {
          return {
            label: el.name,
            value: el.id
          };
        }
        return null;
      });

      setIssuerInstitutions(newDataIssuerInstitution.filter((v: any) => v));
    }
  };

  const extractKeys = (obj: any, keys: string[]): any => {
    return keys.reduce((acc: any, key: string) => {
      if (obj.hasOwnProperty(key)) {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
  };

  const isEqualSubset = (obj1: any, obj2: any, keys: string[]): boolean => {
    const subset1 = extractKeys(obj1, keys);
    const subset2 = extractKeys(obj2, keys);
    return JSON.stringify(subset1) === JSON.stringify(subset2);
  };

  const onCancel = () => {
    const formValues = form.form.getFieldsValue();
    const keysToCompare: string[] = ['compliance_status', 'departments', 'remark'];
  
    if (isEqualSubset(detail, formValues, keysToCompare)) {
      navigate(-1);
    } else {
      confirm({
        title: t('general.label_cancel_data'),
        icon: <ExclamationCircleFilled />,
        okText: t('manage_group.yes_btn'),
        okType: 'danger',
        cancelText: t('manage_group.no_btn'),
        onOk() {
          navigate(-1);
        },
      });
    }
  };

  const handleSubmit = async () => {
    const value = form.form.getFieldsValue();
    const submitForm = {
      id: id,
      product_doc: {
        registration_number: value?.registration_number,
        compliance_status: value?.compliance_status,
        departments: value?.departments.map((departmentName: string) => ({
          department_name: departmentName
        })),
        remark: value?.remark
      }
    };

    await putPermit(submitForm).unwrap()
      .then(() => {
        toast.success(
          <Toast
            message={t('general.success_text')}
            detailedMessage={`${t('permit_monitoring.success_message_update_permit')}`}
          />
        );
      })
      .catch(() => {
        toast.error(
          <Toast
            message={t('general.error_text')}
            detailedMessage={t('permit_monitoring.error_message_update_permit')}
          />
        );
      });
  };

  const onSubmit = () => {
    form.form.validateFields().then(async () => {
      confirm({
        title: t('general.label_save_data'),
        icon: <ExclamationCircleFilled />,
        okText: t('manage_group.yes_btn'),
        okType: 'danger',
        cancelText: t('manage_group.no_btn'),
        onOk() {
          handleSubmit();
        },
      });
    }).catch(() => {
      toast.error(<Toast message={t('general.error_text')} detailedMessage={t('permit_monitoring.error_validate_form')} />);
    });
  };

  const handleClickPermitReference = () => {
    const paths = pathname.split('/');
    const url = hasPermission(paths[1]).can_update 
      ? `/permit-monitoring-crm/edit/${detail.permit_reference_id}` 
      : `/permit-monitoring-crm/detail/${detail.permit_reference_id}`;
    
    window.open(url, '_blank');
  };

  const handleInitiatePermit = () => {
    setIsOpenModalInitiate(true);
  };

  useEffect(() => {
    fetchComplianceStatus('');
    fetchDepartment('');
    fetchIssuerInstitutions('');
    setCanCreateInitiate(hasPermission('initiate permit request').can_create);
  }, []);

  return(
    <div className='mb-[176px] md:mb-0'>
      <Card className='bg-[#FBFBFB] rounded-xl'>
        <div className='bg-[#FDF0D8] p-3 rounded-lg flex flex-row items-center'>
          <span className='font-bold text-base'>{ t('permit_monitoring.permit_info') }</span>
        </div>
        <div className='grid grid-cols-2 gap-5 mt-5'>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='activity'
              label={t('permit_monitoring.activity')}
            >
              <Input placeholder={t('permit_monitoring.activity_placeholder') || ''} disabled />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='permit_name_value'
              label={t('permit_monitoring.permit_name')}
            >
              <Select
                placeholder={t('permit_monitoring.permit_name_placeholder') || ''}
                disabled
              />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='registration_number'
              label={t('permit_monitoring.permit_no')}
            >
              <Input placeholder={t('permit_monitoring.permit_no_placeholder') || ''} disabled />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='permit_tier'
              label={t('permit_monitoring.permit_tier')}
            >
              <Select
                placeholder={t('permit_monitoring.permit_tier_placeholder') || ''}
                disabled
              />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='progress_obligation'
              label={t('permit_monitoring.progress_obligation')}
            >
              <ProgressBar percent={form.form.getFieldValue('progress_obligation') || 0} color="#9c50fe" />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='compliance_status'
              label={t('permit_monitoring.compliance_status')}
              rules={[{ required: true }]}
            >
              <Select
                placeholder={t('permit_monitoring.compliance_status_placeholder') || ''}
                options={complianceStatus}
                disabled={!isEdit}
                filterOption={false}
                onChange={(a: any) => form.form.setFieldValue('compliance_status', a)}
                notFoundContent={isLoadingComplianceStatus ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='remaining_expired'
              label={t('permit_monitoring.remaining_expired')}
            >
              <div>
                <ProgressBar 
                  percent={form.form.getFieldValue('remaining_expired') || 0}
                  colorStart="#f0b059" 
                  colorEnd="#e43e38"
                  showTextCheckpoint={false}
                />
                <p className='text-end text-xs'>{form.form?.getFieldValue('remaining_day')?.remaining_days || 0} Days Left</p>
              </div>
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='requestor'
              label={t('permit_monitoring.requestor')}
            >
              <Input placeholder={t('permit_monitoring.requestor_placeholder') || ''} disabled />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='sector'
              label={t('permit_monitoring.sector')}
            >
              <Input placeholder={t('permit_monitoring.sector_placeholder') || ''} disabled />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='permit_issuer_institution_id'
              label={t('permit_monitoring.institution')}
            >
              <Select
                placeholder={t('permit_monitoring.institution_placeholder') || ''}
                options={issuerInstitutions}
                disabled
              />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='active_date'
              label={t('permit_monitoring.active_date')}
              tooltip={t('permit_request.request_information.hint_active_date')}
            >
              <DatePicker
                className='w-full'
                placeholder={t('permit_monitoring.active_date_placeholder') || ''}
                format={'DD-MMM-YYYY'}
                disabled
              />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='release_date'
              label={t('permit_monitoring.release_date')}
              tooltip={t('permit_request.request_information.hint_release_date')}
            >
              <DatePicker
                className='w-full'
                placeholder={t('permit_monitoring.release_date_placeholder') || ''}
                format={'DD-MMM-YYYY'}
                disabled
              />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='expired_date'
              label={t('permit_monitoring.expired_date')}
            >
              <DatePicker
                className='w-full'
                placeholder={t('permit_monitoring.expired_date_placeholder') || ''}
                format={'DD-MMM-YYYY'}
                disabled
              />
            </Form.Item>
          </div>
          <div/>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='permit_status'
              label={t('permit_monitoring.permit_status')}
            >
              <Select
                placeholder={t('permit_monitoring.permit_status_placeholder') || ''}
                disabled
              />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='departments'
              label={t('permit_monitoring.department')}
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                mode='multiple'
                placeholder={t('permit_monitoring.department_placeholder') || ''}
                options={departments}
                disabled={!isEdit}
                filterOption={false}
                onSearch={fetchDepartment}
                notFoundContent={isLoadingDepartment ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='permit_reference'
              label={t('permit_monitoring.permit_reference')}
            >
              <Input 
                suffix={detail?.is_permit_reference_exist && (
                  <MdLink
                    size={20}
                    className='cursor-pointer'
                    fill="#55A853"
                    onClick={handleClickPermitReference}
                  />
                )}
                placeholder={t('permit_monitoring.permit_reference_placeholder') || ''} 
                disabled 
              />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='expire_reference'
              label={t('permit_monitoring.expire_reference')}
            >
              <DatePicker
                className='w-full'
                placeholder={t('permit_monitoring.expire_reference_placeholder') || ''}
                format={'DD-MMM-YYYY'}
                disabled
              />
            </Form.Item>
          </div>
          <div className='col-span-2'>
            <Form.Item
              name='description'
              label={t('permit_monitoring.description')}
            >
              <Input.TextArea
                style={{ height: 100, resize: 'none' }}
                placeholder={t('permit_monitoring.description_placeholder') || ''}
                disabled
              />
            </Form.Item>
          </div>
          <div className='col-span-2'>
            <Form.Item
              name='remark'
              label={t('permit_monitoring.remarks')}
            >
              <Input.TextArea
                style={{ height: 60, resize: 'none' }}
                placeholder={t('permit_monitoring.remarks_placeholder') || ''}
                disabled={!isEdit}
              />
            </Form.Item>
          </div>
          <div className='col-span-2'>
            <Form.Item
              name='attachments'
              label={t('permit_monitoring.attachment')}
            >
              <div className='grid grid-cols-5 md:grid-cols-10 xl:grid-cols-12 gap-5'>
                {
                  form.form.getFieldsValue()?.attachments?.map((dataC: any, idxC: any) => (
                    <div key={idxC}>
                      <div className={`border ${getBorderStep(3)} rounded-md p-2`}>
                        <div className='cursor-pointer'>
                          <div key={idxC} className='w-full flex justify-center'>
                            <FileOutlined className={`text-[30px] text-[${getColorStep(3)}]`} onClick={() => downloadFileUrl(dataC?.attachment?.downloadUrl)} />
                          </div>
                          <p className='text-center mt-3 text-[10px] text-[#8B849B] truncate'>
                            {dataC?.attachment?.name || '-'}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </Form.Item>
          </div>
        </div>

        <div className='bg-[#d7d7d7] h-[1px] mb-4 mt-4'/>

        <div className={`flex justify-between gap-4 ${canCreateInitiate ? 'flex-col sm:flex-row' : ''}`}>
          <div>
            <Button
              className='sm:mb-0 cancel-green bg-[#E5EDE5] text-[#55A853] border-[#55A853]'
              htmlType="button"
              onClick={onCancel}
            >
              {t('general.cancel_btn') }
            </Button>
          </div>
          <div className='sm:flex'>
            {
              canCreateInitiate && <Button
                type="text"
                className='sm:mb-0 cancel-green bg-[#E5EDE5] text-[#55A853] border-[#55A853] mr-3'
                htmlType="button"
                onClick={handleInitiatePermit}
                loading={isLoadingPutPermit}
              >
                {t('initiate_permit_request.initiate_btn')}
              </Button>
            }
            <Button
              type="text"
              className="bg-[#55A853] text-white font-bold"
              htmlType="button"
              onClick={onSubmit}
              loading={isLoadingPutPermit}
            >
              {t('general.update_btn')}
            </Button>
          </div>
        </div>
      </Card>
      <ModalInitiatePermitRequest
        modalAction={{
          isModalOpen: isOpenModalInitiate,
          setIsModalOpen: setIsOpenModalInitiate
        }}
        detail={detail}
      />
    </div>
  );
};

export default PermitTabPermitMonitoringCrm;