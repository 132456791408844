import { useState, useEffect } from 'react';
import { useAppSelector } from 'store/Hooks';
import { Avatar, Spin, ConfigProvider } from 'antd';
import { SendOutlined, UserOutlined } from '@ant-design/icons';
import ScrollToBottom from 'react-scroll-to-bottom';
import classNames from './mention.module.css';
import { MentionsInput, Mention } from 'react-mentions';
import { useGetProductDocConversationMentionsMutation, useGetProductDocConversationMutation, usePostProductDocConversationMutation } from 'api/ProductDocConversation';
import { toast } from 'react-toastify';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { useTranslation } from 'react-i18next';


const Conversation = (props: any) => {
  const { t } = useTranslation();
  const { id, refetchData } = props;
  const { user } = useAppSelector((state) => state.auth);
  const [messageList, setMessageList] = useState<any[]>([]);
  const [message, setMessage] = useState<string>('');

  const [getProductDocConversationById, {
    isLoading: isLoadingGetProductDocConversationById,
    isError: isErrorGetProductDocConversationById,
    error: errorGetProductDocConversationById
  }] = useGetProductDocConversationMutation();

  const [postProductDocConversationById, {
    isLoading: isLoadingPostProductDocConversationById,
    isError: isErrorPostProductDocConversationById,
    error: errorPostProductDocConversationById
  }] = usePostProductDocConversationMutation();

  const [getProductDocConversationMentionList, {
    isLoading: isLoadingProductDocConversationMentionList
  }] = useGetProductDocConversationMentionsMutation();

  const MessageMe = (props: any) => {
    const { data } = props;
    const regexSplit = /(@\[.*?\]\(.*?\))|(#\[.*?\])/g;
    const splitMessage = data?.message.split(regexSplit).filter(Boolean).map((part:any) => part.trim());
    let newMessage = [];
    if (splitMessage.length){
      for(let i = 0; i < splitMessage.length; i++){
        if (splitMessage[i] !== '' && splitMessage[i] !== ' '){
          if (validateStringMention(splitMessage[i])){
            const regexName = /@\[(.*?)\]/;
            const match = splitMessage[i].match(regexName);
            let mentionSpan = i === (splitMessage?.length-1) ? 
              <span className='text-[#2B93CD] cursor-pointer'>{match[1]}</span> : 
              <span className='text-[#2B93CD] cursor-pointer'>{match[1]}&nbsp;</span>;
            newMessage.push(mentionSpan);
          } else if (validateStringHastag(splitMessage[i])) {
            const regexName = /#\[(.*?)\]/;
            const match = splitMessage[i].match(regexName);
            let hastagSpan = i === (splitMessage?.length-1) ? 
              <span className='text-[#2B93CD] cursor-pointer'>#{match[1]}</span> : 
              <span className='text-[#2B93CD] cursor-pointer'>#{match[1]}&nbsp;</span>;
            newMessage.push(hastagSpan);
          } else {
            let message = i === (splitMessage?.length-1) ? 
              <span>{splitMessage[i]}</span> : 
              <span>{splitMessage[i]}&nbsp;</span>;
            newMessage.push(message);
          }
        }
      }
    }

    return (
      <div className="flex mb-3">
        <div className='bg-[#EDC94A] px-2 py-1 rounded-lg flex mx-1'>
          {newMessage || ''}
        </div>
        <div>
          <Avatar icon={<UserOutlined />} />
        </div>
      </div>
    );
  };
  
  const MessageSender = (props: any) => {
    const { data } = props;
    const regexSplit = /(@\[.*?\]\(.*?\))|(#\[.*?\])/g;
    const splitMessage = data?.message.split(regexSplit).filter(Boolean).map((part:any) => part.trim());
    let newMessage = [];
    if (splitMessage.length){
      for(let i = 0; i < splitMessage.length; i++){
        if (splitMessage[i] !== ''){
          if (validateStringMention(splitMessage[i])){
            const regexName = /@\[(.*?)\]/;
            const match = splitMessage[i].match(regexName);
            let mentionSpan = i === (splitMessage?.length-1) ? 
              <span className='text-[#2B93CD] cursor-pointer'>{match[1]}</span> : 
              <span className='text-[#2B93CD] cursor-pointer'>{match[1]}&nbsp;</span>;
            newMessage.push(mentionSpan);
          } else if (validateStringHastag(splitMessage[i])) {
            const regexName = /#\[(.*?)\]/;
            const match = splitMessage[i].match(regexName);
            let hastagSpan = i === (splitMessage?.length-1) ? 
              <span className='text-[#2B93CD] cursor-pointer'>#{match[1]}</span> : 
              <span className='text-[#2B93CD] cursor-pointer'>#{match[1]}&nbsp;</span>;
            newMessage.push(hastagSpan);
          } else {
            let message = i === (splitMessage?.length-1) ? 
              <span>{splitMessage[i]}</span> : 
              <span>{splitMessage[i]}&nbsp;</span>;
            newMessage.push(message);
          }
        }
      }
    }
    return (
      <div className='flex mb-3'>
        <div className='self-end'>
          <Avatar icon={<UserOutlined />} />
        </div>
        <div className='mx-1'>
          {data?.user[0]?.display_name}
          <div className='bg-[#A3D47C] px-2 py-1 rounded-lg'>
            {newMessage}
          </div>
        </div>
      </div>
    );
  };

  const fetchConversation = async (id: any) => {
    await getProductDocConversationById(id).unwrap().then((resp: any) => {
      let newResp = [...resp?.conversations];
      newResp.sort((a:any, b:any) => (new Date(a.date) > new Date(b.date)) ? 1 : ((new Date(b.date) > new Date(a.date)) ? -1 : 0));
      setMessageList(newResp);
      setMessage('');
    });
  };

  const sendMessage = async () => {
    const payload = {
      product_doc_id: id,
      message: message
    };

    await postProductDocConversationById(payload).unwrap().then(() => {
      fetchConversation(id);
    });
  };

  const fetchMentionList = (value: any, callback:any) => {
    const payload = {
      id: id,
      params: {
        search: value ?? ''
      }
    }; 
    
    getProductDocConversationMentionList(payload).unwrap()
      .then((resp: any) =>
        resp?.map((el:any) => ({
          id: el?.id,
          display: el?.display_name
        }))).then(callback);};

  function validateStringMention(input:any) {
    const regex = /^@\[.*?\]\(.*?\)$/;
    return regex.test(input);
  }

  function validateStringHastag(input:any) {
    const regex = /^#\[.*\]$/;
    return regex.test(input);
  }

  useEffect(() => {
    if (id) {
      fetchConversation(id);
    }
  }, [id, refetchData]);

  useEffect(() => {
    if (isErrorGetProductDocConversationById) {
      const errorMsg:any = errorGetProductDocConversationById;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isErrorGetProductDocConversationById]);

  useEffect(() => {
    if (isErrorPostProductDocConversationById) {
      const errorMsg:any = errorPostProductDocConversationById;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isErrorPostProductDocConversationById]);
  
  return(
    <div className="lg:px-5 pt-3 pb-5 mb-[200px] md:mb-[50px] lg:mb-0">
      <div className="grid grid-cols-1 content-end bg-[#F5F5F5] h-[450px] p-5 rounded-lg">
        <ScrollToBottom className='overflow-auto flex flex-col'>
          <Spin spinning={isLoadingGetProductDocConversationById}>
            {
              messageList.map((el:any, idx: any) => (
                <div key={idx} className='mb-5'>
                  <div className='flex justify-center'>
                    <div className='bg-white px-4 rounded-xl mb-3'>{el?.date}</div>
                  </div>
                  {
                    el?.list.map((elC: any, idxC:any) => (
                      <div key={idxC} className='grid'>
                        <div className={`${elC?.user[0]?.username === user?.username ? 'justify-self-end' : 'justify-self-start'}`}>
                          {
                            elC?.user[0]?.username === user?.username ? 
                              <MessageMe data={elC}></MessageMe> : 
                              <MessageSender data={elC}></MessageSender>
                          }
                        </div>
                      </div>
                    ))    
                  }
                </div>
              ))
            }
          </Spin>
        </ScrollToBottom>
        <div className='flex bg-white border-2 rounded-lg'>
          {
            (<MentionsInput 
              value={message} 
              onChange={(e:any) => {
                setMessage(e?.target?.value);
              }} 
              classNames={classNames}
            >
              <Mention
                markup="@[__display__](__id__)"
                trigger="@"
                data={fetchMentionList}
                className={classNames.mentions__mention}
                displayTransform={(url:any, name:any) => `@${name}`}
              />
            </MentionsInput>)
          }
          {
            isLoadingProductDocConversationMentionList || isLoadingPostProductDocConversationById ? 
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: '#55A853',
                  },
                }}
              >
                <Spin className='ml-5 mr-3 my-auto' size='small'></Spin>
              </ConfigProvider> : 
              message.length ? (
                <SendOutlined 
                  className='ml-5 mr-3 my-auto text-[#55A853] cursor-pointer'
                  onClick={sendMessage}
                />
              ) : (
                <SendOutlined 
                  className='ml-5 mr-3 my-auto text-slate-400 cursor-not-allowed'
                />
              )
          }
        </div>
      </div>
    </div>
  );
};
  
export default Conversation;