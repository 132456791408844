import moment from 'moment';
import dayjs from 'dayjs';
import { Spin, Button, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { FolderFilled } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import DataTable from 'components/DataTable';
import { hasPermission } from 'utils/Permission';
import ModalFilter from './ModalFilter';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { useGetDownloadExcelMutation, useGetPermitMonitoringCrmMutation } from 'api/PermitMonitoring';
import { ButtonDataTableTypeItems } from 'constant/Types/DataTableConfigType';
// @ts-ignore
import $ from 'jquery';
import ModalInitiatePermitRequest from '../../Create/InitiatePermitRequest/ModalInitiatePermitRequest';
import { truncateText } from 'utils/Utility';
import { isEmpty } from 'lodash';
import { Buffer } from 'buffer';

const PermitMonitoringCrm = () => {
  interface listData {
    total: any,
    permitMonitoringCrm: any
  };

  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const dummyIsLoadingDetail = false;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [canView, setCanView] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [canCreate, setCanCreate] = useState(false);
  const [isOpenModalInitiate, setIsOpenModalInitiate] = useState(false);
  const [data, setData] = useState<listData>({
    total: 0,
    permitMonitoringCrm: []
  });
  const [payload, setPayload] = useState({
    lang: getLanguage(),
    page: 1,
    pageSize: 50,
    search: '',
    sort: '-released_date',
    processed_by: [],
    status: [],
    permit_location_id: [],
    expired_date: '',
    permit_tier: []
  });
  const [filter, setFilter] = useState({
    processed_by: [],
    status: [],
    permit_location_id: [],
    expired_date_from: '',
    expired_date_to: '',
    permit_tier: []
  });
  const [columns, setColumns] = useState<ColumnsType>([
    {
      title: 'Action',
      key: 'action',
      fixed: 'left',
      width: 100,
      className: 'bg-fixed-col-1',
      render: (data) => (
        <div>
          {
            (canView || canUpdate) &&
            <Button
              type='text'
              className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0'
              onClick={() => navigate(`/permit-monitoring-crm/edit/${data.id}`)}
            >
              <FolderFilled style={{ fontSize: '20px' }} className='text-[#55A853] p-1' />
            </Button>
          }
        </div>
      ),
    },
    {
      title: 'Activity',
      dataIndex: 'activity',
      key: 'activity',
      fixed: 'left',
      className: 'bg-fixed-col-2',
      render: (data) => {
        return (<div>{data || '-'}</div>);
      }
    },
    {
      title: 'Permit Name',
      dataIndex: 'permit_name',
      key: 'permit_name',
      sorter: true,
      render: (data) => {
        return (<div>{data[getLanguage()] || '-'}</div>);
      }
    },
    {
      title: 'Permit Tier',
      dataIndex: 'permit_tier',
      key: 'permit_tier',
      sorter: true,
      render: (data) => {
        return (<div>{data || '-'}</div>);
      }
    },
    {
      title: 'Permit Type',
      dataIndex: 'permit_type_name',
      key: 'permit_type_name',
      sorter: true,
      render: (data) => {
        return (<div>{data || '-'}</div>);
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (data) => {
        return (
          data ? <Tooltip title={data}>
            <span>{truncateText(data, 10)}</span>
          </Tooltip> : <>-</>
        );
      }
    },
    {
      title: 'Permit No',
      dataIndex: 'registration_number',
      key: 'registration_number',
      sorter: true,
      render: (data) => {
        return (<div>{data || '-'}</div>);
      }
    },
    {
      title: 'Active Date',
      dataIndex: 'released_date',
      key: 'released_date',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (data) => {
        return (<div>{data ? moment(new Date(data)).format('DD-MMM-YYYY') : '-'}</div>);
      }
    },
    {
      title: 'Expired Date',
      dataIndex: 'expired_date',
      key: 'expired_date',
      sorter: true,
      render: (data) => {
        return (<div>{data ? moment(new Date(data)).format('DD-MMM-YYYY') : '-'}</div>);
      }
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      render: (data) => {
        return (
          data ? <Tooltip title={data}>
            <span>{truncateText(data, 10)}</span>
          </Tooltip> : <>-</>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      fixed: 'right',
      className: 'bg-fixed-col-3',
      render: (data) => {
        return (
          <div
            className={
              `
                ml-3 py-1 px-2  rounded-lg border border-solid text-xs text-center uppercase
                ${data.toLowerCase() === 'archived' ? 'text-white bg-[#AAAAAA]' : ''}
                ${data.toLowerCase() === 'active' ? 'text-white bg-[#659711]' : ''}
                ${data.toLowerCase() === 'not active' ? 'text-white bg-[#6A131A]' : ''}
                ${data.toLowerCase() === 'expired' ? 'text-white bg-[#FF7527]' : ''}
              `
            }
          >
            {data.toLowerCase() === 'not active' ? 'Inactive': data}
          </div>
        );
      }
    },
    {
      title: 'Remarks',
      dataIndex: 'remark',
      key: 'remark',
      fixed: 'right',
      className: 'bg-fixed-col-4',
      render: (data) => {
        return (<div>{data || '-'}</div>);
      }
    },
  ]);

  const [permitMonitoringCrmList, {
    error: errorPermitMonitoringCrmList,
    isError: isErrorPermitMonitoringCrmList,
    isLoading
  }] = useGetPermitMonitoringCrmMutation();

  const [downloadExcel, {
    error: errorDownloadExcel,
    isError: isErrorDownload,
    isLoading: loadingDownloadExcel
  }] = useGetDownloadExcelMutation();

  const buttonConfig: ButtonDataTableTypeItems = [
    {
      name: t('initiate_permit_request.initiate_btn'),
      link: '',
      className: `${canCreate ? '' : 'hidden'} bg-[#55A853] text-white font-bold`,
      onClick: () => { setIsOpenModalInitiate(true); }
    },
    {
      name: t('permit_monitoring.download_crm'),
      link: '',
      className: `${loadingDownloadExcel && 'animate-pulse animate-infinite'} ml-3 bg-[#55A853] text-white font-bold`,
      onClick: () => handleDownloadExcel(),
      disable: isEmpty(data.permitMonitoringCrm) || loadingDownloadExcel
    }
  ];

  const convertPayload = () => {
    let convertPayload: any = payload;
    let newPayload: any = {};
    for (const key in convertPayload) {
      if (Array.isArray(convertPayload[key])) {
        if (convertPayload[key].length) {
          newPayload[key] = convertPayload[key];
        }
      } else if (convertPayload[key]) {
        newPayload[key] = convertPayload[key];
      }
    }

    return newPayload;
  };

  const fetchList = async () => {
    const convertedPayload = await convertPayload();

    let listPermitMonitoring = await permitMonitoringCrmList(convertedPayload).unwrap();
    listPermitMonitoring = {
      ...listPermitMonitoring,
      permitMonitoringCrm: listPermitMonitoring?.rows.map((el: any, idx: any) => ({ ...el, key: idx }))
    };

    setData(listPermitMonitoring);
    setIsLoadingFilter(false);
  };

  const handleDownloadExcel = async () => {
    const { page, pageSize, ...payload } = await convertPayload();

    let { data }: any = await downloadExcel({ ...payload });
    const buffer = Buffer.from(data.buffer);
    const blob = new Blob([buffer]);

    const docLink = document.createElement('a');
    docLink.href = window.URL.createObjectURL(blob);
    docLink.target = '_blank';
    docLink.download = `Permit Monitoring CRM-${moment(new Date()).format('YYYYMMDD_HHmmss')}.xlsx`;
    docLink.click();
  };
  
  const applyFilter = () => {
    setIsLoadingFilter(true);
    setPayload({
      ...payload,
      page: 1,
      status: filter?.status,
      permit_location_id: filter?.permit_location_id || [],
      expired_date: filter.expired_date_from && filter.expired_date_to ?
        `${dayjs(filter?.expired_date_from).format('YYYY-MM-DD')};${dayjs(filter?.expired_date_to).format('YYYY-MM-DD')}` :
        '',
      processed_by: filter?.processed_by,
      permit_tier: filter?.permit_tier
    });
  };

  const resetFilter = () => {
    setIsLoadingFilter(true);
    setFilter({
      processed_by: [],
      status: [],
      permit_location_id: [],
      expired_date_from: '',
      expired_date_to: '',
      permit_tier: []
    });
    setPayload({
      ...payload,
      processed_by: [],
      status: [],
      permit_location_id: [],
      expired_date: '',
      permit_tier: []
    });
  };

  useEffect(() => {
    const paths = pathname.split('/');
    setCanView(hasPermission(paths[1]).can_view);
    setCanCreate(hasPermission('initiate permit request').can_create);
    setCanUpdate(hasPermission(paths[1]).can_update);
    fetchList();

    if(!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
      for (let i = 0; i <=4; i++){
        let style =$(`th.bg-fixed-col-${i}`).attr('style');
        style += ';background-color:#F4F5FD !important;';
        $(`th.bg-fixed-col-${i}`).attr('style', style);
      }
    } else {
      let updatedColumns = columns.map(column => {
        const { fixed, ...rest } = column;
        return rest;
      });
      setColumns(updatedColumns);
    }
  }, []);

  useEffect(() => {
    setIsLoadingFilter(true);
    fetchList();
  }, [payload]);

  useEffect(() => {
    if (isErrorPermitMonitoringCrmList) {
      setIsLoadingFilter(false);
      const errorMsg: any = errorPermitMonitoringCrmList;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
    if (isErrorDownload) {
      setIsLoadingFilter(false);
      const errorMsg: any = errorDownloadExcel;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorPermitMonitoringCrmList, isErrorDownload]);

  useEffect(() => {
    setPayload({
      ...payload,
      lang: getLanguage()
    });
  }, [getLanguage()]);

  return(
    <div className='w-full overflow-x-scroll'>
      <Spin tip="Loading" size="large" spinning={dummyIsLoadingDetail}>
        <ModalFilter
          modalAction={{
            isModalOpen: isModalOpen,
            setIsModalOpen: setIsModalOpen
          }}
          filter={{
            filter: filter,
            setFilter: setFilter
          }}
          filterAction={{
            isLoadingFilter: isLoadingFilter,
            resetFilter: resetFilter,
            applyFilter: applyFilter
          }}
        />
        <DataTable
          buttonConfig={buttonConfig}
          tableName={'permit-monitoring-crm'}
          columns={columns}
          data={data.permitMonitoringCrm}
          loading={isLoading}
          totalData={data.total}
          payload={{
            payload: payload,
            setPayload: setPayload
          }}
          modalOpen={{
            isModalOpen: isModalOpen,
            setIsModalOpen: setIsModalOpen
          }}
          scrollWidth={2500}
        />
      </Spin>
      <ModalInitiatePermitRequest
        modalAction={{
          isModalOpen: isOpenModalInitiate,
          setIsModalOpen: setIsOpenModalInitiate
        }}
      />
    </div>
  );
};

export default PermitMonitoringCrm;