import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useAppDispatch } from 'store/Hooks';
import { getAuthFromDb } from 'store/Auth/Thunk';
import {
  DB_CREDENTIAL_TOKEN,
  DB_CREDENTIAL_USER,
  DB_CREDENTIAL_REFRESH_TOKEN
} from 'constant';

const Splash = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const dispatchTokenState = (callback: Function) => {
    dispatch(getAuthFromDb(DB_CREDENTIAL_TOKEN))
      .unwrap()
      .then((data) => {
        dispatch(getAuthFromDb(DB_CREDENTIAL_REFRESH_TOKEN)).unwrap();
        callback();
      })
      .catch(() => {
        navigate('/login');
      });
  };

  const dispatchUserState = () => {
    dispatch(getAuthFromDb(DB_CREDENTIAL_USER))
      .unwrap()
      .then((data) => {
        return !isEmpty(data)
          ? navigate('/my-activity')
          : navigate('/login');
      })
      .catch(() => {
        navigate('/login');
      });
  };

  useEffect(() => {
    dispatchTokenState(dispatchUserState);
  }, []);

  return (
    <div className="p-3 h-screen bg-primary flex flex-col justify-center">
      <img
        src="images/img_logo_white.svg"
        alt="img_logo"
        className="mt-32 w-52 self-center"
      />
        
      <div className="mt-auto flex flex-col">
        <p className="mb-2 self-center text-sm text-white font-bold">{t('splash.message')}</p>
        <progress className="progress progress-white" />
      </div>
    </div>
  );
};

export default Splash;