import { Base } from './Base';
import { ParamsWorkLocationNoCluster } from 'constant/Types/WorkLocation';

const Cluster = Base.injectEndpoints({
  endpoints: (build) => ({
    getListWorkLocNoCluster: build.mutation({
      query: (params: ParamsWorkLocationNoCluster) => ({
        url: '/work-locations/no-cluster',
        method: 'GET',
        params
      })
    }),
    getByIdWorkLoc: build.mutation({
      query: (id: any) => ({
        url: '/work-locations/detail/'+id,
        method: 'GET',
      })
    }),
  })
});

export const { 
  useGetListWorkLocNoClusterMutation,
  useGetByIdWorkLocMutation
} = Cluster;