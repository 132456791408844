import { Button, Collapse, Grid, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import DataTable from 'components/DataTable';
import React, { useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { FaEdit } from 'react-icons/fa';
import { IoMdTrash } from 'react-icons/io';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import Toast from 'components/Toast';
import { toast } from 'react-toastify';
import { ButtonDataTableTypeItems } from 'constant/Types/DataTableConfigType';
import { transformError } from 'utils/ErrorTransformer';
import { useLocation, useNavigate } from 'react-router';
import { formatDate, formatToReadableDate } from 'utils/Date';
import { hasPermission } from 'utils/Permission';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useDelObligationProductDocMutation, useGetObligationsByProductDocMutation, usePutUpdateSortObligationMutation } from 'api/PermitMonitoring';
import { useAppSelector } from 'store/Hooks';
import { LIST_ROLE_CRM } from 'constant';
import StrictModeDroppable from 'components/StrictModeDroppable';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { GrDrag } from 'react-icons/gr';

const PermitMonitoringCrmFormTabObligation = ({id}: {id: any}) => {
  const { user } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { confirm } = Modal;

  const [getDetail, {
    error: errorDetail,
    isError: isErrorDetail,
    isLoading: isLoadingDetail
  }] = useGetObligationsByProductDocMutation();
  const [putSortObligation] = usePutUpdateSortObligationMutation();
  const [delObligationMutation] = useDelObligationProductDocMutation();

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [obligationList, setObligationList] = useState<any[]>([]);
  const [canCreate, setCanCreate] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [isCrm, setIsCrm] = useState<boolean>(false);
  const screen = Grid.useBreakpoint();
  const isMobile = screen.xs;

  const expandedRowRender = (record: any) => {
    if(record.product_doc_obligation_actions && record.product_doc_obligation_actions.length > 0){
      return (
        <div className='ml-[210px] grid grid-cols-[60%_40%] gap-4'>
          {record.product_doc_obligation_actions.map((action: any, index: number) => (
            <React.Fragment key={index}>
              <div>
                <p className='text-[#b3b3b3]'>{t('permit_name.obligation.action_name')}</p>
                <p className='whitespace-pre-line'>{action.name || '-'}</p>
              </div>
              <div>
                <p className='text-[#b3b3b3]'>{t('permit_name.obligation.last_update')}</p>
                <p>{action.updated_at ? formatToReadableDate(action.updated_at) : '-'}</p>
              </div>
            </React.Fragment>
          ))}
        </div>
      );
    }
    return;
  };

  const handleExpand = (record: any) => {
    if(record.id){
      const newExpandedRowKeys = expandedRowKeys.includes(record.id)
        ? expandedRowKeys.filter(id => id !== record.id)
        : [...expandedRowKeys, record.id];
      setExpandedRowKeys(newExpandedRowKeys);
    }
  };

  const handleMutationDelete = async (record: any) => {
    await delObligationMutation(record.id).unwrap()
      .then(() => {
        setObligationList((prev) => prev.filter((item: any) => item.id !== record.id));
        toast.success(
          <Toast
            message={t('general.success_text')}
            detailedMessage={t('permit_name.obligation.success_delete')}
          />
        );
      })
      .catch(() => {
        toast.error(
          <Toast
            message={t('general.error_text')}
            detailedMessage={t('permit_name.obligation.failed_delete')}
          />
        );
      });
  };
  
  const handleClickDelete = (record: any) => {
    confirm({
      title: t('permit_name.obligation.delete_obligation'),
      icon: <ExclamationCircleFilled />,
      okText: t('manage_group.yes_btn'),
      okType: 'danger',
      cancelText: t('manage_group.no_btn'),
      async onOk() {
        handleMutationDelete(record);
      },
      onCancel() { },
    });
  };

  const getBadgeClassname = (status: string) => {
    const statusLower = status?.toLowerCase();
    switch (statusLower) {
      case 'in progress':
        return 'border-[#FF7B31] text-[#FF7B31] bg-[#FF7B31]/20';
      case 'completed':
        return 'border-[#659711] text-[#659711] bg-[#659711]/20';
      default:
        return '';
    }
  };

  const renderStatusBadge = (status = '') => {
    return <span className={`py-1 px-2  rounded-lg border border-solid text-xs text-center ${getBadgeClassname(status)}`}>{status || '-'}</span>;
  };

  const columns: ColumnsType<any> = [
    {
      title: t('permit_name.obligation.column.action'),
      key: 'action',
      width: 200,
      align: 'center',
      render: (_, record) => (
        <div className='flex'>
          {canEdit && (
            <Button
              type='ghost'
              onClick={() => navigate(`${pathname}/obligation/edit/${record.id}`)}
            >
              <FaEdit style={{ fontSize: '1.65rem' }} className='text-[#5e6e78] p-1' />
            </Button>
          )}
          {canDelete && record?.can_delete && (
            <Button
              type='ghost'
              onClick={() => handleClickDelete(record)}
            >
              <IoMdTrash style={{ fontSize: '1.65rem' }} className='text-[#5e6e78] p-1' />
            </Button>
          )}
          <Button
            type='ghost'
            onClick={() => handleExpand(record)}
          >
            {expandedRowKeys.includes(record.id) ? (
              <FaChevronDown style={{ fontSize: '1.65rem' }} className='text-[#5e6e78] p-1' />
            ) : (
              <FaChevronRight style={{ fontSize: '1.65rem' }} className='text-[#5e6e78] p-1' />
            )}
          </Button>
        </div>
      ),
    },
    {
      title: t('permit_name.obligation.column.obligation_name'),
      dataIndex: 'name',
    },
    {
      title: t('permit_monitoring.obligation.due_date'),
      dataIndex: 'due_date',
      render: (_, record) => record?.due_date ? formatDate(record.due_date) : '-',
    },
    {
      title: t('permit_monitoring.obligation.last_update'),
      dataIndex: 'updated_at',
      render: (_, record) => record?.updated_at ? formatDate(record.updated_at) : '-',
    },
    {
      title: t('permit_monitoring.obligation.status'),
      dataIndex: 'status',
      render: (_, record) => renderStatusBadge(record?.status),
    },
    {
      title: 'Progress',
      dataIndex: 'progress_obligation',
      render: (_, record) => `${record.progress_obligation || 0}%`,
    },
  ];

  const buttonConfig: ButtonDataTableTypeItems = [
    {
      name: t('permit_name.obligation.add_obligation'),
      link: '',
      className: 'bg-[#55A853] text-white font-bold',
      onClick: () => {navigate(`${pathname}/obligation/add`);}
    }
  ];

  const fetchDetail = async (id: any) => {
    let dataDetail = await getDetail(id).unwrap();
    setObligationList(dataDetail);
  };

  const callbackDrag = async (data: any): Promise<void> => {
    const sort = { ...data, product_doc_id: id };
  
    try {
      await putSortObligation({sort}).unwrap();
    } catch (error) {
      const errorMessage = t('permit_name.obligation.failed_sorting');
      toast.error(<Toast message={t('general.error_text')} detailedMessage={errorMessage} />);
      throw new Error(errorMessage);
    }
  };

  const dragEnded = async (param:any) => {
    const { source, destination } = param;
    if(!source || !destination || source.index === destination.index)
      return;

    const tmp = Array.from(obligationList);
    const updatedList = tmp;
    const [movedItem] = updatedList.splice(source.index, 1);
    updatedList.splice(destination.index, 0, movedItem);
    setObligationList(updatedList);

    const sort = { 
      id: obligationList[source.index].id,
      from: source.index,
      to: destination.index, 
      product_doc_id: id 
    };
  
    try {
      await putSortObligation({sort}).unwrap();
    } catch (error) {
      setObligationList(tmp);
      const errorMessage = t('permit_name.obligation.failed_sorting');
      toast.error(<Toast message={t('general.error_text')} detailedMessage={errorMessage} />);
      throw new Error(errorMessage);
    }
  };

  useEffect(() => {
    if (id) {
      fetchDetail(id);
    }
  }, [id]);

  useEffect(() => {
    const paths = pathname.split('/');
    const permissions = hasPermission(paths[1]);
    setCanCreate(permissions.can_create);
    setCanEdit(permissions.can_update);
    setCanDelete(permissions.can_delete);
  }, [pathname]);

  useEffect(() => {
    if (isErrorDetail) {
      const errorMsg:any = errorDetail;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isErrorDetail]);

  useEffect(() => {
    setIsCrm(user?.roles?.some((v: any) => LIST_ROLE_CRM.includes(v.name)));
  }, []);

  return (
    <div className='w-full mb-[176px] md:mb-8'>
      {isMobile ? (
        <>
          {
            canCreate &&
            <Button
              className='bg-[#55A853] text-white w-full'
              onClick={() => navigate(`${pathname}/obligation/add`)}
            >
              {t('permit_name.obligation.add_obligation')}
            </Button>
          }
          <div className='mt-8'>
            <DragDropContext onDragEnd={dragEnded}>
              <StrictModeDroppable droppableId="droppable-wrapper">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {obligationList.map((data: any, index: number) => (
                      <Draggable
                        draggableId={data.id}
                        index={index}
                        key={data.id}
                      >
                        {(_provided, _snapshot) => (
                          <div ref={_provided.innerRef} key={data.id}>
                            <div 
                              ref={_provided.innerRef} 
                              className={(_snapshot?.isDragging ? 'hovering' : '')}
                              {..._provided.draggableProps}
                            >
                              <div className='flex gap-2 mb-4'>
                                <span {..._provided.dragHandleProps}>
                                  <GrDrag className='text-lg mt-2' />
                                </span>
                                <div className='bg-white rounded overflow-hidden flex-grow'>
                                  <Collapse
                                    activeKey={expandedRowKeys} 
                                    expandIcon={() => null} 
                                    className='customCollapse'>
                                    <Collapse.Panel
                                      key={data.id}
                                      header={
                                        <div>
                                          <div className='bg-[#DDEEDD] p-4 flex justify-between items-center'>
                                            <span className='font-medium'>{data.name}</span>
                                            <div className='flex items-center'>
                                              {canEdit && (
                                                <div
                                                  className='cursor-pointer'
                                                  onClick={() => navigate(`${pathname}/obligation/edit/${data.id}`)}
                                                >
                                                  <FaEdit style={{ fontSize: '1.65rem' }} className='text-[#54A853] p-1' />
                                                </div>
                                              )}
                                              {canDelete && data?.can_delete && (
                                                <div
                                                  className='cursor-pointer'
                                                  onClick={() => handleClickDelete(data)}
                                                >
                                                  <IoMdTrash style={{ fontSize: '1.65rem' }} className='text-[#F5222D] p-1' />
                                                </div>
                                              )}
                                              <div
                                                className='cursor-pointer'
                                                onClick={() => handleExpand(data)}
                                              >
                                                {expandedRowKeys.includes(data.id) ? (
                                                  <FaChevronDown style={{ fontSize: '1.65rem' }} className='text-[#5e6e78] p-1' />
                                                ) : (
                                                  <FaChevronRight style={{ fontSize: '1.65rem' }} className='text-[#5e6e78] p-1' />
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className='p-4 grid grid-cols-2 gap-x-2 gap-y-4'>
                                            <div>
                                              <p className='text-[#b3b3b3]'>{t('permit_monitoring.obligation.due_date')}</p>
                                              <p className='whitespace-pre-line mt-1'>{data?.due_date ? formatDate(data.due_date) : '-'}</p>
                                            </div>
                                            <div className='text-right'>
                                              <p className='text-[#b3b3b3]'>{t('permit_monitoring.obligation.last_update')}</p>
                                              <p className='whitespace-pre-line mt-1'>{data?.updated_at ? formatDate(data.updated_at) : '-'}</p>
                                            </div>
                                            <div>
                                              <p className='text-[#b3b3b3]'>{t('permit_monitoring.obligation.status')}</p>
                                              <p className='whitespace-pre-line mt-1'>{renderStatusBadge(data?.status)}</p>
                                            </div>
                                            <div className='text-right'>
                                              <p className='text-[#b3b3b3]'>{t('permit_monitoring.obligation.progress')}</p>
                                              <p className='whitespace-pre-line mt-1'>{`${data.progress_obligation || 0}%`}</p>
                                            </div>
                                          </div>
                                        </div>
                                      }
                                    >
                                      <div className='bg-[#F5F5F5] px-4 py-2'>
                                        <div className='grid grid-cols-2 gap-x-2 gap-y-4'>
                                          {data.product_doc_obligation_actions.map((action: any, index: number) => (
                                            <React.Fragment key={index}>
                                              <div>
                                                <p className='text-[#b3b3b3]'>{t('permit_name.obligation.action_name')}</p>
                                                <p className='whitespace-pre-line mt-1'>{action.name || '-'}</p>
                                              </div>
                                              <div className='text-right'>
                                                <p className='text-[#b3b3b3]'>{t('permit_name.obligation.last_update')}</p>
                                                <p className='whitespace-pre-line mt-1'>{action.updated_at ? formatToReadableDate(action.updated_at) : '-'}</p>
                                              </div>
                                            </React.Fragment>
                                          ))}
                                        </div>
                                      </div>
                                    </Collapse.Panel>
                                  </Collapse>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </StrictModeDroppable>
            </DragDropContext>
          </div>
        </>
      ) : (
        <DataTable 
          data={obligationList}
          setData={setObligationList}
          columns={columns}
          buttonConfig={canCreate ? buttonConfig : []}
          expandable={{
            expandedRowRender: expandedRowRender,
            expandedRowKeys,
            onExpand: (_: any, record: any) => handleExpand(record),
            expandIconColumnIndex: -1, // Remove default expand icon column
          }}
          pagination={false}
          withoutSearch={true}
          sortable={isCrm}
          callbackDrag={callbackDrag}
          loading={isLoadingDetail}
          scrollWidth={1000}
        />
      )}
    </div>
  );
};

export default PermitMonitoringCrmFormTabObligation;