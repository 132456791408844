import { Button, Modal, Spin } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useGetLogCronjobMutation } from 'api/ManageCronjob';
import SimpleDataTable from 'components/SimpleDataTable';
import { DATA_COLOR_STATUS_CRONJOB_LOG } from 'constant';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getStyleBadge } from 'utils/Utility';

interface ILogCronjob {
  id: string;
  time: string;
  status: string;
}

interface IModalLogProps {
  modalAction: any;
  data: any;
}

const ModalLog = ({modalAction, data: dataProp}: IModalLogProps) => {
  const { t } = useTranslation();
  const [data, setData] = useState<ILogCronjob[]>([]);

  const [getLogCronjob, {
    isLoading,
  }] = useGetLogCronjobMutation();

  const handleCancel = () => {
    modalAction.setShowModal(false);
  };

  const fetchLog = async (id: string) => {
    const listLogResponse = await getLogCronjob(id).unwrap();
    const filteredResponse: ILogCronjob[] = listLogResponse.map((log: any) => ({
      id: log?.id || '',
      time: log?.created_at ? moment(log.created_at).format('DD MMMM HH:mm') : '',
      status: log?.value || '',
    }));
    setData(filteredResponse);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'No',
      width: 50,
      render: (_text, _record, index) => `#${index + 1}`,
    },
    {
      title: t('manage_cronjob.time'),
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: t('manage_cronjob.status'),
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (data) => {
        return (
          <span
            style={getStyleBadge(data, DATA_COLOR_STATUS_CRONJOB_LOG)}
            className={'py-1 px-2  rounded-lg border border-solid text-xs text-center'}
          >
            {data || '-'}
          </span>
        );
      }
    },
  ];

  useEffect(() => {
    if(dataProp?.id){
      fetchLog(dataProp.id);
    }
  }, [dataProp?.id]);

  return(
    <Modal
      title={null}
      open={modalAction.showModal}
      footer={null}
      width={500}
      onCancel={() => modalAction.setShowModal(false)}
    >
      <Spin tip="Loading" size="large" spinning={isLoading}>
        <p className='font-bold text-[16px] mb-5'>
          {dataProp?.cronName || '-'}
        </p>
        <div>
          <div>
            <SimpleDataTable
              columns={columns}
              dataSource={data}
            />
          </div>
          <div className='mt-8 flex justify-center'>
            <Button
              className='cancel-green bg-[#E5EDE5] text-[#55A853] border-[#55A853]'
              htmlType="button"
              onClick={handleCancel}
            >
              {t('general.back')}
            </Button>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default ModalLog;