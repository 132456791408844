import type { ColumnsType } from 'antd/es/table';
import { ListPayloadType, ApplicationProfileListType } from 'constant/Types/ApplicationProfileType';
import { useState, useEffect } from 'react';
import { Button } from 'antd';
import { FolderFilled } from '@ant-design/icons';
import DataTable from 'components/DataTable';
import { useListApplicationProfileMutation } from 'api/ApplicationProfile';
import moment from 'moment-timezone';
import { hasPermission } from 'utils/Permission';
import { useLocation } from 'react-router';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/Hooks';
import ApplicationProfileForm from './Form';

const ApplicationProfile = () => {
  const {t} = useTranslation();
  const { user } = useAppSelector((state) => state.auth);
  const { pathname } = useLocation();
  const [canView, setCanView] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState(false);
  const [data, setData] = useState({
    total: 0,
    applicationProfile: []
  });
  const [payload, setPayload] = useState<ListPayloadType>({
    page: 1,
    pageSize: 50,
    search: '',
    searchFields: 'key',
    sort: 'key'
  });
  
  const [applicationProfileList, {
    error: errorApplicationProfileList,
    isError: isErrorApplicationProfileList,
    isLoading
  }] = useListApplicationProfileMutation();

  const fetchList = async () => {
    let convertPayload:any = payload;
    let newPayload:any = {};
    for (const key in convertPayload) {
      if (Array.isArray(convertPayload[key])) {
        if (convertPayload[key].length) {
          newPayload[key] = convertPayload[key];
        }
      } else if (convertPayload[key]){
        if (key === 'sort'){
          const text = convertPayload[key];
          const parts = text.split('_');
          newPayload[key] = parts[0];
        } else {
          newPayload[key] = convertPayload[key];
        }
        
      }
    }

    let listApplicationProfile = await applicationProfileList(newPayload).unwrap();

    listApplicationProfile = {
      ...listApplicationProfile, 
      applicationProfile: listApplicationProfile?.rows.map((el: any, idx: any) => ({...el, key_data: el?.key, key: idx}))
    };
    setData(listApplicationProfile);
  };

  const columns: ColumnsType<ApplicationProfileListType> = [
    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (data, record) => (
        <div>
          {
            canView &&
            <Button
              type='text'
              className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0'
              onClick={() => {
                setId(record?.id);
                setOpenModal(true);
              }}
            >
              <FolderFilled style={{fontSize: '20px'}} className='text-[#55A853] p-1' />
            </Button>
          }
        </div>
      ),
    },
    {
      title: 'Key',
      dataIndex: 'key_data',
      key: 'key',
      sorter: true,
      width: 250,
      defaultSortOrder: 'ascend',
      render: (data, record) => {
        return (<div>{data}</div>);
      }
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      sorter: false,
      width: 700,
      render: (data, record) => {
        return (<div>{data}</div>);
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: false,
      width:200,
      render: (data, record) => {
        return (<div>{data}</div>);
      }
    },
    {
      title: 'Modified By',
      dataIndex: 'updated_by',
      key: 'updated_by',
      sorter: false,
      render: (data, record) => {
        return (<div>{data || '-'}</div>);
      }
    },
    {
      title: 'Last Modified',
      dataIndex: 'updated_at',
      key: 'updated_at',
      sorter: false,
      render: (data, record) => {
        return (<div>{moment(data).tz(user?.timezone?.identifier).utc(true).format('DD-MMM-YYYY HH:mm')}</div>);
      }
    }
  ];

  useEffect(() => {
    const paths = pathname.split('/');
    setCanView(hasPermission(paths[1]).can_view);
    setCanEdit(hasPermission(paths[1]).can_update);
  }, []);

  useEffect(() => {
    fetchList();
  }, [payload]);

  useEffect(() => {
    if (isErrorApplicationProfileList){
      const errorMsg:any = errorApplicationProfileList;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorApplicationProfileList]);

  return(
    <>
      <div className='w-full'>
        <DataTable
          className=''
          columns={columns}
          buttonConfig={[]}
          data={data.applicationProfile}
          loading={isLoading}
          totalData={data.total}
          payload={{
            payload: payload,
            setPayload: setPayload
          }}
          modalOpen={false}
          scrollWidth={1600}
        />
      </div>
      <ApplicationProfileForm
        openModal={openModal}
        setOpenModal={setOpenModal}
        canEdit={canEdit}
        id={id}
        fetchList={fetchList}
      />
    </>
  );
};

export default ApplicationProfile;