import { Button, ConfigProvider, Divider, Form, Modal, Select, Spin } from 'antd';
import { useListApplicationProfileMutation } from 'api/ApplicationProfile';
import { useGetProductDocSharesMutation, usePostProductDocSharesMutation } from 'api/PermitMonitoring';
import { useGetPermitRequestSharesMutation, usePostPermitRequestSharesMutation } from 'api/PermitRequest';
import Toast from 'components/Toast';
import _, { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RxDotFilled } from 'react-icons/rx';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';

const ModalAddEmail = (props: any) => {
  const { modalAction, dataPermit, isPermitRequest } = props;
  const { t } = useTranslation();
  const isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const [validationMessage, setValidationMessage] = useState('');
  const [emailData, setEmailData] = useState<string[]>([]);
  const [allowDomain, setAllowDomain] = useState<any>([]);

  const [getPermitRequestShares, {
    error: getPermitRequestSharesError,
    isError: getPermitRequestSharesIsError,
    isLoading: getPermitRequestSharesIsLoading
  }] = useGetPermitRequestSharesMutation();

  const [getPermitMonitoringERShares, {
    error: getPermitMonitoringERSharesError,
    isError: getPermitMonitoringERSharesIsError,
    isLoading: getPermitMonitoringERSharesIsLoading
  }] = useGetProductDocSharesMutation();

  const [applicationProfileList, {
    error: errorApplicationProfileList,
    isError: isErrorApplicationProfileList,
    isLoading: isLoadingApplicationProfileList
  }] = useListApplicationProfileMutation();

  const [postPermitRequestShares, {
    isLoading: permitRequestSharesIsLoading,
    isSuccess: permitRequestSharesIsSuccess,
    isError: permitRequestSharesIsError,
    error: permitRequestSharesError
  }] = usePostPermitRequestSharesMutation();

  const [postPermitMonitoringERShares, {
    isLoading: permitMonitoringERSharesIsLoading,
    isSuccess: permitMonitoringERSharesIsSuccess,
    isError: permitMonitoringERSharesIsError,
    error: permitMonitoringERSharesError
  }] = usePostProductDocSharesMutation();

  const fetchDetailEmailShares = async () => {
    const data = isPermitRequest 
      ? await getPermitRequestShares(dataPermit.permit_request_id).unwrap()
      : await getPermitMonitoringERShares(dataPermit.permit_request_id).unwrap();
    const temp = data.map((v:any) => v.email);
    setEmailData(temp);
  };

  const fetchAllowDomain = async () => {
    const data = await applicationProfileList({
      search: 'PERMIT_REQUEST_INVITATION_ALLOW_DOMAIN',
      searchFields: 'key'
    }).unwrap();

    try {
      const parseData = JSON.parse(data?.rows?.[0]?.value);
      setAllowDomain(parseData);
    } catch (e) {
      setAllowDomain('');
    }
  };

  useEffect(() => {
    if (dataPermit?.permit_request_id) {
      fetchDetailEmailShares();
      fetchAllowDomain();
    }
  }, [dataPermit]);

  const handleSubmit = () => {
    if (isPermitRequest) {
      const body = {
        permit_request_share: {
          permit_request_id: dataPermit.permit_request_id,
          emails: emailData.map(v => {
            return {
              email: v
            };
          })
        }
      };

      postPermitRequestShares(body);
    } else {
      const body = {
        product_doc_share: {
          product_doc_id: dataPermit.permit_request_id,
          emails: emailData.map(v => {
            return {
              email: v
            };
          })
        }
      };
      
      postPermitMonitoringERShares(body);
    }
  };

  const handleCancel = () => {
    modalAction.setShowModalAddEmailRef(false);
    setEmailData([]);
  };
  
  const handleTagChange = (value: any) => {
    setValidationMessage('');

    if (!(value && value.match(isValidEmail))) {
      setValidationMessage(`${t('permit_request.modal_add_email.please_input_valid_email')}`);
      return;
    }

    if (!isEmpty(allowDomain)) {
      let pass = false;
      allowDomain.forEach((v: string) => {
        if (value.includes(v)) {
          pass = !pass;
        }
      });

      if (!pass) {
        setValidationMessage(`${t('permit_request.modal_add_email.allowed_domain_email')} ${allowDomain}`);
        return;
      }
    }

    setEmailData((prevState: string[]) => {
      if (prevState && prevState.length) {
        return [
          ...prevState,
          value
        ];
      }
    
      return [value];
    });
  };

  const handleOnDeselect = (value: any) => {
    const index = emailData.findIndex(v => v === value);
    const tempEmailData = _.cloneDeep(emailData);
    tempEmailData.splice(index, 1);
    setEmailData(tempEmailData);
  };

  useEffect(() => {
    if (permitRequestSharesIsSuccess) {
      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('general.success_text')}`}
        />
      );
      handleCancel();
    }
    if (permitRequestSharesIsError) {
      const errorMsg: any = permitRequestSharesError;

      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={`${transformError(errorMsg?.data).message}`}
        />
      );
      return;
    }
    if (getPermitRequestSharesIsError) {
      const errorMsg: any = getPermitRequestSharesError;

      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={`${transformError(errorMsg?.data).message}`}
        />
      );
      return;
    }
    if (isErrorApplicationProfileList) {
      const errorMsg: any = errorApplicationProfileList;

      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={`${transformError(errorMsg?.data).message}`}
        />
      );
      return;
    }
  }, [
    permitRequestSharesIsSuccess,
    permitRequestSharesIsError,
    getPermitRequestSharesIsError,
    isErrorApplicationProfileList
  ]);

  useEffect(() => {
    if (permitMonitoringERSharesIsSuccess) {
      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('general.success_text')}`}
        />
      );
      handleCancel();
    }
    if (permitMonitoringERSharesIsError) {
      const errorMsg: any = permitMonitoringERSharesError;

      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={`${transformError(errorMsg?.data).message}`}
        />
      );
      return;
    }
    if (getPermitMonitoringERSharesIsError) {
      const errorMsg: any = getPermitMonitoringERSharesError;

      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={`${transformError(errorMsg?.data).message}`}
        />
      );
      return;
    }
  }, [
    permitMonitoringERSharesIsSuccess,
    permitMonitoringERSharesIsError,
    getPermitMonitoringERSharesIsError
  ]);

  return(
    <Modal
      title={null}
      open={modalAction.showModalAddEmailRef}
      footer={null}
      width={500}
      onCancel={handleCancel}
    >
      <p className='font-bold text-[16px] mb-5'>
        {t('permit_request.modal_add_email.title')}
      </p>
      {
        (getPermitRequestSharesIsLoading || getPermitMonitoringERSharesIsLoading) && isLoadingApplicationProfileList ?
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#55A853',
              },
            }}
          >
            <Spin className="flex justify-center" size="large" />
          </ConfigProvider>
          :
          <>
            <div>
              <p>{t('permit_request.modal_add_email.registered_exporter')}</p>
              <div className='flex text-[#a1a1a1] items-center'>
                <div>{ dataPermit?.request_number }</div>
                <RxDotFilled className='mx-3' />
                <div>{ dataPermit?.permit_location }</div>
              </div>
            </div>
            <Divider />
            <Form
              name="formEmail"
              wrapperCol={{ span: 24 }}
              onFinish={handleSubmit}
              autoComplete="off"
            >
              <Form.Item>
                <div>
                  <div>{t('permit_request.modal_add_email.add_email_address')}</div>
                  <div className='text-[#a1a1a1] text-[14px] mb-2'>{t('permit_request.modal_add_email.you_can_add_more')}</div>
                  {
                    !isEmpty(validationMessage) &&
                    <div className='text-red-500 text-[12px] ml-1'>{validationMessage}</div>
                  }
                  <Select
                    mode="tags"
                    placeholder={t('permit_request.modal_add_email.input_email_address')}
                    notFoundContent={null}
                    onSelect={(e) => handleTagChange(e)}
                    onDeselect={(e) => handleOnDeselect(e)}
                    value={emailData}
                  />
                </div>
              </Form.Item>

              <div className='w-full flex gap-3 justify-center mt-5'>
                <Button
                  type='primary'
                  onClick={() => handleCancel()}
                  className='px-10 border-[#E0E0E0]'
                  style={{ backgroundColor: '#F9F9FA', color: '#9E9E9E' }}
                >
                  {t('general.cancel_btn')}
                </Button>
                <Button
                  htmlType='submit'
                  type='text'
                  className={`px-10 bg-[#54A853] text-white border-[#54A853] 
                    ${isEmpty(emailData) || permitRequestSharesIsLoading || permitMonitoringERSharesIsLoading ? 'bg-[#F9F9FA]' : ''}`}
                  disabled={isEmpty(emailData) || permitRequestSharesIsLoading || permitMonitoringERSharesIsLoading}
                >
                  {t('general.send')}
                </Button>
              </div>
            </Form>
          </>
      }
    </Modal>
  );
};

export default ModalAddEmail;