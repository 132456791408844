import { useEffect, useState } from 'react';
import { Select, Input, Button, Pagination, Spin, ConfigProvider } from 'antd';
import { fill } from 'utils/Timeline';
import { NUM_OF_TRACKS, SORT_BY_PERMIT_TRACKER } from 'constant';
import { buildTrack } from 'utils/Builders';
import { useGetLegendTrackerMutation, useGetTrackerMutation } from 'api/PermitRequest';
import { ENTRIESLIST } from 'constant';
import { SearchOutlined } from '@ant-design/icons';
import { FaFilter } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import ModalFilter from './ModalFilter';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import 'react-timelines/lib/css/style.css';
// @ts-ignore
import Timeline from 'react-timelines';
// @ts-ignore
import $ from 'jquery';

const ViewErPic = () => {
  const currentYear = new Date().getFullYear();
  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const [legendList, setLegendList] = useState<any[]>([]);
  const [trackerList, setTrackerList] = useState<any[]>([]);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timebar, setTimeBar] = useState({});
  const [isPrepare, setIsPrepare] = useState(false);
  const [start, setStart] = useState(new Date(currentYear, 0, 1));
  const [end, setEnd] = useState(new Date(currentYear, 11, 31));
  const [paginationData, setPaginationData] = useState({
    total: 0,
    totalPages: 0
  });
  const [payload, setPayload] = useState({
    lang: getLanguage(),
    page: 1,
    pageSize: 50,
    search: '',
    sort: '',
    work_location_id: [],
    permit_type_id: [],
    pic_name: [],
    status: [
      'Waiting for SUPERIOR to approve',
      'Waiting for ER HEAD to approve',
      'Waiting for doc revise',
      'Assigned',
      'Open',
      'Returned',
      'Processed',
      'Rejected'
    ],
    periodDate: `${dayjs(new Date(currentYear, 0, 1)).format('DD-MMM-YYYY')};${dayjs(new Date(currentYear, 11, 31)).format('DD-MMM-YYYY')}`,
    expiredDate: '',
    pic_id: []
  });
  const [filter, setFilter] = useState({
    work_location_id: [],
    permit_type_id: [],
    status: [
      'Waiting for SUPERIOR to approve',
      'Waiting for ER HEAD to approve',
      'Waiting for doc revise',
      'Assigned',
      'Open',
      'Returned',
      'Processed',
      'Rejected'
    ],
    pic_name: [],
    period_date_from: dayjs(new Date(currentYear, 0, 1)),
    period_date_to: dayjs(new Date(currentYear, 11, 31)),
    expired_date_from: '',
    expired_date_to: ''
  });

  const tracksById = fill(NUM_OF_TRACKS).reduce((acc:any, i:any) => {
    const track = buildTrack(i + 1);
    acc[track.id] = track;
    return acc;
  }, {});

  const [getLegendTracker] = useGetLegendTrackerMutation();
  
  const [getTracker, {
    error: errorGetTracker,
    isError: isErrorGetTracker,
  }] = useGetTrackerMutation();

  const [dataTimeline, setDataTimeline] = useState({
    open: false,
    zoom: 4,
    tracksById,
    tracks: Object.values(tracksById)
  });

  const handleToggleTrackOpen = (track:any) => {
    const tracksById = {
      ...dataTimeline.tracksById,
      [track.id]: {
        ...track,
        isOpen: !track.isOpen
      }
    };

    setDataTimeline({...dataTimeline, tracksById, tracks: Object.values(tracksById)});
  };

  const handleToggleOpen = () => {
    setDataTimeline({...dataTimeline, open: !dataTimeline.open});
  };

  const clickElement = (element:any) =>
    alert(`Clicked element\n${JSON.stringify(element, null, 2)}`);

  useEffect(() => {
    $('.rt-sidebar__header div:first').remove(); 
    $('.rt-sidebar__header div:first').remove();
    $('.rt-sidebar__header').prepend($('<div class="h-[81px] flex"><div class="my-auto pl-5">PLIS NUMBER</div></div>'));
  });

  const fetchLegendTracker = async () => {
    await getLegendTracker({}).unwrap().then((resp:any) => {
      setLegendList(resp);
    });
  };

  const fetchTrackerList = async () => {
    setIsPrepare(false);
    let convertPayload:any = payload;
    let newPayload:any = {};
    for (const key in convertPayload) {
      if (Array.isArray(convertPayload[key])) {
        if (convertPayload[key].length) {
          let data = '';
          for (let i = 0; i < convertPayload[key].length; i++){
            data = data + convertPayload[key][i] + ';';
          }
          newPayload[key] = data;
        }
      } else if (convertPayload[key]){
        newPayload[key] = convertPayload[key];
      }
    }

    await getTracker(newPayload).unwrap().then((resp:any) => {
      // mapping date in tracker list (library required date in object)
      const newRespTracker = resp?.rows?.tracker?.map((el: any, idx: any) => {
        const newEl = el?.elements?.map((elC: any) => {
          return {
            ...elC,
            end: new Date(elC?.end),
            start: new Date(elC?.start)
          };
        });

        return {
          ...el,
          elements: newEl,
          title: el?.title[getLanguage()] || '-'
        };
      });

      // mapping date in header - month list (library required date in object)
      const newRespCellMonth = resp?.rows?.header?.cells?.map((el: any, idx: any) => {
        return {
          ...el,
          start: new Date(el?.start),
          end: new Date(el?.end)
        };
      });

      // mapping date in header - year list (library required date in object)
      const newRespCellYear = resp?.rows?.headerYear?.cells?.map((el: any, idx: any) => {
        return {
          ...el,
          start: new Date(el?.start),
          end: new Date(el?.end)
        };
      });

      let newTimebar = [];
      newTimebar[0] = {
        ...resp?.rows?.headerYear,
        cells: newRespCellYear
      };
      newTimebar[1] = {
        ...resp?.rows?.header,
        cells: newRespCellMonth,
        useAsGrid: true,
        style: {}
      };
      setTimeBar(newTimebar);
      setTrackerList(newRespTracker);
      setIsLoadingFilter(false);
      setPaginationData({
        total: resp?.total,
        totalPages: resp?.totalPages
      });
      
      const startDate = new Date(filter?.period_date_from.format('DD-MMM-YYYY'));
      const endDate = new Date(filter?.period_date_to.format('DD-MMM-YYYY'));
      startDate.setDate(startDate.getDate() + 1);
      endDate.setDate(endDate.getDate() + 1);
      setStart(startDate);
      setEnd(endDate);
    });
  };

  
  const applyFilter = () => {
    setIsLoadingFilter(true);
    setPayload({
      ...payload,
      page: 1,
      work_location_id: filter.work_location_id,
      permit_type_id: filter.permit_type_id,
      status: filter?.status,
      periodDate: `${dayjs(filter.period_date_from).format('DD-MMM-YYYY')};${dayjs(filter.period_date_to).format('DD-MMM-YYYY')}`,
      expiredDate: `${dayjs(filter.expired_date_from).format('DD-MMM-YYYY')};${dayjs(filter.expired_date_to).format('DD-MMM-YYYY')}`,
      pic_id: filter.pic_name
    });
  };

  const resetFilter = () => {
    setIsLoadingFilter(true);
    setFilter({
      work_location_id: [],
      permit_type_id: [],
      status: [],
      pic_name: [],
      period_date_from: dayjs(new Date(currentYear, 0, 1)),
      period_date_to: dayjs(new Date(currentYear, 11, 31)),
      expired_date_from: '',
      expired_date_to: ''
    });
    setPayload({
      ...payload,
      work_location_id: [],
      permit_type_id: [],
      status: filter?.status,
      periodDate: `${dayjs(new Date(currentYear, 0, 1)).format('DD-MMM-YYYY')};${dayjs(new Date(currentYear, 11, 31)).format('DD-MMM-YYYY')}`,
      expiredDate: '',
      pic_id: []
    });
  };

  const getPagination = () => {
    return `${paginationData?.total === 0 ? '0' : 
      payload?.page === 1 ? '1' : 
        (payload?.pageSize  * (payload?.page - 1) + 1)} - 
    ${paginationData?.total < payload?.pageSize ? paginationData?.total : 
          payload?.page < paginationData?.totalPages ? (payload?.page * payload?.pageSize) : 
            paginationData?.total}`;
  };

  useEffect(() => {
    fetchLegendTracker();
    fetchTrackerList();
  }, []);

  useEffect(() => {
    fetchTrackerList();
  }, [payload]);

  useEffect(() => {
    setPayload({
      ...payload,
      lang: getLanguage()
    });
  }, [getLanguage()]);

  useEffect(() => {
    if (Object.keys(timebar).length > 0){
      setIsPrepare(true);
    }
  }, [timebar]);

  useEffect(() => {
    if (isErrorGetTracker){
      const errorMsg:any = errorGetTracker;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetTracker]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#55A853',
        },
      }}
    >
      <div className='w-full overflow-x-auto max-md:hidden'>
        <div className='grid grid-cols-1 md:grid-cols-3 justify-between mb-5'>
          <div className='grid grid-cols-12'>
            <p className='col-span-2 text-[14px] font-bold my-auto pl-3'>{t('general.sort_by')}</p>
            <Select
              options={SORT_BY_PERMIT_TRACKER}
              className='col-span-10 my-auto'
              allowClear
              style={{ width: '80%' }}
              placeholder={t('general.sort_by')}
              filterOption={false}
              onChange={(e) => {
                setPayload({
                  ...payload,
                  sort: e || ''
                });
              }}
            />
          </div>
          <div className='flex items-center justify-center mb-5 md:mb-0'>
            <p className='text-[12px] font-normal mr-3'>{t('data_table.show_text')}</p>
            <Select
              defaultValue={payload.pageSize}
              style={{ width: 120 }}
              options={ENTRIESLIST}
              onChange={(e) => {
                setPayload({
                  ...payload,
                  pageSize: e
                });
              }}
            />
            <p className='text-[12px] font-normal ml-3'>{t('data_table.entries_text')}</p>
          </div>
          <div className='flex md:justify-end flex-row items-center'>
            <Input 
              className='md:w-[70%] mr-5' 
              size="large" 
              placeholder={t('data_table.search_placeholder') || ''} 
              prefix={<SearchOutlined className='text-[#55A853]' />} 
              onChange={(e) => {
                if (e?.target?.value?.length > 2 || e?.target?.value?.length === 0) {
                  setPayload({
                    ...payload,
                    search: e?.target?.value
                  });
                }
              }}
            />
            <Button
              type='text'
              className='shadow-md shadow-gray-400 rounded-sm p-0 bg-white'
              onClick={() => setIsModalOpen(!isModalOpen)}
            >
              <FaFilter style={{fontSize: '30px'}} className='text-[#55A853] p-1' />
            </Button>
          </div>
        </div>
        <div className='flex mb-2'>
          {
            legendList.map((el: any) => (
              <div className='mx-4 flex text-[12px] font-bold'>
                <div 
                  className='w-[15px] h-[15px] m-auto mr-1 rounded-sm' 
                  style={{backgroundColor: el?.value}}>  
                </div>
                <p className='m-auto'>{el?.key}</p>
              </div>
            ))
          }
        </div>
        {
          !isPrepare ? (
            <div className='grid grid-cols-1 m-auto'>
              <Spin size='large'></Spin>
            </div>) : (
            <Timeline
              scale={{
                start,
                end,
                zoom: dataTimeline.zoom
              }}
              isOpen={dataTimeline.open}
              toggleOpen={handleToggleOpen}
              clickElement={clickElement}
              timebar={timebar}
              tracks={trackerList}
              toggleTrackOpen={handleToggleTrackOpen}
              enableSticky
              scrollToNow
            />
          )
        }
        <div className='flex justify-between mt-5'>
          <div className='pl-3'>{`${t('data_table.show_text')} ${getPagination()} ${t('data_table.of_text')} ${paginationData?.total} ${t('data_table.entries_text')}`}</div>
          <Pagination
            total={paginationData?.total}
            onChange={(e) => {
              setPayload({
                ...payload,
                page: e
              });
            }}
            current={payload?.page}
            pageSize={payload?.pageSize}
          />
        </div>
        <ModalFilter
          modalAction={{
            isModalOpen: isModalOpen,
            setIsModalOpen: setIsModalOpen
          }}
          filter={{
            filter: filter,
            setFilter: setFilter
          }}
          filterAction={{
            isLoadingFilter: isLoadingFilter,
            resetFilter: resetFilter,
            applyFilter: applyFilter
          }}
        />
      </div>
    </ConfigProvider>
  );
};

export default ViewErPic;