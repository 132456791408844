import { useEffect, useState } from 'react';
import { ListPayloadType } from 'constant/Types/EmailNotificationType';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DataTable from 'components/DataTable';
import type { ColumnsType } from 'antd/es/table';
import ModalFilter from './ModalFilter';
import { useListEmailNotificationMutation } from 'api/EmailNotification';
import Simple from 'components/SeeMore/Simple';
import ReceiverListEmail from 'components/SeeMore/ReceiverListEmail';
import { useAppSelector } from 'store/Hooks';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

const EmailNotification = () => {
  const { user } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [data, setData] = useState({
    total: 0,
    emailNotifications: []
  });
  const [payload, setPayload] = useState<ListPayloadType>({
    page: 1,
    pageSize: 50,
    search: '',
    sent_date: ''
  });
  const [filter, setFilter] = useState({
    sent_date: null,
  });

  const [emailNotificationList, {
    error,
    isError,
    isLoading
  }] = useListEmailNotificationMutation();

  const columns: ColumnsType<any> = [
    {
      title: t('email_notification.datetime_sent'),
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: false,
      width: 200,
      render: (data, record) => {
        return (<div>{data? moment(data).tz(user?.timezone?.identifier).utc(true).format('DD-MMM-YYYY, HH:mm'): '-'}</div>);
      }
    },
    {
      title: t('email_notification.subject'),
      dataIndex: 'subject',
      key: 'subject',
      sorter: false,
      width: 300,
      render: (data, record) => {
        return (<div>{data || '-'}</div>);
      }
    },
    {
      title: t('email_notification.email_body'),
      dataIndex: 'body',
      key: 'body',
      sorter: false,
      render: (data, record) => {
        return (<Simple data={data} limit={50}/>);
      }
    },
    {
      title: t('email_notification.receiver_list_email'),
      dataIndex: 'receiver_list_email',
      key: 'receivers',
      sorter: false,
      width: 400,
      render: (data, record) => {
        return (<ReceiverListEmail to={record?.to || []} cc={record?.cc || []} limit={4}/>);
      }
    },
  ];

  const applyFilter = () => {
    setIsLoadingFilter(true);
    setPayload({
      ...payload,
      page: 1,
      sent_date: `${dayjs(filter?.sent_date?.[0])
        .hour(0)
        .minute(0)
        .second(0)
        .format()};${dayjs(filter?.sent_date?.[1])
          .hour(23)
          .minute(59)
          .second(59)
          .format()}`
    });
  };

  const resetFilter = () => {
    setIsLoadingFilter(true);
    setFilter({
      sent_date: null
    });
    setPayload({
      ...payload,
      sent_date: ''
    });
  };

  const fetchList = async () => {
    let convertPayload: any = payload;
    let newPayload: any = {};
    for (const key in convertPayload) {
      if (Array.isArray(convertPayload[key])) {
        if (convertPayload[key].length) {
          newPayload[key] = convertPayload[key];
        }
      } else if (convertPayload[key]) {
        newPayload[key] = convertPayload[key];
      }
    }

    let listEmailNotification = await emailNotificationList(newPayload).unwrap();

    listEmailNotification = {
      ...listEmailNotification,
      emailNotifications: listEmailNotification?.rows.map((el: any, idx: any) => ({ ...el, key: idx }))
    };
    setData(listEmailNotification);
    setIsLoadingFilter(false);
  };

  useEffect(() => {
    fetchList();
  }, []);

  useEffect(() => {
    setIsLoadingFilter(true);
    fetchList();
  }, [payload]);

  useEffect(() => {
    if (isError) {
      const errorMsg: any = error;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isError]);

  return(
    <div className="w-full">
      <ModalFilter
        modalAction={{
          isModalOpen: isModalOpen,
          setIsModalOpen: setIsModalOpen
        }}
        filter={{
          filter: filter,
          setFilter: setFilter
        }}
        filterAction={{
          isLoadingFilter: isLoadingFilter,
          resetFilter: resetFilter,
          applyFilter: applyFilter
        }}
      />
      <DataTable
        className=''
        columns={columns}
        data={data.emailNotifications}
        buttonConfig={[]}
        loading={isLoading}
        totalData={data.total}
        payload={{
          payload: payload,
          setPayload: setPayload
        }}
        modalOpen={{
          isModalOpen: isModalOpen,
          setIsModalOpen: setIsModalOpen
        }}
        scrollWidth={1500}
      />
    </div>
  );
};

export default EmailNotification;