import { Button, Modal, Form, AutoComplete, DatePicker, Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import { ExclamationCircleFilled } from '@ant-design/icons';

import Toast from 'components/Toast';
import { useProductDocsListMutation } from 'api/Options';
import { getIsDifferentData } from 'utils/Utility';

const ModalEditReference = (props: any) => {
  const { 
    form,
    modalAction, 
    handleSubmit,
    loading } = props;
  const { t } = useTranslation();

  const { confirm } = Modal;
  const [isAutoReferencePermit, setIsAutoReferencePermit] = useState(false);
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const [firstData, setFirstData] = useState({
    reference_permit: '',
    expired_date: null
  });

  const [productDocsList] = useProductDocsListMutation();

  const fetchReferencePermit = async (value: any) => {
    setIsAutoReferencePermit(false);
    if (value) {
      const payload = {
        registration_number: value ?? '',
      }; 
      let data = await productDocsList(payload).unwrap();
      setOptions([...data?.map((el:any) =>  ({value: el?.registration_number, id: el?.id, expired_date: el?.expired_date}))]);
      return;
    }
    setOptions([]);
  };

  const selectReferencePermit = (_:any, value: any) => {
    setIsAutoReferencePermit(true);
    if (!value?.expired_date) {
      toast.warning(<Toast message={t('general.warning_text')} detailedMessage={t('permit_request.request_information.empty_expired_date')} />);
      return;
    }
    form.setFieldsValue({
      ...form, 
      expired_date: dayjs(moment(new Date(value?.expired_date)).format('DD-MMM-YYYY'), 'DD-MMM-YYYY')
    });
  };

  const onChangeExpired = () => {
    setIsAutoReferencePermit(false);
  };

  const handleCancel = () => {
    const mappingConvertData = {
      ...form.getFieldsValue(),
      expired_date: form.getFieldValue('expired_date')? moment(new Date(form.getFieldValue('expired_date'))).format('YYYY-MM-DD') : null
    };
    if (!getIsDifferentData(mappingConvertData, firstData, ['reference_permit', 'expired_date'])) {
      modalAction.setShowModalEditRef(false);
      return;
    }

    confirm({
      title: t('general.label_cancel_data'),
      icon: <ExclamationCircleFilled />,
      okText: t('manage_group.yes_btn'),
      okType: 'danger',
      cancelText: t('manage_group.no_btn'),
      onOk() {
        modalAction.setShowModalEditRef(false);
      },
    });
  };

  const handleFinishFailed = () => {
    toast.error(<Toast message={t('general.error_text')} detailedMessage={t('permit_request.modal_edit_reference.fill_form_validation_text')} />);
  };

  useEffect(() => {
    if(!modalAction?.showModalEditRef){
      form.resetFields();
    }

    setFirstData({
      ...form.getFieldsValue(),
      expired_date: form.getFieldValue('expired_date') ? moment(new Date(form.getFieldValue('expired_date'))).format('YYYY-MM-DD') : null
    });
    setIsAutoReferencePermit(false);
  }, [modalAction?.showModalEditRef]);

  return(
    <Modal
      title={null}
      open={modalAction.showModalEditRef}
      footer={null}
      width={500}
      onOk={handleSubmit}
      onCancel={handleCancel}
    >
      <p className='font-bold text-[16px] mb-5'>
        {t('permit_request.modal_edit_reference.title')}
      </p>
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        requiredMark={false}
        onFinish={handleSubmit}
        onFinishFailed={handleFinishFailed}
        name='form'
      >
        <Form.Item
          name='reference_permit'
          label={t('permit_request.request_information.reference_permit')}
          rules={[{ required: true, message: `${t('permit_request.request_information.reference_permit')} ${t('general.required_field')}` }]}
        >
          <AutoComplete
            options={options}
            onSearch={fetchReferencePermit}
            onSelect={selectReferencePermit}
            placeholder={t('permit_request.request_information.reference_permit_placeholder') || ''}
          />
        </Form.Item>
        <Form.Item
          name='expired_date'
          label={t('permit_request.request_information.expired_date')}
          rules={[{ required: true, message: `${t('permit_request.request_information.expired_date')} ${t('general.required_field')}` }]}
          className='mt-3'
        >
          <DatePicker
            className='w-full'
            placeholder={t('permit_request.request_information.expired_date_placeholder') || ''}
            format={'DD-MMM-YYYY'}
            onChange={onChangeExpired}
            disabled={isAutoReferencePermit}
          />
        </Form.Item>
        <Form.Item className='mt-5'>
          <Row className='h-full'>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Button
                className='cancel-green bg-[#E5EDE5] text-[#55A853] border-[#55A853] mr-2'
                htmlType="button"
                onClick={handleCancel}
                disabled={loading.isLoadingSubmit}
              >
                {t('general.cancel_btn')}
              </Button>
              <Button
                className='btn-draft bg-[#F7B648] text-white'
                htmlType="submit"
                disabled={loading.isLoadingSubmit}
              >
                {t('general.save_btn')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalEditReference;