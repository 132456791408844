import { PermitInitiateBody, ListPayloadType, PermitInitiatePutBody } from 'constant/Types/PermitInitiate';
import { Base } from './Base';

const PermitInitiate = Base.injectEndpoints({
  endpoints: (build) => ({
    postPermitInitiate: build.mutation({
      query: (body: PermitInitiateBody) => ({
        url: '/permit-initiates',
        method: 'POST',
        body
      }),
    }),
    getListPermitInitiate: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/permit-initiates',
        method: 'GET',
        params
      }),
    }),
    getByIdPermitInitiate: build.mutation({
      query: (id: string) => ({
        url: '/permit-initiates/'+id,
        method: 'GET'
      }),
    }),
    putPermitInitiate: build.mutation({
      query: (body: PermitInitiatePutBody) => ({
        url: '/permit-initiates/'+body.id,
        method: 'PUT',
        body
      }),
    }),
    availablePermitInitiate: build.mutation({
      query: () => ({
        url: '/permit-initiates/available',
        method: 'GET'
      }),
    }),
  }),
});

export const {
  usePostPermitInitiateMutation,
  useGetListPermitInitiateMutation,
  useGetByIdPermitInitiateMutation,
  usePutPermitInitiateMutation,
  useAvailablePermitInitiateMutation
} = PermitInitiate;
