import { AES, enc } from 'crypto-js';

export const encodeBase64 = (value: string): string => btoa(value);
export const decodeBase64 = (value: string): string => atob(value);

export const encodeAES = (value: string): string =>
  AES.encrypt(value, `${process.env.REACT_APP_SECRET_AES}`).toString();
export const encodeObjAES = (value: any): string =>
  AES.encrypt(JSON.stringify(value), `${process.env.REACT_APP_SECRET_AES}`).toString();

export const decodeAES = (value: string): string =>
  AES.decrypt(value, `${process.env.REACT_APP_SECRET_AES}`).toString(enc.Utf8);
export const decodeObjAES = (value: string): any =>
  JSON.parse(AES.decrypt(value, `${process.env.REACT_APP_SECRET_AES}`).toString(enc.Utf8));