import { Button, Form, Modal, Select, Spin, Empty } from 'antd';
import { useMeMutation, useUserOrganizationMutation } from 'api/User';
import { UserOrganization } from 'constant/Types/UserType';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toast from 'components/Toast';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import { useCompanySearchMutation, useDepartmentSearchMutation, useWorkLocationHrisMutation } from 'api/Options';
import { OptionType } from 'constant/Types/OptionType';

const ModalUserOrganization = (props: any) => {
  const { dataUser } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { openModal, setOpenModal } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState<OptionType[]>([]);
  const [worklocations, setWorklocations] = useState<OptionType[]>([]);
  const [departments, setDepartments] = useState<OptionType[]>([]);
  const [organization, setOrganization] = useState<UserOrganization>({
    organization: {
      company_name: dataUser.company_name || '',
      department_name: dataUser.department_name || '',
      work_location_name: dataUser.work_location_name || ''
    }
  });

  const [getMe] = useMeMutation();
  const [userOrganization, {
    error,
    isSuccess,
    isError
  }] = useUserOrganizationMutation();

  const [companyList, {
    isLoading: isLoadingCompany
  }] = useCompanySearchMutation();
  const [worklocationList, {
    isLoading: isLoadingWorklocation
  }] = useWorkLocationHrisMutation();
  const [departmentList, {
    isLoading: isLoadingDepartment
  }] = useDepartmentSearchMutation();

  const fetchCompany = async (value: any) => {
    if (value.length > 2 || !value) {
      const payload = {
        filters: `company@=${value}`
      };
      let { data } = await companyList(payload).unwrap();
      const newDataCompany = data.map((el: any) => {
        if (el) {
          return {
            label: el?.company,
            value: el?.company
          };
        }
        return null;
      });

      setCompanies(newDataCompany.filter((v: any) => v));
    }
  };

  const fetchWorklocations = async (value: any) => {
    if (value.length > 2 || !value) {
      const payload = {
        filters: `company@=${value}`
      };
      let { data } = await worklocationList(payload).unwrap();
      const newDataWorklocation = data.map((el: any) => {
        if (el) {
          return {
            label: el?.workLocation,
            value: el?.workLocation
          };
        }
        return null;
      });

      setWorklocations(newDataWorklocation.filter((v: any) => v));
    }
  };

  const fetchDepartment = async (value: any) => {
    if (value.length > 2 || !value) {
      const payload = {
        filters: `department@=${value}`
      };
      let { data } = await departmentList(payload).unwrap();
      const newDataDepartment = data.map((el: any) => {
        if (el) {
          return {
            label: el?.department,
            value: el?.department
          };
        }
        return null;
      });

      setDepartments(newDataDepartment.filter((v: any) => v));
    }
  };

  const handleOk = async () => {
    setIsLoading(true);
    await userOrganization(organization).unwrap();
    setTimeout(async () => {
      await getMe({}).unwrap();
    }, 1000);
  };

  useEffect(() => {
    fetchCompany('');
    fetchDepartment('');
    fetchWorklocations('');
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      company: dataUser.company_name,
      worklocation: dataUser.work_location_name,
      department: dataUser.department_name
    });
  }, [dataUser]);

  useEffect(() => {
    if (isError) {
      setIsLoading(false);
      const errorMsg: any = error;
      toast.error(<Toast message={'Error'} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(<Toast message={'Success'} detailedMessage={`${t('user_organization.success_submit_organization')}`} />);
      setTimeout(async () => {
        setOpenModal(false);
        setIsLoading(false);
      }, 1000);
    }
  }, [isSuccess]);

  return (
    <>
      <Modal
        open={openModal}
        centered
        closable={false}
        footer={false}
      >
        <div className='flex flex-row justify-center mb-5'>{t('user_organization.title')}</div>
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 6 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={handleOk}
          form={form}
        >
          <Form.Item
            label={t('user_organization.company_label')}
            name="company"
            rules={[{ required: true, message: `${t('user_organization.required_msg.company')}` }]}
          >
            <Select
              showSearch
              placeholder={t('user_organization.company_placeholder')}
              filterOption={false}
              onSearch={fetchCompany}
              notFoundContent={isLoadingCompany ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              options={companies}
              onChange={(v) => setOrganization({ organization: { ...organization.organization, company_name: v } })}
              value={organization.organization.company_name}
            />
          </Form.Item>
          <Form.Item
            className='mt-3'
            label={t('user_organization.worklocation_label')}
            name="worklocation"
            rules={[{ required: true, message: `${t('user_organization.required_msg.worklocation')}` }]}
          >
            <Select
              showSearch
              placeholder={t('user_organization.worklocation_placeholder')}
              filterOption={false}
              onSearch={fetchWorklocations}
              notFoundContent={isLoadingWorklocation ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              options={worklocations}
              onChange={(v) => setOrganization({ organization: { ...organization.organization, work_location_name: v } })}
              value={organization.organization.work_location_name}
            />
          </Form.Item>
          <Form.Item
            className='mt-3'
            label={t('user_organization.department_label')}
            name="department"
            rules={[{ required: true, message: `${t('user_organization.required_msg.department')}` }]}
          >
            <Select
              showSearch
              placeholder={t('user_organization.department_placeholder')}
              filterOption={false}
              onSearch={fetchDepartment}
              notFoundContent={isLoadingDepartment ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              options={departments}
              onChange={(v) => setOrganization({ organization: { ...organization.organization, department_name: v } })}
              value={organization.organization.department_name}
            />
          </Form.Item>
          <div className='flex flex-row justify-center mt-5 max-md:pb-48'>
            <Form.Item>
              <Button
                type='text'
                className='bg-[#55A853] text-white px-10'
                disabled={isLoading}
                htmlType="submit"
              >
                {isLoading ? <Spin /> : t('general.submit_btn') || ''}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ModalUserOrganization;