import { Button, Modal, Form, Input, Upload, UploadFile, Image } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Toast from 'components/Toast';
import { MAXFILESIZE } from 'constant';
import { usePostPermitTypeMutation, usePutPermitTypeMutation } from 'api/PermitType';
import { transformError } from 'utils/ErrorTransformer';
import { CloseOutlined } from '@ant-design/icons';

const ModalForm = (props:any) => {
  const {dataUpdate, modalAction, callbackSuccess} = props;

  const { t } = useTranslation();
  const { Dragger } = Upload;
  const [form] = Form.useForm();
  const initialForm = {
    name: '',
    fileData: null
  };
  const fileData = Form.useWatch('fileData', form);

  const [postPermitType, {
    error: errorPostPermitType,
    isError: isErrorPostPermitType,
    isSuccess: isSuccessPostPermitType,
    isLoading: isLoadingPostPermitType
  }] = usePostPermitTypeMutation();
  const [putPermitType, {
    error: errorPutPermitType,
    isError: isErrorPutPermitType,
    isSuccess: isSuccessPutPermitType,
    isLoading: isLoadingPutPermitType
  }] = usePutPermitTypeMutation();

  const handleSubmit = async () => {
    let payload = form.getFieldsValue();
  
    const buildPayload = (name: string, fileData: any = undefined, fileName: any = undefined) => {
      const permitType: any = { name };
      if (fileData !== undefined && fileName !== undefined) {
        permitType.file = fileData;
        permitType.filename = fileName;
      }
      return { permit_type: permitType };
    };
  
    if (dataUpdate) {
      if (payload.fileData) {
        if (typeof payload.fileData === 'string') {
          payload = buildPayload(payload.name);
        } else {
          payload = buildPayload(payload.name, payload.fileData.base64, payload.fileData.fileName);
        }
      } else {
        payload = buildPayload(payload.name, null, null);
      }
      await putPermitType({ id: dataUpdate.id, body: payload }).unwrap();
    } else {
      payload = buildPayload(payload.name, payload.fileData?.base64, payload.fileData?.fileName);
      await postPermitType(payload).unwrap();
    }
  };
  

  const handleFileChange = (opt: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(opt.file);

    reader.onload = () => {
      form.setFieldValue('fileData', {
        fileName: opt.file.name,
        base64: reader.result,
      });
    };
  };

  const beforeUploadFile = (file: UploadFile) => {
    const typeUpload = ['png', 'jpg', 'jpeg'];
    const { type, name, size } = file;
    let specifiecType = type?.substring((type?.lastIndexOf('/') || 0) + 1);
    if (specifiecType === '' || specifiecType === 'x-zip-compressed' || specifiecType?.includes('officedocument')) {
      specifiecType = name.split('.').pop();
    }
    const isAllowedType = typeUpload.includes(specifiecType || '');
    if (!isAllowedType) {
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={`${t('permit_request.list_doc.upload_failed_type_one') + name + t('permit_request.list_doc.upload_failed_type_two')} (${typeUpload})`}
        />);
      return false;
    }

    if ((size || 0) > MAXFILESIZE.VAL_ON_BYTE) {
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={`
            ${t('permit_request.list_doc.upload_failed_type_one') +
            name + t('permit_request.list_doc.upload_failed_type_three') +
            MAXFILESIZE.VAL_CONVERT_BYTE_TO_CURRENT +
            MAXFILESIZE.CURRENT_MEMORY}`
          }
        />);
      return false;
    }

    return true;
  };

  const handleCloseImage = () => {
    form.setFieldValue('fileData', null);
  };

  const handleCancel = () => {
    modalAction.setShowModal(false);
    form.setFieldsValue(initialForm);
  };

  useEffect(() => {
    if(modalAction.showModal){
      if(dataUpdate){
        form.setFieldsValue({
          name: dataUpdate?.name,
          fileData: dataUpdate?.image
        });
      }
      else{
        form.setFieldsValue(initialForm);
      }
    }
  }, [dataUpdate, modalAction.showModal]);

  useEffect(() => {
    if (isErrorPostPermitType) {
      const errorMsg: any = errorPostPermitType;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorPostPermitType, errorPostPermitType]);

  useEffect(() => {
    if (isErrorPutPermitType) {
      const errorMsg: any = errorPutPermitType;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorPutPermitType, errorPutPermitType]);

  useEffect(() => {
    if (isSuccessPostPermitType) {
      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('general.success_text')}`}
        />
      );
      modalAction.setShowModal(false);
      form.setFieldsValue(initialForm);
      callbackSuccess();
    }
  }, [isSuccessPostPermitType]);

  useEffect(() => {
    if (isSuccessPutPermitType) {
      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('general.success_text')}`}
        />
      );
      modalAction.setShowModal(false);
      callbackSuccess();
    }
  }, [isSuccessPutPermitType]);

  return(
    <Modal
      title={null}
      open={modalAction.showModal}
      footer={null}
      width={500}
      onCancel={() => modalAction.setShowModal(false)}
    >
      <p className='font-bold text-[16px] mb-5'>
        {!dataUpdate ? t('general.add_permit_type') :t('general.edit_permit_type')}
      </p>
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        requiredMark={false}
        onFinish={handleSubmit}
        name='form'
      >
        <Form.Item
          name='name'
          label={
            <div className='font-semibold'>{t('permit_type.permit_type_name')}</div>
          }
          rules={[{ required: true, message: `${t('permit_type.permit_type_name_placeholder')} ${t('general.required_field')}` }]}
        >
          <Input 
            placeholder={t('permit_type.permit_type_name_placeholder') || ''} 
            disabled={dataUpdate?.total_permit_name > 0} 
          />
        </Form.Item>
        <Form.Item name="fileData" className='hidden'/>
        <Form.Item
          label={
            <div>
              <div className='font-semibold'>{t('general.upload_file')}</div>
              <p className='m-0 text-[#c3c3c3]'>
                {t('permit_type.upload_file_sub_label')}
              </p>
            </div>
          }
          rules={[{ required: true, message: `${t('general.upload_file')} ${t('general.required_field')}` }]}
          className='mt-5'
        >
          {(fileData) ? (
            <div className="relative flex flex-col gap-2 items-center w-[300px] m-auto">
              <div
                className="absolute top-0 right-0 z-10 bg-slate-200 p-0 hover:bg-slate-300 cursor-pointer flex items-center justify-center"
                style={{ width: 24, height: 24 }}
                onClick={handleCloseImage}
              >
                <CloseOutlined />
              </div>
              {fileData.base64 ? (
                <>
                  <Image
                    src={fileData.base64.toString()}
                    alt={fileData.fileName}
                    width={300}
                    height={300}
                  />
                  <p>{fileData.fileName}</p>
                </>
              ): (
                <>
                  <Image
                    src={`${process.env.REACT_APP_BASE_URL}images/${fileData}`}
                    alt={dataUpdate?.name || '-'}
                    width={300}
                    height={300}
                  />
                </>
              )}
            </div>
          ) : (
            <Dragger
              className='font-normal'
              customRequest={handleFileChange}
              showUploadList={false}
              beforeUpload={beforeUploadFile}
            >
              <span>{t('permit_request.list_doc.attach_two_placeholder')}
                <span className='font-bold text-[#55A853] underline underline-offset-2'>
                  {t('permit_request.list_doc.browser_txt')}
                </span>
              </span>
            </Dragger>
          )}
        </Form.Item>
        
        <div className="flex justify-center gap-2 mt-4">
          <Button
            className='cancel-green bg-[#E5EDE5] text-[#55A853] border-[#55A853] mr-2'
            htmlType="button"
            onClick={handleCancel}
            disabled={isLoadingPostPermitType || isLoadingPutPermitType}
          >
            {t('general.cancel_btn')}
          </Button>
          <Button
            className='bg-[#55A853] text-white'
            htmlType="submit"
            disabled={isLoadingPostPermitType || isLoadingPutPermitType}
          >
            {!dataUpdate ? t('general.add_permit_type') :t('general.edit_permit_type')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalForm;