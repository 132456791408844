import { Button, Modal } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import SimpleDataTable from 'components/SimpleDataTable';
import { EditFilled, ExclamationCircleFilled, EyeOutlined } from '@ant-design/icons';
import Toast from 'components/Toast';
import { toast } from 'react-toastify';
import { useGetErPicPerformancesMutation, usePostResetScoreMutation } from 'api/ErPicPerformances';
import { transformError } from 'utils/ErrorTransformer';
import { useAppSelector } from 'store/Hooks';
import { FaChevronDown, FaChevronRight, FaFilter } from 'react-icons/fa';
import ModalFilter, { IModalFilterRef } from './ModalFilter';
import { Link } from 'react-router-dom';
import ModalAdjustment from './ModalAdjustment';
import ModalHistoryAdjustment from './ModalHistoryAdjustment';

const listRoleAdmin = [
  'Administrator',
];

const ReportErPerformance = () => {
  const { user } = useAppSelector((state) => state.auth);
  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const { confirm } = Modal;
  const modalFilterRef = useRef<IModalFilterRef>(null);

  const [erPicPerformancesMutation, {
    error: errorGetErPicPerformances,
    isError: isErrorGetErPicPerformances,
    isLoading
  }] = useGetErPicPerformancesMutation();
  const [postResetScore, {
    isLoading: isLoadingResetScore
  }] = usePostResetScoreMutation();

  const [erPicPerformanceList, setErPicPerformanceList] = useState<any[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [filter, setFilter] = useState<any>({
    reset_date: null,
    worklocation: [],
  });
  const [payload, setPayload] = useState<any>({
    reset_date: null,
    worklocation: [],
  });
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [showModalAdjustment, setShowModalAdjustment] = useState(false);
  const [showModalHistoryAdjustment, setShowModalHistoryAdjustment] = useState(false);
  const [historyPicSelected, setHistoryPicSelected] = useState<any>(null);
  const [adjustmentSelected, setAdjustmentSelecter] = useState<any>(null);

  const fetchListErPicPerformances = async () => {
    const params: any = {};
    if (payload?.reset_date) {
      params.reset_date = payload.reset_date;
    }
    if (payload?.worklocation && payload?.worklocation.length > 0) {
      params.worklocation = payload.worklocation;
    }
    let newPayload: any = {};
    for (const key in params) {
      if (Array.isArray(params[key])) {
        if (params[key].length) {
          newPayload[key] = params[key];
        }
      } else if (params[key]) {
        newPayload[key] = params[key];
      }
    }

    let listErPicPerformance = await erPicPerformancesMutation(params).unwrap();
    listErPicPerformance = listErPicPerformance?.er_pic_performances?.map((item: any) => ({
      ...item,
      id: item.user_id || '',
    })) || [];
    setErPicPerformanceList(listErPicPerformance);
    setIsLoadingFilter(false);
  };

  const applyFilter = () => {
    setIsLoadingFilter(true);
    setPayload({
      ...payload,
      reset_date: filter?.reset_date || null,
      worklocation: filter?.worklocation || [],
    });
  };

  const resetFilter = () => {
    setIsLoadingFilter(true);
    setFilter({
      reset_date: null,
      worklocation: [],
    });
    setPayload({
      ...payload,
      reset_date: null,
      worklocation: [],
    });
  };

  const handleExpand = (record: any) => {
    if(record.id){
      const newExpandedRowKeys = expandedRowKeys.includes(record.id)
        ? expandedRowKeys.filter(id => id !== record.id)
        : [...expandedRowKeys, record.id];
      setExpandedRowKeys(newExpandedRowKeys);
    }
  };

  const handleShowModalHistoryAdjustment = (record: any) => {
    setHistoryPicSelected({
      id: record?.user_id || '',
      er_pic_name: record?.pic || '',
    });
    setShowModalHistoryAdjustment(true);
  };

  const handleShowModalAdjustment = (record: any, idx: number) => {
    setAdjustmentSelecter({
      pic_id: record?.user_id || '',
      permit_request_id: record.details?.[idx]?.permit_request_id || '',
      request_number: record.details?.[idx]?.request_number || '',
      permit_name: record.details?.[idx]?.permit_name?.[getLanguage()] || '',
      score: record.details?.[idx]?.score || 0,
    });
    setShowModalAdjustment(true);
  };

  const columns: ColumnsType<any> = [
    isAdmin && {
      title: t('permit_name.obligation.column.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_: any, record: any) => (
        <div className='flex justify-center gap-1 items-center'>
          <Button
            type='text'
            className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0 w-[28px] flex items-center justify-center'
            onClick={() => handleExpand(record)}
          >
            {expandedRowKeys.includes(record.id) ? (
              <FaChevronDown style={{ fontSize: '20px' }} className='text-[#55A853] p-1' />
            ) : (
              <FaChevronRight style={{ fontSize: '20px' }} className='text-[#55A853] p-1' />
            )}
          </Button>
          <Button
            type='text'
            className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0'
            onClick={() => handleShowModalHistoryAdjustment(record)}
          >
            <EyeOutlined style={{ fontSize: '20px' }} className='text-[#55A853] p-1' />
          </Button>
        </div>
      ),
    },
    {
      title: t('report_er_performance.er_pic_name_column'),
      dataIndex: 'pic',
      key: 'pic',
      sorter: true,
      render: (data: any) => {
        return (<div>{data || '-'}</div>);
      }
    },
    {
      title: t('report_er_performance.delay_score_column'),
      dataIndex: 'lateness_score',
      key: 'lateness_score',
      sorter: true,
      width: 180,
      render: (data: any) => {
        return (<div>{data || (data === 0 ? 0 : '-')}</div>);
      }
    },
    {
      title: t('report_er_performance.total_permits_processed_column'),
      dataIndex: 'total_permit_process',
      key: 'total_permit_process',
      sorter: true,
      width: 230,
      render: (data: any) => {
        return (<div>{data || (data === 0 ? 0 : '-')}</div>);
      }
    },
    {
      title: t('report_er_performance.total_late_permits_column'),
      dataIndex: 'total_permit_late',
      key: 'total_permit_late',
      sorter: true,
      width: 190,
      render: (data: any) => {
        return (<div>{data || (data === 0 ? 0 : '-')}</div>);
      }
    },
  ].filter(Boolean) as ColumnsType<any>;

  const handleSubmitResetScore = async () => {
    await postResetScore({}).unwrap()
      .then(() => {
        toast.success(
          <Toast
            message={t('general.success_text')}
            detailedMessage={`${t('report_er_performance.success_message_submit_reset_score')}`}
          />
        );
        modalFilterRef.current?.fetchListResetDate();
        resetFilter();
      })
      .catch((error) => {
        toast.error(
          <Toast
            message={t('general.error_text')}
            detailedMessage={error.message || t('report_er_performance.failed_message_submit_reset_score')}
          />
        );
      });
  };  

  const handleClickButtonResetScore = () => {
    confirm({
      title: t('report_er_performance.reset_score'),
      content: t('report_er_performance.confirm_description'),
      icon: <ExclamationCircleFilled />,
      okText: t('manage_group.yes_btn'),
      okType: 'danger',
      cancelText: t('manage_group.no_btn'),
      async onOk() {
        await handleSubmitResetScore();
      },
      onCancel() { },
    });
  };

  useEffect(() => {
    setIsAdmin(user?.roles?.some((v: any) => listRoleAdmin.includes(v.name)));
  }, []);

  useEffect(() => {
    fetchListErPicPerformances();
  }, [payload]);

  useEffect(() => {
    if (isErrorGetErPicPerformances) {
      const errorMsg: any = errorGetErPicPerformances;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [errorGetErPicPerformances, isErrorGetErPicPerformances]);

  const expandedRowRender = (record: any) => {
    return (
      <div className='px-4'>
        <div className='flex gap-2 font-bold'>
          <span className='w-20'>{t('permit_name.obligation.column.action')}</span>
          <span className='w-40'>#{t('report_er_performance.request')}</span>
          <span className='flex-grow'>{t('report_er_performance.permit_name')}</span>
          <span className='w-20'>{t('report_er_performance.delay_score_column')}</span>
        </div>
        <div className='mt-4 flex flex-col gap-2'>
          {record.details?.map((detail: any, idx: number) => (
            <div key={detail.permit_request_id} className='flex gap-2'>
              <div
                className='cursor-pointer w-20'
                onClick={() => handleShowModalAdjustment(record, idx)}
              >
                <EditFilled style={{ fontSize: '20px' }} className='text-[#55A853] p-1' />
              </div>
              <span className='w-40'>{detail.request_number || '-'}</span>
              <div className='flex-grow'>
                <Link to={`/permit-request/edit/${detail.permit_request_id}?tab=3`} target='_blank' className='text-blue-500 underline'>{detail.permit_name?.[getLanguage()]}</Link>
              </div>
              <span>{detail.score || 0}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='flex justify-between items-center'>
        <div>
          {isAdmin && (
            <Button
              type="text"
              className="bg-[#55A853] text-white font-bold"
              onClick={handleClickButtonResetScore}
              loading={isLoadingResetScore}
            >
              {t('report_er_performance.reset_score')}
            </Button>
          )}
        </div>
        <Button
          type='text'
          className='shadow-md rounded-lg p-0 bg-white ml-5'
          onClick={() => setShowModalFilter(!showModalFilter)}
        >
          <FaFilter style={{fontSize: '30px'}} className='text-[#55A853] p-1' />
        </Button>
      </div>

      <ModalFilter
        ref={modalFilterRef}
        modalAction={{
          isModalOpen: showModalFilter,
          setIsModalOpen: setShowModalFilter
        }}
        filter={{
          filter: filter,
          setFilter: setFilter
        }}
        filterAction={{
          isLoadingFilter: isLoadingFilter,
          resetFilter: resetFilter,
          applyFilter: applyFilter
        }}
      />

      <ModalAdjustment
        modalAction={{
          modalFormOpen: showModalAdjustment,
          setModalFormOpen: setShowModalAdjustment
        }}
        detail={adjustmentSelected}
        callbackSuccess={fetchListErPicPerformances}
      />

      <ModalHistoryAdjustment
        modalAction={{
          modalFormOpen: showModalHistoryAdjustment,
          setModalFormOpen: setShowModalHistoryAdjustment
        }}
        detail={historyPicSelected}
      />

      <SimpleDataTable
        columns={columns}
        dataSource={erPicPerformanceList}
        loading={isLoading}
        expandable={isAdmin && {
          expandedRowRender: expandedRowRender,
          expandedRowKeys,
          onExpand: (_: any, record: any) => handleExpand(record),
          expandIconColumnIndex: -1, // Remove default expand icon column
        }}
      />
    </div>
  );
};

export default ReportErPerformance;