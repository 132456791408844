import { Button, Card, Empty, Form, Input, Select, Spin, Tabs, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { TabsProps, UploadFile } from 'antd';
import { FileOutlined, CloseOutlined, DeleteFilled } from '@ant-design/icons';
import { toast } from 'react-toastify';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { TYPEUPLOAD, MAXFILESIZE } from 'constant';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useListRolePaginationMutation } from 'api/Role';
import { useIssuerInstitutionsListMutation, usePermitTypesListMutation } from 'api/Options';
import { OptionType } from 'constant/Types/OptionType';
import { useDelDocumentTemplateMutation, useDelTemplateAttachmentMutation, useDetailPermitNameMutation, usePostPermitNameMutation, usePostTemplateAttachmentMutation, usePutPermitNameMutation } from 'api/PermitName';
import PermitNameFormTabObligation from './Obligation';
import { PERMIT_TIER_TYPE } from 'constant';

const PermitNameForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const { tab } = useParams();
  const [form] = Form.useForm();
  const { Dragger } = Upload;
  const pathSegments = pathname.split('/');
  const segment = pathSegments[2];

  const [priorityNumber, setPriorityNumber] = useState('');
  const [processEstimation, setProcessEstimation] = useState('');
  const [reminderBefore, setReminderBefore] = useState('');
  const [frequencyReminder, setFrequencyReminder] = useState('');
  const [issuerInstitutions, setIssuerInstitutions] = useState<OptionType[]>([]);
  const [permitTypes, setPermitTypes] = useState<OptionType[]>([]);
  const [roles, setRoles] = useState<OptionType[]>([]);
  const [isView, setIsView] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const [detail, setDetail] = useState({
    role_permit_names: []
  });
  const [newId, setNewId] = useState(id);
  const [activateTab, setActiveTab] = useState(tab === 'obligation' ? '2' : '1');
  const [isPageEdit, setIsPageEdit] = useState<boolean>(segment === 'edit');

  const [postTemplateAttachment] = usePostTemplateAttachmentMutation();
  const [delTemplateAttachment] = useDelTemplateAttachmentMutation();
  const [delDocumentTemplate] = useDelDocumentTemplateMutation();
  const [getDetail, {
    error: errorDetail,
    isError: isErrorDetail,
    isLoading: isLoadingDetail
  }] = useDetailPermitNameMutation();
  const [postPermitName, {
    error: errorPost,
    isError: isErrorPost,
    isLoading: isLoadingPost,
    isSuccess: isSuccessPost
  }] = usePostPermitNameMutation();
  const [putPermitName, {
    error: errorPut,
    isError: isErrorPut,
    isLoading: isLoadingPut,
    isSuccess: isSuccessPut
  }] = usePutPermitNameMutation();
  const [issuerInstitutionList, {
    isLoading: isLoadingIssuerInstitution
  }] = useIssuerInstitutionsListMutation();
  const [permitTypeList, {
    isLoading: isLoadingPermitTypes
  }] = usePermitTypesListMutation();
  const [roleList, {
    isLoading: isLoadingRole
  }] = useListRolePaginationMutation();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('tab.general')
    },
    {
      key: '2',
      label: t('tab.obligation'),
      children: <PermitNameFormTabObligation id={id} />,
    },
  ];

  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
    <div>
      <DefaultTabBar
        {...props}
        className='px-10 rounded-xl text-[#AAAAAA]'
        style={{ background: '#FFF' }}
      />
    </div>
  );

  const onTabClick = (e: any) => {
    if (e === '2' && !isPageEdit) {
      toast.warning(<Toast message={t('general.warning_text')} detailedMessage={t('permit_name.cant_move_to_tab_obligation')} />);
      return;
    }

    setActiveTab(e);
  };

  const fetchIssuerInstitutions = async (value: any) => {
    if (value?.length > 2 || !value) {
      const payload = {
        search: value ?? '',
        searchFields: 'name'
      }; 
      let { rows } = await issuerInstitutionList(payload).unwrap();
      const newDataIssuerInstitution = rows.map((el: any) => {
        if (el) {
          return {
            label: el.name,
            value: el.id
          };
        }
        return null;
      });

      setIssuerInstitutions(newDataIssuerInstitution.filter((v: any) => v));
    }
  };

  const fetchPermitTypes = async (value: any) => {
    if (value?.length > 2 || !value) {
      const payload = {
        search: value ?? '',
        searchFields: 'name'
      }; 
      let { rows } = await permitTypeList(payload).unwrap();
      const newDataPermitType = rows.map((el: any) => {
        if (el) {
          return {
            label: el.name,
            value: el.id
          };
        }
        return null;
      });

      setPermitTypes(newDataPermitType.filter((v: any) => v));
    }
  };

  const fetchRoles = async (value: any) => {
    if (value.length > 2 || !value) {
      const payload = {
        search: value ?? '',
        searchFields: 'name',
        sort: 'name',
        status: 'Active'
      }; 

      let { rows } = await roleList(payload).unwrap();
      const newDataRole = rows.map((el: any, idx: any) => {
        if (el) {
          return {
            label: el?.name,
            value: el?.id,
            key: idx
          };
        }
        return null;
      });

      setRoles(newDataRole.filter((v: any) => v));
    }
  };

  const onFinish = async (values: any) => {
    const reqDocs = form.getFieldValue('dynamicReqAttach')?.map((el: any) => {
      let tempExisting = [];
      if (el?.existing?.length) {
        tempExisting = el?.existing?.map((elE: any) => ({
          id: elE?.id
        }));
      }

      let tempAttachment: object[] = [];
      if (el?.attachment?.length) {
        tempExisting = el?.attachment?.map((elAtt: any) => ({
          id: elAtt?.id
        }));
      }

      let newData:any = {
        id: el?.id ?? null,
        name: el?.doc_name,
        attachments: tempExisting.concat(tempAttachment)
      };

      return newData;
    });

    if (id) {
      const formData = {
        id: id,
        permit_name: {
          permit_name_processed_by: values.permit_name_processed_by.map((v: any) => {
            return {
              processed_by: v
            };
          }),
          permit_issuer_institution_id: values.permit_issuer_institution_id,
          permit_type_id: values.permit_type_id,
          name: values.name,
          name_en: values.name_en,
          required_time: values.required_time,
          priority_number: values.priority_number ? parseFloat(values.priority_number) : null,
          reminder_before: values.reminder_before ? parseFloat(values.reminder_before) : null,
          frequency: values.frequency ? parseFloat(values.frequency) : null,
          role_permit_names: values.role_id.map((v: any) => {
            const tempRoleId: any = detail?.role_permit_names?.find((i: any) => i.role_id === v);
            return {
              id: tempRoleId ? tempRoleId.id : null,
              permit_name_id: id,
              role_id: v
            };
          }),
          required_documents: reqDocs,
          activity: values.activity,
          permit_tier: values.permit_tier,
          guidance_url: values.guidance_url || null
        }
      };
      
      await putPermitName(formData).unwrap();
    } else {
      const formData = {
        permit_name: {
          permit_name_processed_by: values.permit_name_processed_by.map((v: any) => {
            return {
              processed_by: v
            };
          }),
          permit_issuer_institution_id: values.permit_issuer_institution_id,
          permit_type_id: values.permit_type_id,
          name: values.name,
          name_en: values.name_en,
          required_time: values.required_time,
          priority_number: values.priority_number ? parseFloat(values.priority_number) : null,
          reminder_before: values.reminder_before ? parseFloat(values.reminder_before) : null,
          frequency: values.frequency ? parseFloat(values.frequency) : null,
          role_permit_names: values.role_id.map((v: any) => {
            return {
              id: null,
              permit_name_id: null,
              role_id: v
            };
          }),
          required_documents: reqDocs,
          activity: values.activity,
          permit_tier: values.permit_tier,
          guidance_url: values.guidance_url || null
        }
      };
      
      const responsePost = await postPermitName(formData).unwrap();
      setNewId(responsePost.id);
    }
  };

  const fetchDetail = async () => {
    const dataDetail = await getDetail(id).unwrap();

    setDetail(dataDetail);
    let dynamicReqAttachNew = dataDetail?.required_documents?.map((el: any) => {
      return {
        ...el,
        attachment: [],
        doc_name: el?.name,
        existing: el?.attachments,
        id: el?.id,
      };
    });

    form.setFieldsValue({
      ...dataDetail,
      permit_name_processed_by: dataDetail?.permit_name_processed_by.map((v: any) => {
        return v.processed_by;
      }),
      role_id: dataDetail?.role_permit_names?.map((v: any) => v.role_id),
      dynamicReqAttach: dynamicReqAttachNew
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      ...form,
      dynamicReqAttach: []
    });
    fetchPermitTypes('');
    fetchIssuerInstitutions('');
    fetchRoles('');
    if (id) {
      setIsView(pathname.includes('detail'));
    }
  }, []);

  useEffect(() => {
    if (id && isFirst && roles.length) {
      setIsFirst(false);
      fetchDetail();
    }
  }, [roles]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, str: any) => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      switch (str) {
        case 'priority_number':
          setPriorityNumber(inputValue);
          form.setFieldsValue({
            ...form,
            priority_number: inputValue
          });
          break;
        case 'process_estimation':
          setProcessEstimation(inputValue);
          form.setFieldsValue({
            ...form,
            process_estimation: inputValue
          });
          break;
        case 'reminder_before':
          setReminderBefore(inputValue);
          form.setFieldsValue({
            ...form,
            reminder_before: inputValue
          });
          break;
        case 'frequency_reminder':
          setFrequencyReminder(inputValue);
          form.setFieldsValue({
            ...form,
            frequency_reminder: inputValue
          });
          break;
        default:
          break;
      }
    }
  };

  const handleBlur = (str: any) => {
    let valueTemp = '';
    switch (str) {
      case 'priority_number':
        valueTemp = priorityNumber;
        if (priorityNumber.charAt(priorityNumber.length - 1) === '.' || priorityNumber === '-') {
          valueTemp = priorityNumber.slice(0, -1);
        }
        form.setFieldsValue({
          ...form,
          priority_number: valueTemp.replace(/0*(\d+)/, '$1')
        });
        break;
      case 'process_estimation':
        valueTemp = processEstimation;
        if (processEstimation.charAt(processEstimation.length - 1) === '.' || processEstimation === '-') {
          valueTemp = processEstimation.slice(0, -1);
        }
        form.setFieldsValue({
          ...form,
          process_estimation: valueTemp.replace(/0*(\d+)/, '$1')
        });
        break;
      case 'reminder_before':
        valueTemp = reminderBefore;
        if (reminderBefore.charAt(reminderBefore.length - 1) === '.' || reminderBefore === '-') {
          valueTemp = reminderBefore.slice(0, -1);
        }
        form.setFieldsValue({
          ...form,
          reminder_before: valueTemp.replace(/0*(\d+)/, '$1')
        });
        break;
      case 'frequency_reminder':
        valueTemp = frequencyReminder;
        if (frequencyReminder.charAt(frequencyReminder.length - 1) === '.' || frequencyReminder === '-') {
          valueTemp = frequencyReminder.slice(0, -1);
        }
        form.setFieldsValue({
          ...form,
          frequency_reminder: valueTemp.replace(/0*(\d+)/, '$1')
        });
        break;
      default:
        break;
    }
  };

  const addReqAttach = () => {
    const newReqAttach = {
      attachment: [],
      doc_name: ''
    };
    const newDynamicReqAttach = [...form.getFieldsValue()?.dynamicReqAttach];
    form.setFieldsValue({
      ...form,
      dynamicReqAttach: [...newDynamicReqAttach, newReqAttach]
    });
  };

  const delRowReqAttach = async (idx: any) => {
    let newDynamicReqAttach = [...form.getFieldsValue()?.dynamicReqAttach];
    const tempDocs = newDynamicReqAttach[idx];

    if (tempDocs.id) {
      newDynamicReqAttach[idx].isLoading = true;
      const formDelete = {
        id: tempDocs.id,
        attachments: tempDocs.attachments.map((v: any) => {
          return {
            id: v.id
          };
        })
      };
      await delDocumentTemplate(formDelete).unwrap().then((resp: any) => {
        newDynamicReqAttach.splice(idx, 1);
        form.setFieldsValue({
          ...form,
          dynamicReqAttach: newDynamicReqAttach
        });
      }).catch((e:any) => {
        newDynamicReqAttach[idx].isLoading = false;
        const errorMsg:any = e;
        toast.error(
          <Toast 
            message={`${t('permit_request.list_doc.failed_delete_file')} ${newDynamicReqAttach[idx]?.attachment_file}`} 
            detailedMessage={`${transformError(errorMsg?.data).message}`} 
          />);
      });
    } else {
      if (tempDocs?.attachment?.length) {
        await tempDocs?.attachment?.map(async (v: any, i: any) => {
          delReqDocs(idx, i);
        });
      }
      newDynamicReqAttach.splice(idx, 1);
      form.setFieldsValue({
        ...form,
        dynamicReqAttach: newDynamicReqAttach
      });
    }
  };

  const uploadImageReqDocs = async (options: any, idx: any) => {
    const reader = new FileReader();
    const { onSuccess, file } = options;
    let fileUpload;
    let newDynamicReqDocs = [...form.getFieldsValue()?.dynamicReqAttach];
    const idxC = newDynamicReqDocs[idx].attachment.length;
    // step 1: uploading, step 3: success
    newDynamicReqDocs[idx].attachment[idxC] = {file: file, step: 1, id: null, isLoading: true};
    form.setFieldsValue({
      ...form,
      dynamicReqAttach: newDynamicReqDocs
    });
    setTimeout(() => {
      reader.onload = async (e) => {
        fileUpload = e?.target?.result || null;
        const payload = {
          body: {
            required_document_template_attachment: {
              attachment_file: fileUpload,
              filename: file.name
            }
          }
        };

        await postTemplateAttachment(payload).unwrap().then((response: any) => {
          let newDynamicReqDocsSuccess = [...form.getFieldsValue()?.dynamicReqAttach];
          newDynamicReqDocsSuccess[idx].attachment[idxC].step = 3;
          newDynamicReqDocsSuccess[idx].attachment[idxC].id = response?.id;
          newDynamicReqDocsSuccess[idx].attachment[idxC].isLoading = false;
          form.setFieldsValue({
            ...form,
            dynamicReqAttach: newDynamicReqDocsSuccess
          });
          onSuccess('Ok');
        }).catch((e: any) => {
          const errorMsg:any = e;
          toast.error(
            <Toast 
              message={`${t('permit_request.list_doc.failed_upload')} ${file?.name}`} 
              detailedMessage={`${transformError(errorMsg?.data).message}`} 
            />);

          let newDynamicReqDocsError = [...form.getFieldsValue()?.dynamicReqAttach];
          newDynamicReqDocsError[idx].attachment?.splice(idxC, 1);
          newDynamicReqDocsError[idx].attachmentFileData?.fileList.splice(idxC, 1);
          if (!newDynamicReqDocsError[idx].attachmentFileData?.fileList.length) {
            newDynamicReqDocsError[idx].attachmentFileData = [];
          }
          form.setFieldsValue({
            ...form,
            dynamicReqAttach: newDynamicReqDocsError
          });
        });
      };
      reader.readAsDataURL(file);
    }, 100);
  };

  const delReqDocs = async (idx: any, idxC: any) => {
    let newDynamicReqDocs = [...form.getFieldsValue()?.dynamicReqAttach];
    const idDocs = newDynamicReqDocs[idx].attachment[idxC].id;
    if (idDocs) {
      newDynamicReqDocs[idx].attachment[idxC].isLoading = true;
      await delTemplateAttachment(idDocs).unwrap().then((resp: any) => {
        newDynamicReqDocs[idx].attachment?.splice(idxC, 1);
        form.setFieldsValue({
          ...form,
          dynamicReqAttach: newDynamicReqDocs
        });
      }).catch((e:any) => {
        newDynamicReqDocs[idx].attachment[idxC].isLoading = false;
        const errorMsg:any = e;
        toast.error(
          <Toast 
            message={`${t('permit_request.list_doc.failed_delete_file')} ${newDynamicReqDocs[idx].attachment[idxC]?.file?.name}`} 
            detailedMessage={`${transformError(errorMsg?.data).message}`} 
          />);
      });
    } else {
      newDynamicReqDocs[idx].attachment?.splice(idxC, 1);
      form.setFieldsValue({
        ...form,
        dynamicReqAttach: newDynamicReqDocs
      });
    }
  };

  const delReqDocsExisting = async (idx: any, idxC: any) => {
    let newDynamicReqDocs = [...form.getFieldsValue()?.dynamicReqAttach];
    const idDocs = newDynamicReqDocs[idx].existing[idxC].id;
    if (idDocs) {
      newDynamicReqDocs[idx].existing[idxC].isLoading = true;
      await delTemplateAttachment(idDocs).unwrap().then((resp: any) => {
        newDynamicReqDocs[idx].existing?.splice(idxC, 1);
        form.setFieldsValue({
          ...form,
          dynamicReqAttach: newDynamicReqDocs
        });
      }).catch((e:any) => {
        newDynamicReqDocs[idx].existing[idxC].isLoading = false;
        const errorMsg:any = e;
        toast.error(
          <Toast 
            message={`${t('permit_request.list_doc.failed_delete_file')} ${newDynamicReqDocs[idx].existing[idxC]?.file?.name}`} 
            detailedMessage={`${transformError(errorMsg?.data).message}`} 
          />);
      });
    } else {
      newDynamicReqDocs[idx].existing?.splice(idxC, 1);
      form.setFieldsValue({
        ...form,
        dynamicReqAttach: newDynamicReqDocs
      });
    }
  };

  const beforeUploadFile = (file: UploadFile) => {
    const { type, name, size } = file;
    let specifiecType = type?.substring((type?.lastIndexOf('/') || 0) + 1);
    if (specifiecType === '' || specifiecType === 'x-zip-compressed' || specifiecType?.includes('officedocument')){
      specifiecType = name.split('.').pop();
    }
    const isAllowedType = TYPEUPLOAD.includes(specifiecType || '');
    if (!isAllowedType) {
      toast.error(
        <Toast 
          message={t('general.error_text')} 
          detailedMessage={`${t('permit_request.list_doc.upload_failed_type_one') + name + t('permit_request.list_doc.upload_failed_type_two')} (${TYPEUPLOAD})`} 
        />);
      return false;
    }

    if ((size || 0) > MAXFILESIZE.VAL_ON_BYTE) {
      toast.error(
        <Toast 
          message={t('general.error_text')} 
          detailedMessage={`
            ${t('permit_request.list_doc.upload_failed_type_one') +
             name + t('permit_request.list_doc.upload_failed_type_three') + 
             MAXFILESIZE.VAL_CONVERT_BYTE_TO_CURRENT + 
             MAXFILESIZE.CURRENT_MEMORY}`
          } 
        />);
      return false;
    }
    
    return true;
  };

  const getBorderStep = (data: any) => {
    switch(data) {
      case 1:
        return 'border-zinc-400';
      case 2:
        return 'border-red-400';
      case 3:
        return 'border-green-400';
    }
  };

  const getColorStep = (data: any) => {
    switch(data) {
      case 1:
        return '#bbbbbb';
      case 2:
        return '#ED3F3F';
      case 3:
        return '#55A853';
    }
  };

  const downloadFile = (file: any) => {
    let csvURL = window.URL.createObjectURL(file);
    let tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `${file?.name}`);
    tempLink.click();
  };

  const downloadFileUrl = (url: any) => {
    window.open(url);
  };

  useEffect(() => {
    if (isSuccessPost && newId) {
      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('permit_name.success_message_submit')}`}
        />
      );
      navigate(`/setting-permit-name/edit/${newId}/obligation`);
    }
    if (isSuccessPut) {
      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('permit_name.success_message_update')}`}
        />
      );
      navigate('/setting-permit-name');
    }
  }, [isSuccessPost, isSuccessPut, newId]);

  useEffect(() => {
    if (isErrorPost) {
      const errorMsg:any = errorPost;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
    if (isErrorPut) {
      const errorMsg:any = errorPut;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isErrorPost, isErrorPut]);

  useEffect(() => {
    if (isErrorDetail) {
      const errorMsg:any = errorDetail;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isErrorDetail]);

  return(
    <Spin tip="Loading" size="large" spinning={isLoadingDetail}>
      <Tabs
        activeKey={activateTab}
        renderTabBar={renderTabBar}
        items={items}
        onTabClick={onTabClick}
        onChange={() => {}}
      />
      {activateTab === '1' && (
        <Form
          className='h-[100px]'
          layout="vertical"
          autoComplete="off"
          form={form}
          requiredMark={false}
          onFinish={onFinish}
        >
          <Card className='bg-[#FBFBFB] rounded-xl'>
            <span className='col-span-2 font-bold text-base'>{t('permit_name.permit_info')}</span>
            <div className='grid grid-cols-2 gap-5 mt-5'>
              <div className='md:col-span-1 max-md:col-span-2'>
                <Form.Item
                  name='activity'
                  label={t('permit_request.request_information.activity')}
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder={t('permit_request.request_information.activity_placeholder') || ''}
                    disabled={isView}
                  />
                </Form.Item>
              </div>
              <div className='md:col-span-1 max-md:col-span-2'>
                <Form.Item
                  name='name'
                  label={t('permit_name.label_permit_name_id')}
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder={t('permit_name.placeholder_permit_name_id') || ''}
                    disabled={isView}
                  />
                </Form.Item>
              </div>
              <div className='md:col-span-1 max-md:col-span-2'>
                <Form.Item
                  name='permit_type_id'
                  label={t('permit_request.request_information.permit_type')}
                  rules={[{ required: true }]}
                >
                  <Select
                    allowClear
                    showSearch
                    onClear={() => {
                      fetchPermitTypes('');
                    }}
                    placeholder={t('permit_request.request_information.permit_type_placeholder')}
                    options={permitTypes}
                    filterOption={false}
                    onSearch={fetchPermitTypes}
                    notFoundContent={isLoadingPermitTypes ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    disabled={isView}
                  />
                </Form.Item>
              </div>
              <div className='md:col-span-1 max-md:col-span-2'>
                <Form.Item
                  name='name_en'
                  label={t('permit_name.label_permit_name_en')}
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder={t('permit_name.placeholder_permit_name_en') || ''}
                    disabled={isView}
                  />
                </Form.Item>
              </div>
              <div className='md:col-span-1 max-md:col-span-2'>
                <Form.Item
                  name='permit_tier'
                  label={t('permit_request.request_information.permit_tier')}
                  rules={[{ required: true }]}
                >
                  <Select
                    allowClear
                    placeholder={t('permit_request.request_information.permit_tier_placeholder')}
                    options={PERMIT_TIER_TYPE}
                    filterOption={false}
                    notFoundContent={isLoadingPermitTypes ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    disabled={isView}
                  />
                </Form.Item>
              </div>
              <div className='md:col-span-1 max-md:col-span-2'>
                <Form.Item
                  name='required_time'
                  label={t('permit_name.process_estimation')}
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder={t('permit_name.process_estimation_placeholder') || ''} 
                    suffix={
                      <span
                        className='ml-3 px-2 bg-[#54a853]/10 rounded-full text-[#54a853] f border border-solid border-[#55A853] text-xs'
                      >
                        {t('general.week_text')}
                      </span>
                    }
                    onChange={(e) => handleChange(e, 'process_estimation')}
                    onBlur={() => handleBlur('process_estimation')}
                    value={processEstimation}
                    disabled={isView}
                  />
                </Form.Item>
              </div>
              <div className='md:col-span-1 max-md:col-span-2'>
                <Form.Item
                  name='permit_issuer_institution_id'
                  label={t('permit_name.institution')}
                  rules={[{ required: true }]}
                >
                  <Select
                    allowClear
                    showSearch
                    onClear={() => {
                      fetchIssuerInstitutions('');
                    }}
                    placeholder={t('permit_name.institution_placeholder')}
                    options={issuerInstitutions}
                    filterOption={false}
                    onSearch={fetchIssuerInstitutions}
                    notFoundContent={isLoadingIssuerInstitution ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    disabled={isView}
                  />
                </Form.Item>
              </div>
              <div className='md:col-span-1 max-md:col-span-2'>
                <Form.Item
                  name='permit_name_processed_by'
                  label={t('permit_monitoring.processed_by')}
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    mode='multiple'
                    placeholder={t('permit_monitoring.processed_by_placeholder')}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={[
                      {
                        value: 'ER Jakarta',
                        label: 'ER Jakarta',
                      },
                      {
                        value: 'Mine Site',
                        label: 'Mine Site',
                      }
                    ]}
                    disabled={isView}
                  />
                </Form.Item>
              </div>
              <div className='md:col-span-1 max-md:col-span-2'>
                <Form.Item
                  name='guidance_url'
                  label={t('permit_request.request_information.guidance_url')}
                  rules={[{ type: 'url' }]}
                >
                  <Input
                    placeholder={t('permit_request.request_information.guidance_url_placeholder') || ''}
                    disabled={isView}
                  />
                </Form.Item>
              </div>

              <hr className='col-span-2' style={{height:'10px', borderWidth:'0', color:'gray', backgroundColor:'#E5EDE5'}} />
              <span className='col-span-2 font-bold text-base'>{t('permit_name.reminder_management')}</span>
              <div className='md:col-span-1 max-md:col-span-2'>
                <Form.Item
                  name='reminder_before'
                  label={t('permit_name.reminder_before')}
                >
                  <Input
                    placeholder={t('permit_name.reminder_before_placeholder') || ''} 
                    suffix={
                      <span
                        className='ml-3 px-2 bg-[#54a853]/10 rounded-full text-[#54a853] f border border-solid border-[#55A853] text-xs'
                      >
                        {t('general.day_text')}
                      </span>
                    }
                    onChange={(e) => handleChange(e, 'reminder_before')}
                    onBlur={() => handleBlur('reminder_before')}
                    value={reminderBefore}
                    disabled={isView}
                  />
                </Form.Item>
              </div>
              <div className='md:col-span-1 max-md:col-span-2'>
                <Form.Item
                  name='frequency'
                  label={t('permit_name.frequency_reminder')}
                >
                  <Input
                    placeholder={t('permit_name.frequency_reminder_placeholder') || ''} 
                    suffix={
                      <span
                        className='ml-3 px-2 bg-[#54a853]/10 rounded-full text-[#54a853] f border border-solid border-[#55A853] text-xs'
                      >
                        {t('general.day_text')}
                      </span>
                    }
                    onChange={(e) => handleChange(e, 'frequency_reminder')}
                    onBlur={() => handleBlur('frequency_reminder')}
                    value={frequencyReminder}
                    disabled={isView}
                  />
                </Form.Item>
              </div>
              <div className='col-span-2'>
                <Form.Item
                  name='role_id'
                  label={t('permit_name.selected_role_for_reminder')}
                  rules={[{ required: true }]}
                >
                  <Select
                    mode='multiple'
                    allowClear
                    showSearch
                    onClear={() => {
                      fetchRoles('');
                    }}
                    placeholder={t('permit_name.select_role_placeholder')}
                    options={roles}
                    filterOption={false}
                    onSearch={fetchRoles}
                    notFoundContent={isLoadingRole ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    disabled={isView}
                  />
                </Form.Item>
              </div>
              <hr className='col-span-2' style={{height:'10px', borderWidth:'0', color:'gray', backgroundColor:'#E5EDE5'}} />
              <span className='col-span-2 font-bold text-base'>{t('permit_name.list_of_required_document')}</span>
              {
                !isView &&
              <div className='col-span-2'>
                <Button
                  className='w-full md:w-auto submit bg-[#55A853] text-white md:col-span-4 xl:col-span-3 2xl:col-span-2'
                  onClick={addReqAttach}
                >
                  {t('permit_name.add_new_doc_btn')}
                </Button>
              </div>
              }
              <Form.List name="dynamicReqAttach">
                {
                  (fields) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <div key={key} className='col-span-2 bg-white md:bg-transparent rounded-lg overflow-hidden border-2 md:border-none'>
                          <div className='flex md:hidden justify-between items-center px-4 py-2 bg-[#DDEEDD]'>
                            <span className='font-bold'>{`Document ${index + 1}`}</span>
                            {
                              form.getFieldsValue()?.dynamicReqAttach?.[key]?.isLoading ? (<Spin></Spin>) : (
                                !isView &&
                                  <DeleteFilled 
                                    className='text-[25px] self-end pb-[5px] text-[#ED3F3F] cursor-pointer'
                                    onClick={() => isView ? '' : delRowReqAttach(key)}
                                  />
                              )
                            }
                          </div>
                          <div className='grid md:grid-cols-2 gap-5 px-4 py-2'>
                            <div className='grid grid-cols-12'>
                              <div className='hidden md:block col-span-2 pt-[30px]'>
                                <div className='flex'>
                                  {
                                    form.getFieldsValue()?.dynamicReqAttach?.[key]?.isLoading ? (<Spin></Spin>) : (
                                      !isView &&
                                  <DeleteFilled 
                                    className='text-[25px] self-end pb-[5px] text-[#ED3F3F] cursor-pointer'
                                    onClick={() => isView ? '' : delRowReqAttach(key)}
                                  />
                                    )
                                  }
                                  <p className='text-[20px] text-[#55A853] font-bold px-4'>{key + 1}.</p>
                                </div>
                              </div>
                              <div className='col-span-12 md:col-span-10'>
                                <Form.Item
                                  {...restField}
                                  key={key}
                                  name={[name, 'doc_name']}
                                  rules={[{ required: true, message:`${t('permit_request.list_doc.remark_label')} ${t('general.required_field')}` }]}
                                  label={`${t('permit_name.name_of_document')} ${key + 1}`}
                                  className='m-0 font-bold'
                                >
                                  <Input 
                                    className='font-normal m-0'
                                    placeholder={t('permit_name.name_of_document_placeholder') || ''}
                                    disabled={isView}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div>
                              <Form.Item
                                {...restField}
                                key={key}
                                name={[name, 'attachmentFileData']}
                                label={t('permit_name.upload_template')}
                                className='m-0 font-bold'
                              >
                                <Dragger
                                  multiple
                                  className='font-normal'
                                  customRequest={(opt) => uploadImageReqDocs(opt, key)}
                                  showUploadList={false}
                                  beforeUpload={beforeUploadFile}
                                  fileList={form.getFieldsValue()?.dynamicReqAttach?.[key]?.attachmentFileData?.fileList}
                                  disabled={isView}
                                >
                                  <span>{t('permit_request.list_doc.attach_two_placeholder')} 
                                    <span className='font-bold text-[#55A853] underline underline-offset-2'>
                                      {t('permit_request.list_doc.browser_txt')}
                                    </span>
                                  </span>
                                </Dragger>
                              </Form.Item>
                              <div className='mt-5 grid grid-cols-3 md:grid-cols-5 xl:grid-cols-7 gap-5'>
                                {
                                  form.getFieldsValue()?.dynamicReqAttach?.[key]?.existing?.map((dataC: any, idxC: any) => (
                                    <div key={idxC}>
                                      <div className={`border ${getBorderStep(3)} rounded-md p-2`}>
                                        <div className='flex justify-end'>
                                          {
                                            dataC?.isLoading ? (<Spin></Spin>) : (
                                              !isView &&
                                          <CloseOutlined 
                                            className={`text-[#ED3F3F] text-bold ${false ? 'cursor-disabled' : 'cursor-pointer'}`}
                                            onClick={() => delReqDocsExisting(key, idxC)}
                                          />
                                            )
                                          }
                                        </div>
                                        <div className='cursor-pointer'>
                                          <div key={idxC} className='w-full flex justify-center'>
                                            <FileOutlined className={`text-[30px] text-[${getColorStep(3)}]`} onClick={() => downloadFileUrl(dataC?.attachment?.downloadUrl)} />
                                          </div>
                                          <p className='text-center mt-3 text-[10px] text-[#8B849B] truncate'>
                                            {dataC?.attachment_file}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                }
                                {
                                  form.getFieldsValue()?.dynamicReqAttach?.[key]?.attachment?.map((dataC: any, idxC: any) => (
                                    <div key={idxC}>
                                      <div className={`border ${getBorderStep(dataC?.step)} rounded-md p-2`}>
                                        <div className='flex justify-end'>
                                          {
                                            dataC?.isLoading ? (<Spin></Spin>) : (
                                              <CloseOutlined 
                                                className={`text-[#ED3F3F] text-bold ${false ? 'cursor-disabled' : 'cursor-pointer'}`} 
                                                onClick={() => delReqDocs(key, idxC)}
                                              />
                                            )
                                          }
                                        </div>
                                        <div className='cursor-pointer'>
                                          <div key={idxC} className='w-full flex justify-center'>
                                            <FileOutlined className={`text-[30px] text-[${getColorStep(dataC?.step)}]`} onClick={() => downloadFile(dataC?.file)} />
                                          </div>
                                          <p className='text-center mt-3 text-[10px] text-[#8B849B] truncate'>
                                            {dataC?.step === 1 ? t('permit_request.list_doc.uploading') : dataC?.step === 2 ? t('permit_request.list_doc.failed') : dataC?.file.name}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )
                }
              </Form.List>
            </div>
          </Card>
          <div className='flex flex-row justify-between mt-5 max-md:pb-44 md:pb-5'>
            <Button
              className='cancel-green bg-[#E5EDE5] text-[#55A853] border-[#55A853]'
              htmlType="button"
              onClick={() => {navigate(-1);}}
              disabled={isLoadingPost || isLoadingPut}
            >
              {t('general.cancel_btn')}
            </Button>
            {
              !isView &&
            <Button
              className='submit bg-[#55A853] text-white md:col-span-4 xl:col-span-3 2xl:col-span-2'
              htmlType="submit"
              disabled={isLoadingPost || isLoadingPut}
            >
              {isLoadingPost || isLoadingPut ? <Spin /> : t('permit_name.save_permit_name_btn') || ''}
            </Button>
            }
          </div>
        </Form>
      )}
    </Spin>
  );
};

export default PermitNameForm;