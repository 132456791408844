import { Button, Modal, Form, Col, Row, Input, Select, Spin, Empty } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { toast } from 'react-toastify';

import { getIsDifferentData } from 'utils/Utility';
import { useGetListWorkLocNoClusterMutation } from 'api/WorkLocation';
import { DropdownTypeItems } from 'constant/Types/DropdownType';
import Toast from 'components/Toast';

const ModalForm = (props:any) => {
  const { 
    id,
    form,
    modalAction, 
    handleSubmit,
    loading 
  } = props;

  const paramCheckDifferentData = ['name', 'work_locations'];
  const { t } = useTranslation();
  const { confirm } = Modal;
  const [workLocationOpt, setWorkLocationOpt] = useState<DropdownTypeItems>([]);
  const [firstData, setFirstData] = useState({
    id: '',
    name: '',
    work_locations: []
  });

  const [getWorkLocNoCluster, {
    isLoading: isLoadingGetWorkLocNoCluster
  }] = useGetListWorkLocNoClusterMutation();

  const handleCancel = () => {
    if (!getIsDifferentData(form.getFieldsValue(), firstData, paramCheckDifferentData)) {
      modalAction.setShowModal(false);
      return;
    }

    confirm({
      title: !id ? t('clusters.modal_form.confirm_cancel_modal_add') : t('clusters.modal_form.confirm_cancel_modal_edit'),
      icon: <ExclamationCircleFilled />,
      okText: t('manage_group.yes_btn'),
      okType: 'danger',
      cancelText: t('manage_group.no_btn'),
      onOk() {
        modalAction.setShowModal(false);
      },
    });
  };

  const fetchWorkLocations = async () => {
    setWorkLocationOpt([]);
    let params={};
    if (id) {
      params = {
        cluster_id: id
      };
    }

    let data = await getWorkLocNoCluster(params).unwrap();
    const newDataWorklocation = data.map((el: any) => {
      if (el) {
        return {
          label: el?.name,
          value: el?.id
        };
      }
      return null;
    });
    setWorkLocationOpt([...newDataWorklocation]);
  };

  const handleFinishFailed = () => {
    toast.error(<Toast message={t('general.error_text')} detailedMessage={t('manage_group.fill_form_validation_text')} />);
  };

  useEffect(() => {
    setFirstData(form.getFieldsValue());

    if(!modalAction?.showModal){
      form.resetFields();
    }

    if (modalAction?.showModal){
      fetchWorkLocations();
    }
  }, [modalAction?.showModal]);

  return(
    <Modal
      title={null}
      open={modalAction.showModal}
      footer={null}
      width={500}
      onOk={handleSubmit}
      onCancel={handleCancel}
    >
      <p className='font-bold text-[16px] mb-5'>
        {!id ? t('clusters.modal_form.title_add') :t('clusters.modal_form.title_edit')}
      </p>
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        requiredMark={false}
        onFinish={handleSubmit}
        onFinishFailed={handleFinishFailed}
        name='form'
      >
        <Form.Item
          name='name'
          label={t('clusters.modal_form.label_cluster_name')}
          rules={[{ required: true, message: `${t('clusters.modal_form.label_cluster_name')} ${t('general.required_field')}` }]}
        >
          <Input placeholder={t('clusters.modal_form.placeholder_cluster_name') || ''} />
        </Form.Item>
        <Form.Item
          name='work_locations'
          label={t('clusters.modal_form.label_work_location')}
          rules={[{ required: true, message: `${t('clusters.modal_form.label_work_location')} ${t('general.required_field')}` }]}
          className='mt-5'
        >
          <Select
            mode='multiple'
            showSearch
            placeholder={t('clusters.modal_form.placeholder_work_location')}
            options={workLocationOpt}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            notFoundContent={isLoadingGetWorkLocNoCluster ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          />
        </Form.Item>
        <Form.Item className='mt-5'>
          <Row className='h-full'>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button
                className='cancel-green bg-[#E5EDE5] text-[#55A853] border-[#55A853] mr-2'
                htmlType="button"
                onClick={handleCancel}
                disabled={loading.isLoadingSubmit}
              >
                {t('general.cancel_btn')}
              </Button>
              <Button
                className='btn-draft bg-[#F7B648] text-white'
                htmlType="submit"
                disabled={loading.isLoadingSubmit}
              >
                {t('general.save_btn')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalForm;