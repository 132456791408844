import { Button, ConfigProvider, Empty, Form, Rate, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useListFeedbackQuestionMutation } from 'api/FeedbackQuestion';
import Toast from 'components/Toast';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import { FeedbackForm } from 'constant/Types/Feedback';
import { usePostFeedbackMutation } from 'api/Feedback';

const ApplicationFeedback = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [listQuestion, setListQuestion] = useState([]);

  const [getListFeedbackQuestion, {
    isLoading: isLoadingFeedbackList,
    isError: isErrorLoadingFeedbackList,
    error: errorLoadingFeedbackList
  }] = useListFeedbackQuestionMutation();

  const [postFeedback, {
    error: errorPostFeedback,
    isError: isErrorPostFeedback,
    isSuccess: isSuccessPostFeedback,
    isLoading: isLoadingPostFeedback
  }] = usePostFeedbackMutation();

  const fetchListFeedbackQuestion = async () => {
    const payload = {
      feedback_type: 'feedback application'
    };
    await getListFeedbackQuestion(payload).unwrap().then((resp: any) => {
      const { questions } = resp;
      if (questions.length) {
        const newMappingQuestion = questions.map((el:any) => ({
          question_id: el.id,
          category_id: el.category_id,
          question: el.question, 
          rate: null
        }));

        setListQuestion(newMappingQuestion);
      }
    });
  };

  const handleSubmit = async (dataFeedback: any) => {
    const payload:FeedbackForm = {
      feedback: {
        permit_request_id: null,
        feedback_type: 'feedback application',
        comment: dataFeedback?.comment,
        rates: dataFeedback?.feedbacks.map((el:any) => ({
          question_id: el?.question_id,
          category_id: el?.category_id,
          rate: el?.rate
        }))
      }
    };
    
    await postFeedback(payload).unwrap();
  };

  const handleFailed = () => {
    toast.error(<Toast message={t('general.error_text')} detailedMessage={t('permit_request.modal_reminder_feedback.rating_required_label')} />);
  };

  useEffect(() => {
    fetchListFeedbackQuestion();
  }, []);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      ...form.getFieldsValue(),
      feedbacks: listQuestion
    });
  }, [listQuestion]);

  useEffect(() => {
    if (isErrorLoadingFeedbackList) {
      const errorMsg: any = errorLoadingFeedbackList;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isErrorLoadingFeedbackList]);

  useEffect(() => {
    if (isErrorPostFeedback) {
      const errorMsg: any = errorPostFeedback;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isErrorPostFeedback]);

  useEffect(() => {
    if (isSuccessPostFeedback) {
      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('general.success_text')}`}
        />
      );

      form.resetFields();
      form.setFieldsValue({
        ...form.getFieldsValue(),
        feedbacks: listQuestion
      });
    }
  }, [isSuccessPostFeedback]);

  return(
    <div className='bg-white rounded-lg px-5 py-3'>
      <p className='font-bold'>{t('application_feedback.title')}</p>
      {
        !listQuestion.length ? (<Empty description={t('application_feedback.no_question')} className='my-5'/>) :       
          isLoadingFeedbackList ? (
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: '#55A853',
                },
              }}
            >
              <Spin className="flex justify-center" size="large" />
            </ConfigProvider>
          ) : (
            <Form
              className="mt-2"
              layout="vertical"
              onFinish={handleSubmit}
              onFinishFailed={handleFailed}
              requiredMark={false}
              form={form}
            >
              <div className='max-h-[400px] overflow-auto border border-[#AFF0AF] rounded-md mt-4 pt-5'>
                <Form.List name="feedbacks">
                  {(fields) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div key={key} className="text-center md:text-left md:flex md:justify-between mb-[20px] px-2">
                          <div className="my-auto">
                            <p>
                              {form.getFieldValue(['feedbacks', name, 'question'])}
                            </p>
                          </div>
                          <Form.Item
                            {...restField}
                            key={key}
                            name={[name, 'rate']}
                            rules={[
                              {
                                required: true,
                                message: `${t('general.feedback_label')} ${t(
                                  'general.required_field'
                                )}`,
                              },
                            ]}
                          >
                            <Rate allowClear={false} />
                          </Form.Item>
                        </div>
                      ))}
                    </>
                  )}
                </Form.List>
              </div>
              <div>
                <Form.Item
                  label={t('general.comment_label')}
                  name="comment"
                  initialValue={''}
                  className="font-bold mt-3"
                >
                  <TextArea
                    className="font-normal"
                    style={{ height: 100, resize: 'none' }}
                    placeholder={t('general.comment_btn') || ''}
                  />
                </Form.Item>
              </div>
              <div className="text-right mt-5">
                <Button
                  className={'bg-[#55A853] text-white'}
                  htmlType="submit"
                  disabled={
                    isLoadingFeedbackList || isLoadingPostFeedback || !listQuestion.length
                  }
                >
                  {isLoadingFeedbackList || isLoadingPostFeedback ? (
                    <Spin />
                  ) : (
                    t('general.submit_btn') || ''
                  )}
                </Button>
              </div>
            </Form>
          )       
      }
    </div>
  );
};

export default ApplicationFeedback;