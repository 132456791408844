import { Button, Form, Modal, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useStatusPermitListWfeMutation } from 'api/Options';
import { OptionType } from 'constant/Types/OptionType';

const ModalFilter = (props: any) => {
  const { modalAction, filter, filterAction } = props;
  const { t } = useTranslation();
  const [statuses, setStatuses] = useState<OptionType[]>([]);
  const [statusList] = useStatusPermitListWfeMutation();

  const fetchStatus = async (value: any) => {
    if (value.length > 2 || !value) {
      const payload = {
        search: value ?? '',
        searchFields: 'name',
        pageSize: 100
      };
      let { rows } = await statusList(payload).unwrap();
      const filterData = rows.reduce((acc: any, char: any) => acc.includes(char) ? acc : [...acc, char], []);
      let newDataStatus = [{
        label: 'Not Process',
        value: 'Not Process'
      }];
      
      newDataStatus = newDataStatus.concat(filterData.map((el: any) => {
        if (el) {
          return {
            label: el?.status_to,
            value: el?.status_from
          };
        }
        return null;
      }));
      setStatuses(newDataStatus.filter((v: any) => v.label));
    }
  };

  useEffect(() => {
    fetchStatus('');
  }, []);

  return (
    <Modal
      title={null}
      open={modalAction.isModalOpen}
      footer={null}
      width={500}
      onOk={() => modalAction.setIsModalOpen(false)}
      onCancel={() => modalAction.setIsModalOpen(false)}
    >
      <div className="bg-white p-[10px] rounded-lg">
        <p className='font-bold text-[16px] mb-5'>
          {t('manage_group.filter_label')}
        </p>
        <Form layout="vertical">
          <div className='grid grid-cols-1 gap-1'>    
            <div className='col-span-1 mb-3'>
              <Form.Item
                label='Status'
                className='font-bold'
              >
                <Select
                  mode="multiple"
                  allowClear
                  className='font-normal'
                  style={{ width: '100%' }}
                  options={statuses}
                  placeholder={t('manage_group.status_placeholder')}
                  onChange={(e) => filter.setFilter({ ...filter.filter, status: e })}
                  value={filter.filter.status}
                />
              </Form.Item>
            </div>
            <div className='grid grid-cols-2 gap-4 content-end mt-4'>
              <Button
                type='text'
                className='bg-white border-[#55A853] text-[#55A853] font-bold'
                onClick={filterAction.resetFilter}
                disabled={filterAction.isLoadingFilter}
              >
                {filterAction.isLoadingFilter ? <Spin></Spin> : t('manage_group.reset_btn') || ''}
              </Button>
              <Button
                type='text'
                className='bg-[#55A853] text-white font-bold'
                onClick={() => {
                  filterAction.applyFilter();
                  modalAction.setIsModalOpen(false);
                }}
                disabled={filterAction.isLoadingFilter}
              >
                {filterAction.isLoadingFilter ? <Spin></Spin> : t('manage_group.apply_btn') || ''}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalFilter;