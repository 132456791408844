import { FeedbackForm } from 'constant/Types/Feedback';
import { Base } from './Base';

const Feedback = Base.injectEndpoints({
  endpoints: (build) => ({
    getFeedback: build.mutation({
      query: (params: Object) => ({
        url: '/feedbacks',
        method: 'GET',
        params
      })
    }),
    postFeedback: build.mutation({
      query: (body: FeedbackForm) => ({
        url: '/feedbacks',
        method: 'POST',
        body
      })
    }),
    getSummaryFeedback: build.mutation({
      query: (params: Object) => ({
        url: '/feedbacks/summary',
        method: 'GET',
        params
      })
    }),
  })
});

export const { 
  usePostFeedbackMutation,
  useGetSummaryFeedbackMutation,
  useGetFeedbackMutation
} = Feedback;