import { configureStore } from '@reduxjs/toolkit';

import { Base } from 'api/Base';
import authReducer from 'store/Auth';
import languageReducer from 'store/Language';
import notificationReducer from 'store/Notification';
import permitRequestReducer from 'store/PermitRequest';

export const store = configureStore({
  reducer: {
    [Base.reducerPath]: Base.reducer,
    auth: authReducer,
    lang: languageReducer,
    notif: notificationReducer,
    permit_request: permitRequestReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(Base.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
export type RootState = ReturnType<typeof store.getState>;
