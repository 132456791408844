import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Form, MenuProps, Modal, Spin } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import DataTable from 'components/DataTable';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { EyeOutlined, FolderFilled, EditFilled, ExclamationCircleFilled, DownOutlined, UserAddOutlined } from '@ant-design/icons';
import { ButtonDataTableTypeItems } from 'constant/Types/DataTableConfigType';
import { useLocation, useNavigate } from 'react-router';
import ModalFilter from './ModalFilter';
import { useListPermitRequestPaginationMutation, usePutPermitReqReferencePermitMutation } from 'api/PermitRequest';
import { ListPayloadType, PermitRequestListType } from 'constant/Types/PermitRequest';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { hasPermission } from 'utils/Permission';
import moment from 'moment-timezone';
import ModalHistory from './ModalHistory';
import { useAppSelector } from 'store/Hooks';
import ModalEditReference from './ModalEditReference';
import dayjs from 'dayjs';
import CardList from 'components/CardList';
import { PermitRequestDetail } from 'constant/Types/PermitRequestType';
import { isEmpty } from 'lodash';
import ModalAddEmail from 'components/ModalAddEmail';

const PermitRequest = () => {
  const [permitRequestList, {
    error: errorPermitRequestList,
    isError: isErrorPermitRequestList,
    isLoading
  }] = useListPermitRequestPaginationMutation();

  const [putPermitReqReferencePermit, {
    error: errorPutPermitReqReferencePermit,
    isError: isErrorPutPermitReqReferencePermit,
    isLoading: isLoadingPutPermitReqReferencePermit,
    isSuccess: isSuccessPutPermitReqReferencePermit
  }] = usePutPermitReqReferencePermitMutation();

  const { user } = useAppSelector((state) => state.auth);
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const navigate = useNavigate();
  const [formEditRef] = Form.useForm();
  const [isAdmin, setIsAdmin] = useState(false);
  const [showModalEditRef, setShowModalEditRef] = useState(false);
  const [showModalAddEmailRef, setShowModalAddEmailRef] = useState(false);
  const [selectedPermitData, setSelectedPermitData] = useState({});
  const [idPutReferencePermit, setIdPutReferencePermit] = useState('');
  const [data, setData] = useState({
    total: 0,
    permitRequests: []
  });
  const [dataMobile, setDataMobile] = useState({
    total: 0,
    permitRequests: []
  });
  const [payload, setPayload] = useState<ListPayloadType>({
    lang: getLanguage(),
    page: 1,
    pageSize: 50,
    search: '',
    sort: '-updated_at',
    permit_location_id: [],
    permit_type_id: [],
    work_location_id: [],
    status: [
      'Waiting for SUPERIOR to approve',
      'Waiting for ER HEAD to approve',
      'Waiting for doc revise',
      'Assigned',
      'Open',
      'Returned',
      'Processed',
      'Approved'
    ],
    expiredDate: '',
    pic_id: []
  });
  const [payloadMobile, setPayloadMobile] = useState<ListPayloadType>({
    lang: getLanguage(),
    page: 1,
    pageSize: 50,
    search: '',
    sort: '-updated_at',
    permit_location_id: [],
    permit_type_id: [],
    work_location_id: [],
    status: [
      'Waiting for SUPERIOR to approve',
      'Waiting for ER HEAD to approve',
      'Waiting for doc revise',
      'Assigned',
      'Open',
      'Returned',
      'Processed',
      'Approved'
    ],
    expiredDate: '',
    pic_id: []
  });
  const [filter, setFilter] = useState({
    permit_location_id: [],
    permit_type_id: [],
    work_location_id: [],
    status: [
      'Waiting for SUPERIOR to approve',
      'Waiting for ER HEAD to approve',
      'Waiting for doc revise',
      'Assigned',
      'Open',
      'Returned',
      'Processed',
      'Approved'
    ],
    pic_name: []
  });
  const { confirm } = Modal;
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenHistory, setIsOpenHistory] = useState(false);
  const [permitRequestId, setPermitRequestId] = useState<string>('');
  const [canView, setCanView] = useState(false);
  const [canCreate, setCanCreate] = useState(false);
  const [detailId, setDetailId] = useState({});
  const [onSearchMobile, setOnSearchMobile] = useState(false);
  const [onFilterMobile, setOnFilterMobile] = useState(false);
  let isEyeClick = false;
  let isPencilClick = false;

  const applyFilter = () => {
    setIsLoadingFilter(true);
    setPayload({
      ...payload,
      page: 1,
      permit_location_id: filter.permit_location_id,
      permit_type_id: filter.permit_type_id,
      work_location_id: filter.work_location_id,
      status: filter.status,
      pic_id: filter.pic_name
    });
    setPayloadMobile({
      ...payloadMobile,
      page: 1,
      permit_location_id: filter.permit_location_id,
      permit_type_id: filter.permit_type_id,
      work_location_id: filter.work_location_id,
      status: filter.status,
      pic_id: filter.pic_name
    });
  };

  const resetFilter = () => {
    setIsLoadingFilter(true);
    setFilter({
      permit_location_id: [],
      permit_type_id: [],
      work_location_id: [],
      status: [
        'Waiting for SUPERIOR to approve',
        'Waiting for ER HEAD to approve',
        'Waiting for doc revise',
        'Assigned',
        'Open',
        'Returned',
        'Processed',
        'Approved'
      ],
      pic_name: []
    });
    setPayload({
      ...payload,
      permit_location_id: [],
      permit_type_id: [],
      work_location_id: [],
      status: [
        'Waiting for SUPERIOR to approve',
        'Waiting for ER HEAD to approve',
        'Waiting for doc revise',
        'Assigned',
        'Open',
        'Returned',
        'Processed',
        'Approved'
      ],
      pic_id: []
    });
    setPayloadMobile({
      ...payloadMobile,
      permit_location_id: [],
      permit_type_id: [],
      work_location_id: [],
      status: [
        'Waiting for SUPERIOR to approve',
        'Waiting for ER HEAD to approve',
        'Waiting for doc revise',
        'Assigned',
        'Open',
        'Returned',
        'Processed',
        'Approved'
      ],
      pic_id: []
    });
  };

  const fetchList = async () => {
    let convertPayload: any = payload;
    let newPayload: any = {};
    for (const key in convertPayload) {
      if (Array.isArray(convertPayload[key])) {
        if (convertPayload[key].length) {
          newPayload[key] = convertPayload[key];
        }
      } else if (convertPayload[key]) {
        newPayload[key] = convertPayload[key];
      }
    }

    let listPermitRequest = await permitRequestList(newPayload).unwrap();

    listPermitRequest = {
      ...listPermitRequest,
      permitRequests: listPermitRequest?.rows.map((el: any, idx: any) => ({ ...el, key: idx }))
    };
    setData(listPermitRequest);
    setIsLoadingFilter(false);
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      setIsAdmin(user?.roles?.some((v: any) => v?.name?.toLowerCase() === 'administrator'));
    }
    if (!isEmpty(pathname)) {
      const paths = pathname.split('/');
      if (!hasPermission(paths[1]).can_view) {
        navigate('/403');
      }
      setCanView(hasPermission(paths[1]).can_view);
      setCanCreate(hasPermission('create permit request').can_view);
    }
  }, [user, pathname]);

  useEffect(() => {
    setIsLoadingFilter(true);
    fetchList();
  }, [payload]);

  useEffect(() => {
    if (isErrorPermitRequestList) {
      const errorMsg: any = errorPermitRequestList;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [errorPermitRequestList, isErrorPermitRequestList]);

  useEffect(() => {
    if (isErrorPutPermitReqReferencePermit) {
      const errorMsg: any = errorPutPermitReqReferencePermit;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [errorPutPermitReqReferencePermit, isErrorPutPermitReqReferencePermit]);

  useEffect(() => {
    if (isSuccessPutPermitReqReferencePermit) {
      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('general.success_text')}`}
        />
      );
      setShowModalEditRef(false);
      fetchList();
    }
  }, [isSuccessPutPermitReqReferencePermit]);

  useEffect(() => {
    setPayload({
      ...payload,
      lang: getLanguage()
    });

    setDataMobile({
      total: 0,
      permitRequests: []
    });
    setPayloadMobile({
      ...payloadMobile,
      lang: getLanguage()
    });
  }, [getLanguage()]);

  const buttonConfig: ButtonDataTableTypeItems = [
    {
      name: t('general.create_btn'),
      link: '',
      className: 'bg-[#55A853] text-white font-bold',
      onClick: () => { navigate('/create-permit-request'); }
    }
  ];

  const buttonConfigMobile: ButtonDataTableTypeItems = [
    {
      name: t('general.create_btn') + ' ' + t('tab.permit'),
      link: '',
      className: 'bg-[#55A853] text-white font-bold w-[90%]',
      onClick: () => { navigate('/create-permit-request'); }
    }
  ];

  const generateDropdownItem = (data: PermitRequestDetail) => {

    const items: MenuProps['items'] = [
      {
        label: (
          <div onClick={() => handleModalEditRef(data?.reference_permit, data?.expired_date, data.id ?? '')}>
            <EditFilled className='text-[#55A853] text-[20px] p-1 mr-2' />
            <span>{t('permit_request.action.reference')}</span>
          </div>
        ),
        key: '0',
      },
      {
        type: 'divider',
      },
      {
        label: (
          <div onClick={() => {
            setSelectedPermitData({
              permit_request_id: data?.id,
              permit_location: data?.permit_location?.name,
              request_number: data?.request_number
            });
            setShowModalAddEmailRef(true);
          }}>
            <UserAddOutlined className='text-[#55A853] text-[20px] p-1 mr-2' />
            <span>{t('permit_request.action.add_email_invitation')}</span>
          </div>
        ),
        key: '1',
      },
      {
        type: 'divider',
      },
      {
        label: (
          <div onClick={() => navigate(`/permit-request/${data.can_edit ? 'edit' : 'detail'}/${data.id}`)}>
            <FolderFilled className='text-[#55A853] text-[20px] p-1 mr-2' />
            <span>{t('permit_request.action.detail')}</span>
          </div>
        ),
        key: '2',
      },
      {
        type: 'divider',
      },
      {
        label: (
          <div onClick={() => {
            setIsOpenHistory(true);
            setPermitRequestId(data.id ?? '');
            setDetailId(data);
          }}>
            <EyeOutlined className='text-[#55A853] text-[20px] p-1 mr-2' />
            <span>{t('permit_request.action.history_conversation')}</span>
          </div>
        ),
        key: '3',
      },
    ];

    return items;
  };

  const columns: ColumnsType<PermitRequestListType> = [
    {
      title: 'Action',
      key: 'action',
      width: 180,
      render: (data, _) => (
        <div>
          {
            canView && !isAdmin &&
            <Button
              type='text'
              className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0'
              onClick={() => {
                setIsOpenHistory(true);
                setPermitRequestId(data.id);
                setDetailId(data);
              }}
              disabled={data.status === 'Draft'}
            >
              <EyeOutlined style={{ fontSize: '20px' }} className='text-[#55A853] p-1' />
            </Button>
          }
          {
            canView && !isAdmin &&
            <Button
              type='text'
              className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0'
              onClick={() => navigate(`/permit-request/${data.can_edit ? 'edit' : 'detail'}/${data.id}`)}
            >
              <FolderFilled style={{ fontSize: '20px' }} className='text-[#55A853] p-1' />
            </Button>
          }
          {
            isAdmin &&
            <Dropdown menu={{ items: generateDropdownItem(data) }} trigger={['click']}>
              <Button
                type='text'
                className='shadow-md shadow-[#55A853] rounded-sm mr-3 p-1 flex'
                onClick={(e) => e.preventDefault()}
              >
                <span className='m-auto text-[#54A853]'>Action</span>
                <DownOutlined style={{ fontSize: '12px' }} className='text-[#55A853] self-center' />
              </Button>
            </Dropdown>
          }
        </div>
      ),
    },
    {
      title: 'Number',
      dataIndex: 'request_number',
      key: 'request_number',
      sorter: true,
      render: (data, record) => {
        return (<div>{data || '-'}</div>);
      }
    },
    {
      title: 'Location',
      dataIndex: 'permit_location',
      key: 'permit_location',
      sorter: true,
      render: (data, record) => {
        return (<div>{data?.name}</div>);
      }
    },
    {
      title: 'Permit Name',
      dataIndex: 'permit_name',
      key: 'permit_name',
      sorter: true,
      render: (data, record) => {
        return (<div>{data?.name[getLanguage()]}</div>);
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (data, record) => {
        return (
          <div
            className={
              `
                ml-3 py-1 px-2  rounded-lg border border-solid text-xs text-center
                ${data === 'Draft' ? 'border-[#3D81DB] text-[#3D81DB] bg-[#3D81DB]/20' : ''}
                ${data?.toLowerCase().includes('waiting') ? 'border-[#F7B648] text-[#F7B648] bg-[#F7B648]/20' : ''}
                ${data?.toLowerCase().includes('approved') || data?.toLowerCase().includes('close') ? 'border-[#659711] text-[#659711] bg-[#659711]/20' : ''}
                ${data?.toLowerCase() === 'assigned' ? 'border-[#6552B7] text-[#6552B7] bg-[#6552B7]/20' : ''}
                ${data?.toLowerCase().includes('open') || data?.toLowerCase().includes('processed') ? 'border-[#FF7B31] text-[#FF7B31] bg-[#FF7B31]/20' : ''}
                ${data?.toLowerCase().includes('rejected') ? 'border-[#930B16] text-[#930B16] bg-[#930B16]/20' : ''}
                ${data?.toLowerCase().includes('returned') ? 'border-[#ED3F3F] text-[#ED3F3F] bg-[#ED3F3F]/20' : ''}
              `
            }
          >
            {record?.status_view}
          </div>
        );
      }
    },
    {
      title: 'Requestor',
      dataIndex: 'requestor',
      key: 'requestor',
      sorter: true,
      render: (data, record) => {
        return (<div>{data?.display_name}</div>);
      }
    },
    {
      title: 'Date Modified',
      dataIndex: 'updated_at',
      key: 'updated_at',
      defaultSortOrder: 'descend',
      sorter: true,
      render: (data, record) => {
        return (<div>{moment(data).tz(user?.timezone?.identifier).utc(true).format('DD-MMM-YYYY') || '-'}</div>);
      }
    },
    {
      title: 'ER PIC',
      dataIndex: 'pic',
      key: 'pic',
      sorter: true,
      render: (data, record) => {
        return (<div>{data?.display_name || '-'}</div>);
      }
    },
    {
      title: 'Permit Type',
      dataIndex: 'permit_type',
      key: 'permit_type',
      sorter: true,
      render: (data, record) => {
        return (<div>{data?.name}</div>);
      }
    },
    {
      title: 'Created By',
      dataIndex: 'creator',
      key: 'creator',
      sorter: true,
      render: (data, record) => {
        return (<div>{data?.display_name}</div>);
      }
    },
    {
      title: 'Email',
      dataIndex: 'requestor',
      key: 'requestor',
      sorter: true,
      render: (data, record) => {
        return (<div>{data?.email}</div>);
      }
    }
  ];

  const handleModalEditRef = (referencePermit:string, expiredDate:string, id:string) => {
    formEditRef.setFieldsValue({
      reference_permit: referencePermit ? referencePermit : '',
      expired_date: expiredDate ? dayjs(moment(expiredDate).format('DD-MMM-YYYY'), 'DD-MMM-YYYY') : null
    });
    setIdPutReferencePermit(id);
    setShowModalEditRef(true);
  };

  const handleSubmitEditRef = async () => {
    confirm({
      title: t('general.label_save_data'),
      icon: <ExclamationCircleFilled />,
      okText: t('manage_group.yes_btn'),
      okType: 'danger',
      cancelText: t('manage_group.no_btn'),
      onOk: async () => {
        const payload = {
          ...formEditRef.getFieldsValue(),
          expired_date: moment(new Date(formEditRef.getFieldsValue()?.expired_date)).format('YYYY-MM-DD'),
          id: idPutReferencePermit
        };
        await putPermitReqReferencePermit(payload).unwrap();
      },
    });
  };

  const viewCardList = () => {
    return (
      <Spin tip="Loading" size="large" spinning={isLoading}>
        {
          dataMobile?.permitRequests?.map((el: any, idx: any) => (
            <div key={idx} className="p-0 my-1 bg-white rounded-xl p-4" onClick={() => {
              if (!isEyeClick && !isPencilClick) {
                navigate(`/permit-request/${el?.can_edit ? 'edit' : 'detail'}/${el?.id}`);
              }
            }}>
              <div className='flex justify-between mb-2'>
                <div className='my-auto'>
                  <div
                    className={
                      `
                        py-1 px-2  rounded-lg border border-solid text-xs text-center
                        ${el?.status === 'Draft' ? 'border-[#3D81DB] text-[#3D81DB] bg-[#3D81DB]/20' : ''}
                        ${el?.status?.toLowerCase().includes('waiting') ? 'border-[#F7B648] text-[#F7B648] bg-[#F7B648]/20' : ''}
                        ${el?.status?.toLowerCase().includes('approved') || el?.status?.toLowerCase().includes('close') ? 'border-[#659711] text-[#659711] bg-[#659711]/20' : ''}
                        ${el?.status?.toLowerCase() === 'assigned' ? 'border-[#6552B7] text-[#6552B7] bg-[#6552B7]/20' : ''}
                        ${el?.status?.toLowerCase().includes('open') || el?.status?.toLowerCase().includes('processed') ? 'border-[#FF7B31] text-[#FF7B31] bg-[#FF7B31]/20' : ''}
                        ${el?.status?.toLowerCase().includes('rejected') ? 'border-[#930B16] text-[#930B16] bg-[#930B16]/20' : ''}
                        ${el?.status?.toLowerCase().includes('returned') ? 'border-[#ED3F3F] text-[#ED3F3F] bg-[#ED3F3F]/20' : ''}
                      `
                    }
                  >
                    {el?.status_view}
                  </div>
                </div>
                <div className='flex'>
                  <div>
                    {
                      canView &&
                        <Button
                          type='text'
                          className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0'
                          onClick={() => {
                            isEyeClick = true;
                            isPencilClick = false;
                            setIsOpenHistory(true);
                            setPermitRequestId(el?.id);
                            setDetailId(el);
                          }}
                          disabled={el?.status === 'Draft'}
                        >
                          <EyeOutlined style={{ fontSize: '15px' }} className='text-[#55A853] p-1' />
                        </Button>
                    }
                  </div>
                  <div>
                    {
                      isAdmin &&
                        <Button
                          type='text'
                          className='shadow-md shadow-gray-400 rounded-sm p-0'
                          onClick={() => {
                            isEyeClick = false;
                            isPencilClick = true;
                            handleModalEditRef(el?.reference_permit, el?.expired_date, el?.id);
                          }}
                        >
                          <EditFilled style={{ fontSize: '15px' }} className='text-[#55A853] p-1' />
                        </Button>
                    }
                  </div>
                </div>
              </div>
              <p className='font-bold'>{el?.permit_name?.name?.[getLanguage()] || '-'}</p>
              <p className='text-[12px] text-[#616161] mb-3'>{el?.request_number || '-'}</p>
              <div className='flex'>
                <div className='mr-4'>
                  <p className='text-[12px] text-[#616161]'>{t('permit_request.column.date_modified')}</p>
                  <p>{el?.updated_at ? moment(el?.updated_at).tz(user?.timezone?.identifier).utc(true).format('DD-MMM-YYYY') : '-'}</p>
                </div>
                <div>
                  <p className='text-[12px] text-[#616161]'>{t('permit_request.column.er_pic')}</p>
                  <p>{el?.pic?.display_name}</p>
                </div>
              </div>
            </div>
          ))
        }
        {
          dataMobile?.permitRequests?.length !== dataMobile?.total ?
            (
              <Button
                type='text'
                className='bg-[#55A853] text-white px-10 w-full mt-4'
                disabled={isLoading}
                onClick={() => {
                  setPayloadMobile({ ...payloadMobile, page: payloadMobile.page + 1 });
                }}
              >
                {isLoading ? <Spin /> : t('general.load_more') || ''}
              </Button>
            ) :
            (<div></div>)
        }
      </Spin>
    );
  };

  const fetchListMobile = async () => {
    let convertPayload: any = payloadMobile;
    let newPayload: any = {};
    for (const key in convertPayload) {
      if (Array.isArray(convertPayload[key])) {
        if (convertPayload[key].length) {
          newPayload[key] = convertPayload[key];
        }
      } else if (convertPayload[key]) {
        newPayload[key] = convertPayload[key];
      }
    }

    newPayload.searchFields = payloadMobile.searchFields;
    let listPermitRequest = await permitRequestList(newPayload).unwrap();

    const newPermitRequest = listPermitRequest?.rows.map((el: any, idx: any) => ({ ...el, key: idx }));
    const concatNewPermitRequest = [...dataMobile.permitRequests, ...newPermitRequest];

    listPermitRequest = {
      ...listPermitRequest,
      permitRequests: (!onSearchMobile && !onFilterMobile) ? concatNewPermitRequest : newPermitRequest
    };

    setOnSearchMobile(false);
    setOnFilterMobile(false);
    setDataMobile(listPermitRequest);
    setIsLoadingFilter(false);
  };

  useEffect(() => {
    fetchListMobile();
  }, [payloadMobile]);

  useEffect(() => {
    viewCardList();
  }, [dataMobile.permitRequests]);

  useEffect(() => {
    if (!isOpenHistory) {
      isEyeClick = false;
    }
  }, [isOpenHistory]);

  useEffect(() => {
    if (!showModalEditRef) {
      isPencilClick = false;
    }
  }, [showModalEditRef]);

  return (
    <div className='w-full'>
      <ModalHistory
        id={permitRequestId}
        modalAction={{
          isOpenHistory: isOpenHistory,
          setIsOpenHistory: setIsOpenHistory
        }}
        detail={detailId}
      />
      <ModalFilter
        modalAction={{
          isModalOpen: isModalOpen,
          setIsModalOpen: setIsModalOpen
        }}
        filter={{
          filter: filter,
          setFilter: setFilter
        }}
        filterAction={{
          isLoadingFilter: isLoadingFilter,
          resetFilter: resetFilter,
          applyFilter: applyFilter
        }}
      />
      <div className='md:hidden'>
        <CardList
          payloadMobile={{
            payloadMobile: payloadMobile,
            setPayloadMobile: setPayloadMobile
          }}
          onSearchMobile={{
            onSearchMobile: onSearchMobile,
            setOnSearchMobile: setOnSearchMobile
          }}
          onFilterMobile={{
            onFilterMobile: onFilterMobile,
            setOnFilterMobile: setOnFilterMobile
          }}
          modalOpen={{
            isModalOpen: isModalOpen,
            setIsModalOpen: setIsModalOpen
          }}
          viewCardList={viewCardList}
          buttonConfig={canCreate ? buttonConfigMobile : []}
        />
      </div>
      <div className='max-md:hidden'>
        <DataTable
          className=''
          columns={columns}
          data={data.permitRequests}
          buttonConfig={canCreate ? buttonConfig : []}
          loading={isLoading}
          totalData={data.total}
          payload={{
            payload: payload,
            setPayload: setPayload
          }}
          modalOpen={{
            isModalOpen: isModalOpen,
            setIsModalOpen: setIsModalOpen
          }}
          scrollWidth={2000}
        />
      </div>
      <ModalEditReference
        form={formEditRef}
        modalAction={{
          showModalEditRef: showModalEditRef,
          setShowModalEditRef: setShowModalEditRef
        }}
        handleSubmit={handleSubmitEditRef}
        loading={{
          isLoadingSubmit: isLoadingPutPermitReqReferencePermit
        }}
      />
      <ModalAddEmail
        modalAction={{
          showModalAddEmailRef: showModalAddEmailRef,
          setShowModalAddEmailRef: setShowModalAddEmailRef
        }}
        dataPermit={selectedPermitData}
        isPermitRequest={true}
      />
    </div>
  );
};

export default PermitRequest;