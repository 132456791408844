import type { AuthStateType } from 'constant/Types/AuthType';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DB_CREDENTIAL_REFRESH_TOKEN,
  DB_CREDENTIAL_TOKEN,
  DB_CREDENTIAL_USER,
  EMPTY
} from 'constant';

import {
  saveAuthToDb,
  getAuthFromDb,
  deleteAuthFromDb
} from './Thunk';

const initialState: AuthStateType = {
  isFreshInstall: true,
  isAuthenticated: false,
  token: '',
  refresh_token: '',
  user: {}
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthentication: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    }
  },
  extraReducers: ({ addCase }) => {
    addCase(saveAuthToDb.fulfilled, (state, action) => {
      const id = action.meta.arg._id;
      const value = action.meta.arg.value;

      switch (id) {
        case DB_CREDENTIAL_TOKEN:
          state.token = value;
          break;
        case DB_CREDENTIAL_REFRESH_TOKEN:
          state.refresh_token = value;
          break;
        case DB_CREDENTIAL_USER:
          state.user = JSON.parse(value);
          break;
        default:
          break;
      }
    });

    addCase(getAuthFromDb.fulfilled, (state, action) => {
      const id = action?.payload?._id || EMPTY.STRING;
      const value = action?.payload?.value || EMPTY.STRING;

      switch (id) {
        case DB_CREDENTIAL_TOKEN:
          state.token = value;
          break;
        case DB_CREDENTIAL_REFRESH_TOKEN:
          state.refresh_token = value;
          break;
        case DB_CREDENTIAL_USER:
          state.user = value ? JSON.parse(value) : {};
          break;
        default:
          break;
      }
    });

    addCase(deleteAuthFromDb.fulfilled, (state, action) => {
      const id = action?.payload?._id;

      switch (id) {
        case DB_CREDENTIAL_TOKEN:
          state.token = EMPTY.STRING;
          break;
        case DB_CREDENTIAL_REFRESH_TOKEN:
          state.refresh_token = EMPTY.STRING;
          break;
        case DB_CREDENTIAL_USER:
          state.user = {};
          break;
        default:
          break;
      }
    });
  }
});

export const { setAuthentication } = auth.actions;
export default auth.reducer;
