import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

const PermitBySector = ({ data }:any) => {
  const { t } = useTranslation();

  const state = {
    series:  data?.series,
    options: {
      chart:{
        stacked:true,
      },
      plotOptions: {
        bar: {
          borderRadius: 15,
          horizontal: true,
        }
      },
      dataLabels: {
        style: {
          colors: ['black']
        }
      },
      xaxis: {
        title: {
          text: t('dashboard.permit_status'),
          offsetY: -18,
          offsetX: -50
        },
        labels:{
          show: false
        },
        categories:data?.categories,
      },
      yaxis: {
        labels: {
          style: {
            colors: 'black',
            fontSize: '12px',
            fontFamily: 'Noto-Sans, sans-serif',
            fontWeight: 'bold',
          }
        },
      },
      grid: {
        show: true,
        borderColor: '#90A4AE',
        strokeDashArray: 0,
        xaxis: {
          lines: {
            show: false
          }
        },   
        yaxis: {
          lines: {
            show: false
          }
        },  
        row: {
          colors: undefined,
          opacity: 0.5
        },  
        column: {
          colors: undefined,
          opacity: 0.5
        },  
        padding: {
          left: 30
        },
      },
      legend: {
        show: true
      },
      colors: ['#BCC5CB', '#B1E3FF', '#FFF1E3', '#71B670']
    },
  };
  
  return (
    <div className="compliance_activities app bg-[#F7F9FB] rounded-lg">
      <div className='bg-gradient-to-r from-[#F04130] to-[#FFCCC7] rounded-t-lg h-[70px] text-white font-bold flex items-center pl-9'>
        <p>
          {t('dashboard.title_chart_3')}
        </p>
      </div>
      <div className="row h-[275px] overflow-auto">
        <div className="mixed-chart">
          <Chart
            type="bar"
            height={data?.categories?.length * 60}
            series={state.series}
            options={state.options}
          />
        </div>
      </div>
    </div>
  );
};

export default PermitBySector;