import { Button, Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toast from 'components/Toast';
import { toast } from 'react-toastify';
import { useDetailPermitNameObligationMutation, usePutPermitNameObligationMutation } from 'api/PermitName';
import { useLocation, useNavigate, useParams } from 'react-router';
import ObligationFormItem from '../ObligationFormItem';
import { transformError } from 'utils/ErrorTransformer';
import { FaChevronLeft } from 'react-icons/fa';


const PermitNameObligationEditForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { obligationId } = useParams();
  const [form] = Form.useForm();
  const initialForm = {
    name: '',
    actions: [
      {
        name: '',
      },
    ],
  };
  const [obligationDetail, setObligationDetail] = useState(null);

  const [getDetail, {
    error: errorDetail,
    isError: isErrorDetail,
  }] = useDetailPermitNameObligationMutation();
  const [putPermitNameObligation, { isLoading: isLoadingSubmitObligation }] = usePutPermitNameObligationMutation();

  const fetchDetail = async (id: any) => {
    const dataDetail = await getDetail(id).unwrap();
    setObligationDetail(dataDetail);
  };

  const addAction = () => {
    const actions = form.getFieldValue('actions');
    actions.push({ name: '' });
    form.setFieldValue('actions', actions);
  };

  const removeAction = (actionIndex: number) => {
    const actions = form.getFieldValue('actions');
    actions.splice(actionIndex, 1);
    form.setFieldValue('actions', actions);
  };

  const backToTabObligation = () => {
    const currentPath = location.pathname;
    const newPath = currentPath.split('/').slice(0, -2).join('/');
    navigate(newPath);
  };

  const onFinish = async () => {
    const dataForm = form.getFieldsValue();

    const submitForm = {
      permit_name_obligation: {
        name: dataForm.name,
        permit_name_obligation_actions: dataForm.actions.map((action: any) => ({name: action.name}))
      },
    };

    await putPermitNameObligation({id: obligationId, body: submitForm}).unwrap()
      .then(() => {
        toast.success(
          <Toast
            message={t('general.success_text')}
            detailedMessage={`${t('permit_name.obligation.success_submit_update_obligation')}`}
          />
        );

        backToTabObligation();
      })
      .catch(() => {
        toast.error(
          <Toast
            message={t('general.error_text')}
            detailedMessage={t('permit_name.obligation.failed_submit_update_obligation')}
          />
        );
      });
  };

  const transformApiResponseToForm = (data: any) => {
    return ({
      name: data.name,
      actions: data.permit_name_obligation_actions.map((action: any) => ({name: action?.name || '-'})),
    });
  };

  useEffect(() => {
    if(obligationDetail){
      form.setFieldsValue(transformApiResponseToForm(obligationDetail));
    }
    else{
      form.setFieldsValue(initialForm);
    }
  }, [obligationDetail]);

  useEffect(() => {
    if (obligationId) {
      fetchDetail(obligationId);
    }
  }, [obligationId]);

  useEffect(() => {
    if (isErrorDetail) {
      const errorMsg:any = errorDetail;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isErrorDetail]);

  return (
    <div>
      <div className='flex gap-1 items-center mb-4 cursor-pointer text-sm text-[#939aa5]' 
        onClick={backToTabObligation}
      >
        <FaChevronLeft />
        <span>
          {t('general.back')}
        </span>
      </div>
      <Form form={form} onFinish={onFinish} requiredMark={false}>
        <div className="flex justify-between items-center mb-4">
          <span className="font-bold text-lg">
            {t('permit_name.obligation.edit_obligation')}
          </span>
        </div>
        <div className="mb-5">
          <ObligationFormItem
            addAction={() => addAction()}
            removeAction={(actionIndex: number) => removeAction(actionIndex)}
          />
        </div>

        <div className='bg-[#d7d7d7] h-[1px] mb-4'/>

        <div className="flex justify-end">
          <Button
            type="text"
            className="bg-[#55A853] text-white font-bold"
            htmlType="submit"
            disabled={isLoadingSubmitObligation}
          >
            {t('general.submit_btn')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default PermitNameObligationEditForm;
