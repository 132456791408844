import { Tabs, Form, Spin } from 'antd';
import type { TabsProps } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PermitTabPermitMonitoringCrm from './Permit';
import { useLocation, useNavigate, useParams } from 'react-router';
import Toast from 'components/Toast';
import { toast } from 'react-toastify';
import { hasPermission } from 'utils/Permission';
import { useDetailPermitMonitoringMutation } from 'api/PermitMonitoring';
import dayjs from 'dayjs';
import { transformError } from 'utils/ErrorTransformer';
import PermitMonitoringCrmFormTabObligation from './Obligation';
import { useSearchParams } from 'react-router-dom';
import { useProductDocsListMutation } from 'api/Options';
import Conversation from './Conversation';
import { useGetProductDocConversationMutation } from 'api/ProductDocConversation';

const FormPermitMonitoringCrm = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const complianceStatus = Form.useWatch('compliance_status', form);

  const [getDetail, {
    error: errorDetail,
    isError: isErrorDetail,
    isLoading: isLoadingDetail
  }] = useDetailPermitMonitoringMutation();
  const [productDocsList] = useProductDocsListMutation();

  const [activateTab, setActiveTab] = useState('1');
  const [canUpdate, setCanUpdate] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [detail, setDetail] = useState<unknown>(null);
  const [canChat, setCanChat] = useState(false);
  const [refetchData, setRefetchData] = useState(false);

  const [getProductDocConversationById, {
    isError: isErrorGetProductDocConversationById,
    error: errorGetProductDocConversationById
  }] = useGetProductDocConversationMutation();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('tab.permit'),
      children:
        <PermitTabPermitMonitoringCrm
          id={id}
          form={
            {
              setFieldsValue: form.setFieldsValue,
              form: form
            }
          }
          isView={isView}
          isEdit={isEdit}
          detail={detail}
        />,
    },
    {
      key: '2',
      label: t('tab.obligation'),
      children: <PermitMonitoringCrmFormTabObligation id={id} />,
    },
    {
      key: '3',
      label: t('tab.conversation'),
      children: <Conversation id={id} refetchData={refetchData} />,
    },
  ];

  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
    <div>
      <DefaultTabBar
        {...props}
        className='px-10 rounded-xl text-[#AAAAAA]'
        style={{ background: '#FFF' }}
      />
    </div>
  );

  const onTabClick = (e: any) => {
    if (e === '2' && !isEdit && !canUpdate) {
      toast.warning(<Toast message={t('general.warning_text')} detailedMessage={t('permit_monitoring.cant_move_to_tab_obligation')} />);
      return;
    }

    if (e === '3') {
      setRefetchData(!refetchData);
    }

    setActiveTab(e);
  };

  const onChange = (key: string) => {};

  const onFinish = () => {};

  const fetchDetail = async () => {
    let dataDetail = await getDetail(id).unwrap();
    const remainingDays = dataDetail?.remaining_day?.remaining_days;
    const totalDays = dataDetail?.remaining_day?.total_days;
    const percentRemainingDays = (totalDays - remainingDays) * 100 / totalDays;
    dataDetail = {
      ...dataDetail,
      activity: dataDetail?.permit_name?.activity,
      permit_tier: dataDetail?.permit_name?.permit_tier,
      permit_name_value: dataDetail?.permit_name?.name,
      active_date: dataDetail?.issued_date ? dayjs(dataDetail.issued_date) : null,
      release_date: dataDetail?.released_date ? dayjs(dataDetail.released_date) : null,
      expired_date: dataDetail?.expired_date ? dayjs(dataDetail.expired_date) : null,
      expire_reference: dataDetail?.expire_permit_reference ? dayjs(dataDetail.expire_permit_reference) : null,
      permit_status: dataDetail?.status,
      requestor: dataDetail?.requestor?.display_name,
      sector: dataDetail?.permit_name?.permit_type?.name,
      compliance_status: dataDetail?.compliance_status,
      remaining_expired: percentRemainingDays,
      departments: dataDetail?.departments?.map((department: any) => department.department_name),
    };
    if(dataDetail?.is_permit_reference_exist && dataDetail?.permit_reference){
      const productDocs = await productDocsList({
        registration_number: dataDetail?.permit_reference || '',
      }).unwrap();
      if(productDocs[0]){
        dataDetail = {
          ...dataDetail,
          permit_reference_id: productDocs[0].id
        };
      }
    }
    setDetail(dataDetail);
    form.setFieldsValue(dataDetail);
  };

  const fetchConversation = async (id: any) => {
    await getProductDocConversationById(id).unwrap().then((resp: any) => {
      setCanChat(resp?.can_chat);
    });
  };

  useEffect(() => {
    fetchConversation(id);
    if (tab) {
      setActiveTab(tab);
    }
  }, []);

  useEffect(() => {
    if (isErrorDetail) {
      const errorMsg: any = errorDetail;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isErrorDetail]);

  useEffect(() => {
    const paths = pathname.split('/');
    const permissions = hasPermission(paths[1]);
    
    if (!permissions.can_view) {
      navigate('/403');
      return;
    }
    setCanUpdate(permissions.can_update);
  
    if (id) {
      const isEditPath = pathname.includes('edit');
      const isDetailPath = pathname.includes('detail');
  
      if (isEditPath) {
        setIsEdit(true);
        setIsView(false);
        if (!permissions.can_update) {
          navigate(`/permit-monitoring-crm/detail/${id}`);
        }
      } else if (isDetailPath) {
        setIsEdit(false);
        setIsView(true);
        if (permissions.can_update) {
          navigate(`/permit-monitoring-crm/edit/${id}`);
        }
      }
      fetchDetail();
    }
  }, [pathname, id, navigate]);

  useEffect(() => {
    if (isErrorGetProductDocConversationById) {
      const errorMsg:any = errorGetProductDocConversationById;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isErrorGetProductDocConversationById]);
  
  return (
    <Spin tip="Loading" size="large" spinning={isLoadingDetail}>
      <Form
        className='h-[100px]'
        layout="vertical"
        autoComplete="off"
        form={form}
        requiredMark={false}
        onFinish={onFinish}
      >
        <div className='px-2 py-3 text-[12px] bg-white rounded-lg mb-4 flex flex-row'>
          <span
            className={
              `
                ml-3 py-1 px-2  rounded-lg border border-solid text-xs text-center
                ${complianceStatus === 'Compliance' ? 'border-[#659711] text-[#659711] bg-[#659711]/20' : ''}
                ${complianceStatus === 'Compliance Risk' ? 'border-[#F7B648] text-[#F7B648] bg-[#F7B648]/20' : ''}
                ${complianceStatus === 'Not Compliance' ? 'border-[#930B16] text-[#930B16] bg-[#930B16]/20' : ''}
              `
            }
          >
            {complianceStatus || '-'}
          </span>
        </div>
        <Tabs
          activeKey={activateTab}
          renderTabBar={renderTabBar}
          items={canChat ? items : items.filter((el:any) => el.key !== '3')}
          onTabClick={onTabClick}
          onChange={onChange}
        />
      </Form>
    </Spin>
  );
};

export default FormPermitMonitoringCrm;