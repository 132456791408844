import { Button, Card, Col, Form, Input, InputNumber, Row, Select, Spin, Switch, Empty } from 'antd';
import Title from 'antd/es/typography/Title';
import { useCompanySearchMutation, useWorkLocationHrisMutation, useTimezoneListMutation } from 'api/Options';
import { useListRolePaginationMutation } from 'api/Role';
import { useGetUserByIdMutation, usePutUserMutation, useMeMutation } from 'api/User';
import Toast from 'components/Toast';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import { OptionType } from 'constant/Types/OptionType';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaAngleLeft, FaMicrosoft, FaWhatsappSquare } from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useAppSelector } from 'store/Hooks';

const FormManageUser = () => {
  const { user } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isMtActive, setIsMtActive] = useState(false);
  const [isWaActive, setIsWaActive] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isView, setIsView] = useState(false);
  const [roles, setRoles] = useState<OptionType[]>([]);
  const [companies, setCompanies] = useState<OptionType[]>([]);
  const [worklocations, setWorklocations] = useState<OptionType[]>([]);
  const [timezones, setTimezones] = useState<OptionType[]>([]);
  const [dataUserId, setDataUserId] = useState<any>({});
  const [getUserById, {
    isLoading: isLoadingUser,
    error: errorUser,
    isError: isErrorUser,
  }] = useGetUserByIdMutation();
  const [putUser, {
    error: errorPutUser,
    isSuccess: isSuccessPutUser,
    isError: isErrorPutUser
  }] = usePutUserMutation();
  const [roleList, {
    error: errorRole,
    isError: isErrorRole,
    isLoading: isLoadingRole
  }] = useListRolePaginationMutation();
  const [companyList, {
    isLoading: isLoadingCompany
  }] = useCompanySearchMutation();
  const [worklocationList, {
    isLoading: isLoadingWorklocation
  }] = useWorkLocationHrisMutation();
  const [timezoneList, {
    isLoading: isLoadingTimezoneList
  }] = useTimezoneListMutation();
  const [getMe, {
    isError: isErrorMe,
    error: errorMe
  }] = useMeMutation();

  
  const fetchUserById = async () => {
    await fetchRoles('');
    await fetchCompany('');
    await fetchWorklocations('');
    await fetchTimezoneList('');

    const dataUser = await getUserById(params.id).unwrap();
    
    const role = dataUser?.roles?.map((v:any) => v.id);
    
    setDataUserId(dataUser);
    setIsActive(dataUser.is_active);
    setIsMtActive(dataUser.is_microsoft_teams_active);
    setIsWaActive(dataUser.is_whatsapp_number_active);
    form.setFieldsValue(
      {
        ...dataUser,
        roles: role
      }
    );
  };

  const fetchRoles = async (value: any) => {
    if (value.length > 2 || !value) {
      const payload = {
        page: 1,
        pageSize: 100,
        search: value ?? '',
        searchFields: 'name',
        sort: 'name',
        status: 'Active'
      }; 

      let { rows } = await roleList(payload).unwrap();
      const newDataRole = rows.map((el: any, idx: any) => {
        if (el) {
          return {
            label: el?.name,
            value: el?.id,
            key: idx
          };
        }
        return null;
      });

      setRoles(newDataRole.filter((v: any) => v));
    }
  };

  const fetchCompany = async (value: any) => {
    if (value.length > 2 || !value) {
      const payload = {
        filters: `company@=${value}`
      }; 
      let { data } = await companyList(payload).unwrap();
      const newDataCompany = data.map((el: any) => {
        if (el) {
          return {
            label: el?.company,
            value: el?.company
          };
        }
        return null;
      });

      setCompanies(newDataCompany.filter((v: any) => v));
    }
  };

  const fetchWorklocations = async (value: any) => {
    if (value.length > 2 || !value) {
      const payload = {
        filters: `workLocation@=${value}`
      }; 
      let { data } = await worklocationList(payload).unwrap();
      const newDataWorklocation = data.map((el: any) => {
        if (el) {
          return {
            label: el?.workLocation,
            value: el?.workLocation
          };
        }
        return null;
      });

      setWorklocations(newDataWorklocation.filter((v: any) => v));
    }
  };

  const fetchTimezoneList = async (value: any) => {
    if (value.length > 2 || !value) {
      const payload = {
        pageSize: 50,
        search: value ?? '',
        searchFields: 'timezone',
        sort: 'timezone',
      }; 
      let { rows } = await timezoneList(payload).unwrap();
      const newTimezones = rows.map((el: any) => {
        if (el) {
          return {
            label: el?.timezone,
            value: el?.id
          };
        }
        return null;
      });

      setTimezones(newTimezones.filter((v: any) => v));
    }
  };

  const onFinish = async (values: any) => {
    setIsLoading(true);
    const formData = {
      user: {
        ...values,
        roles: values?.roles.map((el:any) => ({role_id: el})),
        id: params.id,
        is_microsoft_teams_active: isMtActive,
        is_whatsapp_number_active: isWaActive,
        whatsapp_number: values?.whatsapp_number?.toString() || null,
        is_external: dataUserId?.is_external,
        is_active_directory: dataUserId?.is_active_directory,
        is_contractor: dataUserId?.is_contractor,
      }
    };
    
    await putUser(formData).unwrap().then(async () => {
      if (user?.id === params?.id){
        await getMe({}).unwrap();
      }
    });
  };

  useEffect(() => {
    setIsView(pathname.includes('detail'));
    fetchUserById();
  }, []);

  useEffect(() => {
    if (isErrorUser) {
      const errorMsg:any = errorUser;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isErrorUser]);

  useEffect(() => {
    if (isErrorRole) {
      const errorMsg:any = errorRole;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isErrorRole]);

  useEffect(() => {
    if (isErrorPutUser) {
      const errorMsg:any = errorPutUser;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isErrorPutUser]);
  
  useEffect(() => {
    if (isSuccessPutUser) {
      toast.success(<Toast message={t('general.success_text')} detailedMessage={`${t('manage_user.success_edit_user')}`} />);
      navigate('/manage-user');
    }
  }, [isSuccessPutUser]);

  useEffect(() => {
    if (isErrorMe){
      const errorMsg:any = errorMe;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    };
  }, [isErrorMe]);

  return(
    <Spin tip="Loading" size="large" spinning={isLoadingUser || isLoadingRole || isLoadingCompany || isLoadingWorklocation}>
      <Form className='h-full' onFinish={onFinish} layout="vertical" form={form} name="user_form">
        <div className='md:hidden bg-[#fff] w-fit rounded-lg cursor-pointer' onClick={() => navigate(-1)}>
          <FaAngleLeft size={40} fill='#55A853' />
        </div>
        <Card className='mt-3 rounded-xl' bodyStyle={{ background: '#FBFBFB', borderRadius: '20px' }}>
          <Title level={5}>{ t('manage_user.user_info_text') }</Title>
          <div className='grid grid-cols-1 max-md:grid-cols-1 md:grid-cols-2 gap-5 mt-5'>
            <div className='col-span-1'>
              <Form.Item
                name='display_name'
                label={ t('manage_user.label_display_name') }
                rules={[
                  {
                    required: true,
                    message: `${t('manage_user.required_msg.display_name')}`,
                  },
                ]}
              >
                <Input placeholder={ `${t('manage_user.placeholder_display_name')}` } disabled={isView || !isActive} />
              </Form.Item>
            </div>
            <div className='col-span-1'>
              <Form.Item
                name='username'
                label={ t('manage_user.label_username') }
              >
                <Input placeholder={ `${t('manage_user.placeholder_username')}` } disabled />
              </Form.Item>
            </div>
            <div className='col-span-1'>
              <Form.Item
                name='roles'
                label={ t('manage_user.label_role') }
                rules={[
                  {
                    required: true,
                    message: `${t('manage_user.required_msg.role')}`,
                  },
                ]}
              >
                <Select
                  mode='multiple'
                  showSearch
                  placeholder={ `${t('manage_user.placeholder_role')}` }
                  filterOption={false}
                  onSearch={fetchRoles}
                  notFoundContent={isLoadingRole ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                  options={roles}
                  disabled={isView || !isActive}
                />
              </Form.Item>
            </div>
            <div className='col-span-1'>
              <Form.Item
                name='email'
                label={ t('manage_user.label_email') }
                rules={[
                  {
                    required: true,
                    message: `${t('manage_user.required_msg.email')}`,
                  },
                ]}
              >
                <Input placeholder={ `${t('manage_user.placeholder_email')}` } disabled={isView || !isActive} />
              </Form.Item>
            </div>
            <div className='col-span-1'>
              <Form.Item
                name='company_name'
                label={ t('manage_user.label_company') }
                rules={[
                  {
                    required: true,
                    message: `${t('manage_user.required_msg.company')}`,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={ `${t('manage_user.placeholder_company')}` }
                  filterOption={false}
                  onSearch={fetchCompany}
                  notFoundContent={isLoadingCompany ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                  options={companies}
                  disabled={isView || !isActive}
                />
              </Form.Item>
            </div>
            <div className='col-span-1'>
              <Form.Item
                name='work_location_name'
                label={ t('manage_user.label_worklocation') }
                rules={[
                  {
                    required: true,
                    message: `${t('manage_user.required_msg.worklocation')}`,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={ `${t('manage_user.placeholder_worklocation')}` }
                  filterOption={false}
                  onSearch={fetchWorklocations}
                  notFoundContent={isLoadingWorklocation ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                  options={worklocations}
                  disabled={isView || !isActive}
                />
              </Form.Item>
            </div>
            <div className='col-span-1'>
              <Form.Item
                name='timezone_id'
                label={ t('manage_user.label_timezone') }
                rules={[
                  {
                    required: true,
                    message: `${t('manage_user.required_msg.timezone')}`,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={ `${t('manage_user.placeholder_timezone')}` }
                  filterOption={false}
                  onSearch={fetchTimezoneList}
                  options={timezones}
                  notFoundContent={isLoadingTimezoneList ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                  disabled={isView || !isActive}
                />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            name='is_active'
            valuePropName='checked'
            label={ t('manage_user.label_is_active') }
            className='mt-4'
          >
            <Switch onChange={(v) => {
              form.setFieldValue('is_active', v);
              setIsActive(v);
              if (!v) {
                setIsMtActive(v);
                setIsWaActive(v);
              }
            }} disabled={isView} />
          </Form.Item>
          <hr className='mt-5 mb-5' style={{height:'10px', borderWidth:'0', color:'gray', backgroundColor:'#E5EDE5'}} />
          <Title level={5} className=''>{ t('manage_user.connected_chat_text') }</Title>
          <div className='grid grid-cols-1 max-md:grid-cols-1 md:grid-cols-2 gap-5 mt-5'>
            <div className='col-span-1'>
              <Form.Item
                name='microsoft_teams'
                label={
                  <div>
                    <label className='mr-3'>
                      { t('manage_user.label_ms_teams') }
                    </label>
                    <Switch
                      checked={isMtActive}
                      size='small'
                      onChange={(v) => {
                        if (!v) {
                          form.setFieldValue('microsoft_teams', null);
                        }
                        setIsMtActive(v);
                      }}
                      disabled={isView || !isActive}
                    />
                  </div>
                }
                rules={[
                  {
                    required: isMtActive,
                    message: `${t('manage_user.required_msg.ms_teams')}`
                  },
                  { type: 'url', warningOnly: true }
                ]}
              >
                <Input
                  prefix={<FaMicrosoft fill='#55A853' />}
                  placeholder={ `${t('manage_user.placeholder_ms_teams')}` }
                  disabled={isView || !isMtActive}  
                />
              </Form.Item>
            </div>
            <div className='col-span-1'>
              <Form.Item
                name='whatsapp_number'
                label={
                  <div>
                    <label className='mr-3'>
                      { t('manage_user.label_whatsapp') }
                    </label>
                    <Switch
                      checked={isWaActive}
                      size='small'
                      onChange={(v) => {
                        if (!v) {
                          form.setFieldValue('whatsapp_number', null);
                        }
                        setIsWaActive(v);
                      }}
                      disabled={isView || !isActive}
                    />
                  </div>
                }
                rules={[
                  {
                    required: isWaActive,
                    message: `${t('manage_user.required_msg.whatsapp')}`,
                  }
                ]}
              >
                <InputNumber
                  prefix={<FaWhatsappSquare fill='#55A853' />}
                  placeholder={ `${t('manage_user.placeholder_whatsapp')}` }
                  style={{ width: '100%' }}
                  disabled={isView || !isWaActive}
                />
              </Form.Item>
            </div>
          </div>
        </Card>
        <div className='flex flex-row justify-end mt-5 max-md:pb-48'>
          <Form.Item>
            <Row className='h-full'>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Button
                  className='cancel-green bg-[#E5EDE5] text-[#55A853] border-[#55A853]'
                  htmlType="button"
                  onClick={() => navigate(-1)}
                  disabled={isLoading}
                >
                  {t('general.cancel_btn') }
                </Button>
                {!isView && 
                  <Button
                    className='submit ml-5 bg-[#55A853] text-white'
                    htmlType="submit"
                    disabled={!isActive || isLoading}
                  >
                    {isLoading ? <Spin /> : t('general.submit_btn') || ''}
                  </Button>
                }
              </Col>
            </Row>
          </Form.Item>
        </div>
      </Form>
    </Spin>
  );
};

export default FormManageUser;