import { useTranslation } from 'react-i18next';
import { ListPayloadType, ReportFeedbackListType } from 'constant/Types/ReportFeedbackType';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Rate, DatePicker, Spin } from 'antd';
import { toast } from 'react-toastify';

import FeedbackBar from './FeedbackBar';
import DataTable from 'components/DataTable';
import { useGetSummaryFeedbackMutation, useGetFeedbackMutation } from 'api/Feedback';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';

const ReportFeedback = () => {
  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const [dataSummary, setDataSummary] = useState({
    series: [],
    categories: []
  });
  const [validYear, setValidYear] = useState(dayjs());
  const [data, setData] = useState({
    total: 0,
    reportFeedback: []
  });
  const [payload, setPayload] = useState<ListPayloadType>({
    lang: getLanguage(),
    page: 1,
    pageSize: 50,
    year: dayjs().format('YYYY'),
  });

  const [getFeedbackSummary, {
    error: errorGetFeedbackSummary,
    isError: isErrorGetFeedbackSummary,
    isLoading: isLoadingGetFeedbackSummary
  }] = useGetSummaryFeedbackMutation();

  const [getFeedback, {
    error: errorGetFeedback,
    isError: isErrorGetFeedback,
    isLoading: isLoadingGetFeedback
  }] = useGetFeedbackMutation();

  const columns: ColumnsType<ReportFeedbackListType> = [
    {
      title: 'Requestor',
      dataIndex: 'requestor',
      key: 'requestor',
      sorter: false,
      render: (data, record) => {
        return (<div>{data || '-'}</div>);
      }
    },
    {
      title: 'ER PIC',
      dataIndex: 'er_pic',
      key: 'er_pic',
      sorter: false,
      render: (data, record) => {
        return (<div>{data || '-'}</div>);
      }
    },
    {
      title: 'Permit Name',
      dataIndex: 'permit_name',
      key: 'permit_name',
      sorter: false,
      render: (data, record) => {
        return (<div>{data[getLanguage()] || '-'}</div>);
      }
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      sorter: false,
      render: (data, record) => {
        return (<div>{data || '-'}</div>);
      }
    }
  ];

  const expandable = {
    expandedRowRender: (record:any) => record?.feedbacks?.map((data:any) => {
      return <div key={data.key} className='flex justify-between mb-1 px-2'>
        <div className='my-auto'><p>{data.question}</p></div>
        <div><p><Rate value={data.rate} disabled /></p></div>
      </div>;
    })
  };

  const fetchFeedbackSummary = async () => {
    await getFeedbackSummary({year: validYear.format('YYYY')}).unwrap().then((resp) => {
      setDataSummary(resp);
    });
  };

  const fetchFeedback = async () => {
    let listReportFeedback = await getFeedback(payload).unwrap();
    listReportFeedback = {
      ...listReportFeedback,
      reportFeedback: listReportFeedback?.rows.map((el: any, idx: any) => ({ ...el, key: idx }))
    };
    setData(listReportFeedback);
  };

  const handleChangeValidYear = async (e: any) => {
    setValidYear(dayjs(e));
    setPayload({
      ...payload,
      year: dayjs(e).format('YYYY')
    });
  };

  useEffect(() => {
    fetchFeedbackSummary();
    fetchFeedback();
  },[]);

  useEffect(() => {
    fetchFeedbackSummary();
    fetchFeedback();
  }, [validYear]);

  useEffect(() => {
    fetchFeedback();
  }, [payload]);
  
  useEffect(() => {
    setPayload({
      ...payload,
      lang: getLanguage()
    });
  }, [getLanguage()]);

  useEffect(() => {
    if (isErrorGetFeedbackSummary) {
      const errorMsg: any = errorGetFeedbackSummary;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [errorGetFeedbackSummary, isErrorGetFeedbackSummary]);

  useEffect(() => {
    if (isErrorGetFeedback) {
      const errorMsg: any = errorGetFeedback;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [errorGetFeedback, isErrorGetFeedback]);

  return(
    <div className="w-full">
      <div className="flex gap-4 items-center mb-8">
        <span>{t('dashboard.year')}</span>
        <DatePicker
          className="font-normal"
          picker="year"
          onSelect={handleChangeValidYear}
          value={validYear}
          allowClear={false}
        />
      </div>
      <div className='bg-white rounded-lg font-bold pt-5 px-2'>
        <p className='text-[30px] font-normal text-center'>{t('report_feedback.title')}</p>
        {
          isLoadingGetFeedbackSummary 
            ? <div className='mt-5 flex justify-center'>
              <Spin></Spin>
            </div>
            : dataSummary?.categories?.length
              ? <FeedbackBar data={dataSummary}></FeedbackBar>
              : <div className='flex justify-center items-center h-full'>
                <div className='my-5 py-5'>
                  <img src='images/img_no_data.svg' alt='img-no-data'/>
                  <p className='mt-5 text-[#AAAAAA] text-[12px] text-center'>{t('general.no_data')}</p>
                </div>
              </div>
        }
        
      </div>

      <DataTable
        className=''
        columns={columns}
        data={data.reportFeedback}
        buttonConfig={[]}
        loading={isLoadingGetFeedback}
        totalData={data.total}
        payload={{
          payload: payload,
          setPayload: setPayload
        }}
        expandable={expandable}
        withoutSearch={true}
        scrollWidth={1000}
      />
    </div>
  );
};

export default ReportFeedback;