import { Button, Form, Input } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { FaWindowClose } from 'react-icons/fa';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';
import { GrDrag } from 'react-icons/gr';

const ObligationFormItem = ({
  restField = {},
  key = null,
  isOnlyOneData = false,
  removeObligation = () => {},
  addAction = () => {},
  name = null,
  removeAction = () => {},
  ref,
  dragHandleProps,
  snapshot = null,
  obligationNameDisabled = false,
  ...props
}: any) => {
  const { t } = useTranslation();
  const autoSize = {
    minRows: 1,
    maxRows: 10
  };

  return (
    <div 
      ref={ref} 
      className={`obligation bg-white rounded border-[#e4e7ea] border-2 ${(snapshot?.isDragging ? 'hovering' : '')}`}
      {...props}
    >
      <div className="bg-[#eceef0] p-3 flex items-center gap-4">
        {
          isOnlyOneData && <span {...dragHandleProps}>
            <GrDrag />
          </span>
        }
        <Form.Item
          {...restField}
          key={key}
          name={name !== null ? [name, 'name'] : 'name'}
          rules={[
            {
              required: true,
              message: `${t('permit_name.obligation.obligation_name')} ${t('general.required_field')}`,
            },
          ]}
          label={t('permit_name.obligation.obligation_name')}
          className="m-0 font-bold flex-grow"
        >
          <Input
            className="font-normal m-0"
            placeholder={t('permit_name.obligation.obligation_name_placeholder') || ''}
            disabled={obligationNameDisabled}
          />
        </Form.Item>
        {isOnlyOneData && (
          <FaWindowClose
            className="font-bold text-[#F00] text-lg cursor-pointer"
            onClick={removeObligation}
          />
        )}
      </div>
      <div className='p-5'>
        <div className="flex justify-between items-center mb-4">
          <Button
            className="bg-[#55A853] text-white font-bold flex gap-1 items-center"
            onClick={addAction}
          >
            <PlusOutlined className='font-bold' />
            <span>{t('permit_name.obligation.add_action')}</span>
          </Button>
        </div>
        <div className="flex justify-between items-center mb-4">
          <span className='font-semibold'>
            {t('permit_name.obligation.action')}
          </span>
        </div>
        <Form.List
          name={name !== null ? [name, 'actions'] : 'actions'}
        >
          {(actionFields) => (
            <div className="flex flex-col gap-2">
              {actionFields.map(
                (
                  {
                    key: actionKey,
                    name: actionName,
                    ...restActionField
                  },
                  actionIndex
                ) => (
                  <div key={actionKey} className="flex gap-4">
                    <Form.Item
                      {...restActionField}
                      name={[actionName, 'name']}
                      className="flex-grow"
                      rules={[
                        {
                          required: true,
                          message: `${t('permit_name.obligation.action')} ${t('general.required_field')}`,
                        },
                      ]}
                    >
                      <TextArea
                        autoSize={autoSize}
                        placeholder={t('permit_name.obligation.action_placeholder') || ''}
                      />
                    </Form.Item>
                    {actionFields.length > 1 && (
                      <DeleteFilled
                        className={
                          'text-[20px] text-[#9e9e9e] self-end pb-[5px] cursor-pointer m-auto'
                        }
                        onClick={() => removeAction(actionIndex)}
                      />
                    )}
                  </div>
                )
              )}
            </div>
          )}
        </Form.List>
      </div>
    </div>
  );
};

export default ObligationFormItem;