import Chart from 'react-apexcharts';

const Statistic = ({ data }:any) => {
  const state = {
    series:  data?.series,
    options: {
      chart:{
        stacked:true,
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        }
      },
      dataLabels: {
        style: {
          colors: ['black']
        }
      },
      xaxis: {
        show: false,
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        categories:data?.categories,
      },
      yaxis: {
        labels: {
          style: {
            colors: 'black',
            fontSize: '12px',
            fontFamily: 'Noto-Sans, sans-serif',
            fontWeight: 'bold',
          },
        },
      },
      grid: {
        show: false,
      },
      legend: {
        show: true
      },
      colors: ['#87C561', '#ED9D3F']
    },
  };
  
  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            type="bar"
            height={120}
            series={state.series}
            options={state.options}
          />
        </div>
      </div>
    </div>
  );
};

export default Statistic;