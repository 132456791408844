import { Base } from './Base';

const EmailNotification = Base.injectEndpoints({
  endpoints: (build) => ({
    listEmailNotification: build.mutation({
      query: (params: any) => ({
        url: 'notification-email-histories',
        method: 'GET',
        params
      })
    }),
  })
});

export const { 
  useListEmailNotificationMutation
} = EmailNotification;