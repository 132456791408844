import { ListPayloadType } from 'constant/Types/PermitRequest';
import { Base } from './Base';
import { 
  PermitRequestApprove, 
  PermitRequestDraft, 
  PermitRequestPickup, 
  PermitRequestProcess, 
  PermitRequestReject, 
  PermitRequestResubmission, 
  PermitRequestResubmit, 
  PermitRequestReturn, 
  PermitRequestRevise, 
  PermitRequestSubmit,
  PermitRequestReferencePermit,
  IPermitRequestShares
} from 'constant/Types/PermitRequestType';

const PermitRequest = Base.injectEndpoints({
  endpoints: (build) => ({
    listPermitRequestPagination: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/permit-request',
        method: 'GET',
        params
      })
    }),
    listMyActivity: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/permit-request/my-activities',
        method: 'GET',
        params
      })
    }),
    detailPermitRequest: build.mutation({
      query: (id: any) => ({
        url: `/permit-request/detail/${id}`,
        method: 'GET'
      })
    }),
    postPermitRequestDraft: build.mutation({
      query: (body: PermitRequestDraft) => ({
        url: '/permit-request/draft',
        method: 'POST',
        body
      })
    }),
    putPermitRequestDraft: build.mutation({
      query: (body: PermitRequestDraft) => ({
        url: `/permit-request/draft/${body.id}`,
        method: 'PUT',
        body
      })
    }),
    postPermitRequestSubmit: build.mutation({
      query: (body: PermitRequestSubmit) => ({
        url: '/permit-request/submit',
        method: 'POST',
        body
      })
    }),
    postPermitRequestResubmit: build.mutation({
      query: (body: PermitRequestResubmit) => ({
        url: '/permit-request/resubmit',
        method: 'POST',
        body
      })
    }),
    postPermitRequestAccept: build.mutation({
      query: (id: any) => ({
        url: `/permit-request/accept/${id}`,
        method: 'POST'
      })
    }),
    postPermitRequestApprove: build.mutation({
      query: (body: PermitRequestApprove) => ({
        url: '/permit-request/approve',
        method: 'POST',
        body
      })
    }),
    postPermitRequestRevise: build.mutation({
      query: (body: PermitRequestRevise) => ({
        url: '/permit-request/revise',
        method: 'POST',
        body
      })
    }),
    postPermitRequestPickup: build.mutation({
      query: (body: PermitRequestPickup) => ({
        url: '/permit-request/pickup',
        method: 'POST',
        body
      })
    }),
    postPermitRequestReturn: build.mutation({
      query: (body: PermitRequestReturn) => ({
        url: '/permit-request/return',
        method: 'POST',
        body
      })
    }),
    postPermitRequestReject: build.mutation({
      query: (body: PermitRequestReject) => ({
        url: '/permit-request/reject',
        method: 'POST',
        body
      })
    }),
    postPermitRequestProcess: build.mutation({
      query: (body: PermitRequestProcess) => ({
        url: '/permit-request/process',
        method: 'POST',
        body
      })
    }),
    postPermitRequestResubmission: build.mutation({
      query: (body: PermitRequestResubmission) => ({
        url: '/permit-request/resubmission',
        method: 'POST',
        body
      })
    }),
    approvalPermission: build.mutation({
      query: (id: any) => ({
        url: `/permit-request/permission/${id}`,
        method: 'GET'
      })
    }),
    approvalBox: build.mutation({
      query: (id: any) => ({
        url: `/permit-request/approval-box/${id}`,
        method: 'GET'
      })
    }),
    getPic: build.mutation({
      query: (payload: any) => ({
        url: '/permit-request/get-pic',
        method: 'GET',
        params: payload?.payload
      })
    }),
    requiredDocumentTemplate: build.mutation({
      query: (id: any) => ({
        url: `/required-document-templates/by-permit/${id}`,
        method: 'GET'
      })
    }),
    postUploadRequiredDocs: build.mutation({
      query: (data: any) => ({
        url: '/permit-required-document-attachments',
        method: 'POST',
        body: data?.body,
      }),
    }),
    delUploadRequiredDocs: build.mutation({
      query: (id: any) => ({
        url: `/permit-required-document-attachments/${id}`,
        method: 'DELETE'
      }),
    }),
    postUploadRequesterDocs: build.mutation({
      query: (data: any) => ({
        url: '/permit-requested-documents',
        method: 'POST',
        body: data?.body,
      }),
    }),
    delUploadRequesterDocs: build.mutation({
      query: (id: any) => ({
        url: `/permit-requested-documents/${id}`,
        method: 'DELETE'
      }),
    }),
    postUploadProductDoc: build.mutation({
      query: (data: any) => ({
        url: '/product-doc-attachments',
        method: 'POST',
        body: data?.body,
      }),
    }),
    delUploadProductDoc: build.mutation({
      query: (id: any) => ({
        url: `/product-doc-attachments/${id}`,
        method: 'DELETE'
      }),
    }),
    getConversationById: build.mutation({
      query: (id: any) => ({
        url: `/permit-request-conversations/conversation/${id}`,
        method: 'GET'
      })
    }),
    postConversation: build.mutation({
      query: (body: any) => ({
        url: '/permit-request-conversations',
        method: 'POST',
        body
      })
    }),
    getMentionList: build.mutation({
      query: (data: any) => ({
        url: `/permit-request-conversations/mention/${data?.id}`,
        method: 'GET',
        params: data?.params
      })
    }),
    getHistory: build.mutation({
      query: (id: any) => ({
        url: `/permit-request-histories/by-permit/${id}`,
        method: 'GET'
      })
    }),
    assignPic: build.mutation({
      query: (data: any) => ({
        url: `/assign-pic/${data?.id}`,
        method: 'PATCH',
        body: data?.body
      })
    }),
    getLegendTracker: build.mutation({
      query: () => ({
        url: '/permit-request/legend-tracker',
        method: 'GET'
      })
    }),
    getTracker: build.mutation({
      query: (params) => ({
        url: '/permit-request/tracker',
        method: 'GET',
        params
      })
    }),
    getHastagList: build.mutation({
      query: (data: any) => ({
        url: '/permit-request-conversations/hastag',
        method: 'GET',
        params: data?.params
      })
    }),
    getLastNoFeedback: build.mutation({
      query: () => ({
        url: '/permit-request/last-no-feedback',
        method: 'GET'
      })
    }),
    putPermitReqReferencePermit: build.mutation({
      query: (body: PermitRequestReferencePermit) => ({
        url: '/permit-request/reference-permit/'+body.id,
        method: 'PUT',
        body
      })
    }),
    postPermitRequestShares: build.mutation({
      query: (body: IPermitRequestShares) => ({
        url: '/permit-request-shares',
        method: 'POST',
        body
      })
    }),
    getPermitRequestShares: build.mutation({
      query: (id: string) => ({
        url: `/permit-request-shares/by-permit-request/${id}`,
        method: 'GET'
      })
    }),
  })
});

export const { 
  useListPermitRequestPaginationMutation,
  useDetailPermitRequestMutation,
  usePostPermitRequestDraftMutation, 
  useRequiredDocumentTemplateMutation,
  usePostUploadRequiredDocsMutation,
  usePostUploadRequesterDocsMutation,
  useGetConversationByIdMutation,
  usePostConversationMutation,
  useGetMentionListMutation,
  useDelUploadRequiredDocsMutation,
  useDelUploadRequesterDocsMutation,
  usePostPermitRequestSubmitMutation,
  usePostPermitRequestApproveMutation,
  usePostPermitRequestReviseMutation,
  useGetHistoryMutation,
  useApprovalBoxMutation,
  useApprovalPermissionMutation,
  usePutPermitRequestDraftMutation,
  useGetPicMutation,
  usePostPermitRequestPickupMutation,
  usePostPermitRequestProcessMutation,
  usePostPermitRequestRejectMutation,
  usePostPermitRequestResubmissionMutation,
  usePostPermitRequestResubmitMutation,
  usePostPermitRequestReturnMutation,
  usePostUploadProductDocMutation,
  useDelUploadProductDocMutation,
  useAssignPicMutation,
  usePostPermitRequestAcceptMutation,
  useGetLegendTrackerMutation,
  useGetTrackerMutation,
  useGetHastagListMutation,
  useListMyActivityMutation,
  useGetLastNoFeedbackMutation,
  usePutPermitReqReferencePermitMutation,
  usePostPermitRequestSharesMutation,
  useGetPermitRequestSharesMutation
} = PermitRequest;
