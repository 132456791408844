import { 
  Button, 
  Image, 
  Modal, 
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { FileOutlined } from '@ant-design/icons';
import DataTable from 'components/DataTable';
import { ColumnsType } from 'antd/es/table';
import { ListPayloadType } from 'constant/Types/OptionType';
import moment from 'moment';
import { useGetAdjustmentHistoriesMutation } from 'api/ErPicPerformances';
import { downloadFileUrl } from 'utils/Utility';

const ModalHistoryAdjustment = (props: any) => {
  const { modalAction, detail } = props;
  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  const [getAdjustmentHistories, {isLoading}] = useGetAdjustmentHistoriesMutation();

  const [payload, setPayload] = useState<ListPayloadType>({
    page: 1,
    pageSize: 50,
  });
  const [data, setData] = useState<any>(null);

  const fetchAdjustmentHistories = async () => {
    let response = await getAdjustmentHistories({
      ...payload,
      id: detail.id,
    }).unwrap();
    setData(response);
  };

  useEffect(() => {
    if(detail?.id){
      fetchAdjustmentHistories();
    }
  }, [payload, detail?.id]);

  const handleClose = () => {
    modalAction.setModalFormOpen(false);
  };

  const columns: ColumnsType<any> = [
    {
      title: t('report_er_performance.request'),
      dataIndex: 'request_number',
      key: 'request_number',
      width: 150,
    },
    {
      title: t('report_er_performance.permit_name'),
      dataIndex: 'permit_name',
      key: 'permit_name',
      render: (data) => {
        return (<div>{data[getLanguage()] || '-'}</div>);
      }
    },
    {
      title: t('report_er_performance.adjustment_date'),
      dataIndex: 'created_at',
      key: 'created_at',
      width: 150,
      render: (data) => {
        return (<div>{moment(data).format('DD-MMM-YYYY') || '-'}</div>);
      }
    },
    {
      title: t('report_er_performance.reason'),
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: t('report_er_performance.score_before'),
      dataIndex: 'score_before',
      key: 'score_before',
      width: 120,
    },
    {
      title: t('report_er_performance.adjust_score'),
      dataIndex: 'adjust_score',
      key: 'adjust_score',
      width: 120,
    },
    {
      title: t('report_er_performance.evidance'),
      dataIndex: 'evidence',
      key: 'evidence',
      width: 100,
      render: (data, record) => {
        return (
          <div>
            {data && (data.type.startsWith('image/') ? (
              <div>
                <Image
                  width={50}
                  height={50}
                  src={`${process.env.REACT_APP_BASE_URL}images/${record?.attachment_id}`}
                  alt={data?.name}
                />
              </div>
            ) : (
              <FileOutlined 
                onClick={() => downloadFileUrl(data?.downloadUrl || '')} 
                className='cursor-pointer text-lg' 
              />
            ))}
          </div>
        );
      }
    },
  ];

  return (
    <Modal
      title={null}
      open={modalAction.modalFormOpen}
      footer={null}
      width={1000}
      onOk={handleClose}
      onCancel={handleClose}
    >
      <div className="bg-white rounded-lg">
        <p className='font-bold text-[16px]'>{t('report_er_performance.history_adjustment')}</p>
        <div className='flex gap-2 items-center'>
          <span className='font-bold'>{t('report_er_performance.er_pic_name')}</span>
          <span>{detail?.er_pic_name || ''}</span>
        </div>
        <DataTable
          tableName={'permit-monitoring-crm'}
          columns={columns}
          data={data?.rows || []}
          loading={isLoading}
          totalData={data?.total || 0}
          payload={{
            payload: payload,
            setPayload: setPayload
          }}
          scrollWidth={1000}
          withoutSearch={true}
        />
        <div className='flex justify-end'>
          <Button
            type='text'
            className='bg-white border-[#55A853] text-[#55A853] font-bold'
            onClick={handleClose}
          >
            {t('general.close') || ''}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalHistoryAdjustment;