import { encodeBase64, decodeObjAES } from 'utils/Encoder';

import {
  KEY_ME
} from 'constant';

const encryptedMeKey = encodeBase64(KEY_ME);

export const hasPermission = (name: string) => {
  const encryptedMe = localStorage.getItem(encryptedMeKey) || '';

  if (encryptedMe) {
    const user = decodeObjAES(encryptedMe);

    const strName = name
      .replaceAll('-', ' ')
      .replaceAll('/', '').toLowerCase();

    return {
      menu: user.permissions.some((v: any) => v?.menu.link.toLowerCase().replaceAll('-', ' ').replaceAll('/', '') === strName),
      can_view: user.permissions.some((v: any) => v?.menu.link.toLowerCase().replaceAll('-', ' ').replaceAll('/', '') === strName && v.can_view),
      can_update: user.permissions.some((v: any) => v?.menu.link.toLowerCase().replaceAll('-', ' ').replaceAll('/', '') === strName && v.can_update),
      can_create: user.permissions.some((v: any) => v?.menu.link.toLowerCase().replaceAll('-', ' ').replaceAll('/', '') === strName && v.can_create),
      can_delete: user.permissions.some((v: any) => v?.menu.link.toLowerCase().replaceAll('-', ' ').replaceAll('/', '') === strName && v.can_delete)
    };
  } else {
    return {
      menu: true,
      can_view: true,
      can_update: true,
      can_create: true,
      can_delete: true
    };
  }
};
