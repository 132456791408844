import React from 'react';
import { initializeApp } from 'firebase/app';
import { FirebaseProvider } from '@useweb/use-firebase';
import { getMessaging } from 'firebase/messaging';
import { getToken } from 'firebase/messaging';

import firebaseConfig from 'services/Firebase/firebase.config';

const firebaseApp = initializeApp(firebaseConfig);
let messaging: any;
try {
  messaging = getMessaging(firebaseApp);
} catch {

}

export const fetchToken = (setTokenInfo: any, tokenInfo: any) => {
  return getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY }).then((currentToken) => {
    if (currentToken) {
      setTokenInfo({ ...tokenInfo, currentToken: currentToken, isTokenFound: true, isFirstTime: false });
      return;
    }

    setTokenInfo({ ...tokenInfo, isTokenFound: false, isFirstTime: false });
  }).catch((err) => {
    setTokenInfo({ isTokenFound: false, currentToken: '', errToken: err, isFirstTime: false });
  });
};

export default function Firebase({ children }: any) {
  return (
    <FirebaseProvider
      firebaseConfig={firebaseConfig}
      firebaseApp={firebaseApp}
      envIsDev={process.env.NODE_ENV === 'development'}
      messaging={messaging}
    >
      {children}
    </FirebaseProvider>
  );
}
