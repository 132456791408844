import { Button, Card, DatePicker, Empty, Form, Input, Modal, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { OptionType } from 'constant/Types/OptionType';
import { useEffect, useState } from 'react';
import { useGetComplianceStatusMutation } from 'api/PermitMonitoring';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { toast } from 'react-toastify';
import Toast from 'components/Toast';
import DocumentSection from './DocumentSection';
import ActionPlanSection from './ActionPlanSection';
import { usePostComplianceMonitoringMutation } from 'api/ComplianceMonitoring';

const PermitTabComplianceMonitoring = (props: any) => {
  const { id, form, isEdit = true, detail, dataOptions = null } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { confirm } = Modal;

  const [complianceStatusList, {
    isLoading: isLoadingComplianceStatus
  }] = useGetComplianceStatusMutation();
  const [postComplianceMonitoring, {
    isLoading: isLoadingPostComplianceMonitoring
  }] = usePostComplianceMonitoringMutation();

  const [complianceStatus, setComplianceStatus] = useState<OptionType[]>([]);

  const fetchComplianceStatus = async (value: any) => {
    if (value.length > 2 || !value) {
      let data = await complianceStatusList({}).unwrap();
      const newDataComplianceStatus = data.map((el: any) => {
        if (el) {
          return {
            label: el?.value,
            value: el?.value
          };
        }
        return null;
      });

      setComplianceStatus(newDataComplianceStatus.filter((v: any) => v));
    }
  };

  const extractKeys = (obj: any, keys: string[]): any => {
    return keys.reduce((acc: any, key: string) => {
      if (obj.hasOwnProperty(key)) {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
  };

  const isEqualSubset = (obj1: any, obj2: any, keys: string[]): boolean => {
    const subset1 = extractKeys(obj1, keys);
    const subset2 = extractKeys(obj2, keys);
    return JSON.stringify(subset1) === JSON.stringify(subset2);
  };

  const onCancel = () => {
    const formValues = form.form.getFieldsValue();
    const keysToCompare: string[] = [
      'compliance_status', 
      'description', 
      'likelihood', 
      'impact', 
      'revised_target',
      'impact_to_the_business',
      'remarks'
    ];
  
    if (isEqualSubset(detail, formValues, keysToCompare)) {
      navigate(-1);
    } else {
      confirm({
        title: t('general.label_cancel_data'),
        icon: <ExclamationCircleFilled />,
        okText: t('manage_group.yes_btn'),
        okType: 'danger',
        cancelText: t('manage_group.no_btn'),
        onOk() {
          navigate(-1);
        },
      });
    }
  };

  const handleSubmit = async () => {
    const value = form.form.getFieldsValue();
    const submitForm = {
      permit_request_compliance: {
        permit_request_id: id,
        compliance_status: value?.compliance_status, 
        description: value?.description,
        likelihood: value?.likelihood, 
        impact: value?.impact, 
        revised_target: value?.revised_target ? value.revised_target.format('YYYY-MM-DD') : null,
        impact_to_business: value?.impact_to_business,
        remarks: value?.remarks
      }
    };

    await postComplianceMonitoring(submitForm).unwrap()
      .then(() => {
        toast.success(
          <Toast
            message={t('general.success_text')}
            detailedMessage={`${t('compliance_monitoring.success_message_create_permit')}`}
          />
        );
      })
      .catch(() => {
        toast.error(
          <Toast
            message={t('general.error_text')}
            detailedMessage={t('compliance_monitoring.error_message_create_permit')}
          />
        );
      });
  };

  const onSubmit = () => {
    form.form.validateFields().then(async () => {
      confirm({
        title: t('general.label_save_data'),
        icon: <ExclamationCircleFilled />,
        okText: t('manage_group.yes_btn'),
        okType: 'danger',
        cancelText: t('manage_group.no_btn'),
        onOk() {
          handleSubmit();
        },
      });
    }).catch(() => {
      toast.error(<Toast message={t('general.error_text')} detailedMessage={t('permit_monitoring.error_validate_form')} />);
    });
  };

  useEffect(() => {
    fetchComplianceStatus('');
  }, []);

  return(
    <div className='mb-[176px] md:mb-0'>
      <Card className='bg-[#FBFBFB] rounded-xl'>
        <div className='bg-[#FDF0D8] p-3 rounded-lg flex flex-row items-center'>
          <span className='font-bold text-base'>{ t('compliance_monitoring.compliance_permit') }</span>
        </div>
        <div className='grid grid-cols-2 gap-5 mt-5'>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='request_no'
              label={t('compliance_monitoring.request_no')}
            >
              <Input placeholder={t('compliance_monitoring.request_no_placeholder') || ''} disabled />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='permit_name'
              label={t('compliance_monitoring.permit_name')}
            >
              <Input placeholder={t('compliance_monitoring.permit_name_placeholder') || ''} disabled />
            </Form.Item>
          </div>
          <div className='col-span-2'>
            <Form.Item
              name='description'
              label={t('compliance_monitoring.description')}
              rules={[
                {
                  required: true,
                  message: `${t('compliance_monitoring.description')} ${t('general.required_field')}`,
                },
              ]}
            >
              <Input.TextArea
                style={{ height: 100, resize: 'none' }}
                placeholder={t('compliance_monitoring.description_placeholder') || ''}
                disabled={!isEdit}
              />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='status'
              label={t('compliance_monitoring.status')}
            >
              <Input placeholder={t('compliance_monitoring.status_placeholder') || ''} disabled />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='er_pic'
              label={t('compliance_monitoring.er_pic')}
            >
              <Input placeholder={t('compliance_monitoring.er_pic_placeholder') || ''} disabled />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='permit_holder'
              label={t('compliance_monitoring.permit_holder')}
            >
              <Input placeholder={t('compliance_monitoring.permit_holder_placeholder') || ''} disabled />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='permit_location'
              label={t('compliance_monitoring.permit_location')}
            >
              <Input placeholder={t('compliance_monitoring.permit_location_placeholder') || ''} disabled />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='sector'
              label={t('compliance_monitoring.sector')}
            >
              <Input placeholder={t('compliance_monitoring.sector_placeholder') || ''} disabled />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='submitted_date'
              label={t('compliance_monitoring.submitted_date')}
            >
              <DatePicker
                className='w-full'
                placeholder={t('compliance_monitoring.submitted_date_placeholder') || ''}
                format={'DD-MMM-YYYY'}
                disabled
              />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='original_target'
              label={t('compliance_monitoring.original_target')}
            >
              <DatePicker
                className='w-full'
                placeholder={t('compliance_monitoring.original_target_placeholder') || ''}
                format={'DD-MMM-YYYY'}
                disabled
              />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='revised_target'
              label={t('compliance_monitoring.revised_target')}
              rules={[
                {
                  required: true,
                  message: `${t('compliance_monitoring.revised_target')} ${t('general.required_field')}`,
                },
              ]}
            >
              <DatePicker
                className='w-full'
                placeholder={t('compliance_monitoring.revised_target_placeholder') || ''}
                format={'DD-MMM-YYYY'}
                disabled={!isEdit}
              />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='compliance_status'
              label={t('compliance_monitoring.compliance_status')}
              rules={[
                {
                  required: true,
                  message: `${t('compliance_monitoring.compliance_status')} ${t('general.required_field')}`,
                },
              ]}
            >
              <Select
                placeholder={t('compliance_monitoring.compliance_status_placeholder') || ''}
                options={complianceStatus}
                disabled={!isEdit}
                filterOption={false}
                onChange={(a: any) => form.form.setFieldValue('compliance_status', a)}
                notFoundContent={isLoadingComplianceStatus ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              />
            </Form.Item>
          </div>
          <div className='hidden md:block md:col-span-1 max-md:col-span-2'/>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='likelihood'
              label={t('compliance_monitoring.likelihood')}
              rules={[
                {
                  required: true,
                  message: `${t('compliance_monitoring.likelihood')} ${t('general.required_field')}`,
                },
              ]}
            >
              <Select
                placeholder={t('compliance_monitoring.likelihood_placeholder') || ''}
                options={dataOptions?.likelihood || []}
                disabled={!isEdit}
                filterOption={false}
                onChange={(a: any) => form.form.setFieldValue('likelihood', a)}
                notFoundContent={dataOptions?.isLoading ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              />
            </Form.Item>
          </div>
          <div className='md:col-span-1 max-md:col-span-2'>
            <Form.Item
              name='impact'
              label={t('compliance_monitoring.impact')}
              rules={[
                {
                  required: true,
                  message: `${t('compliance_monitoring.impact')} ${t('general.required_field')}`,
                },
              ]}
            >
              <Select
                placeholder={t('compliance_monitoring.impact_placeholder') || ''}
                options={dataOptions?.impact || []}
                disabled={!isEdit}
                filterOption={false}
                onChange={(a: any) => form.form.setFieldValue('impact', a)}
                notFoundContent={dataOptions?.isLoading ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              />
            </Form.Item>
          </div>
          <div className='col-span-2'>
            <Form.Item
              name='impact_to_business'
              label={t('compliance_monitoring.impact_to_the_business')}
              rules={[
                {
                  required: true,
                  message: `${t('compliance_monitoring.impact_to_the_business')} ${t('general.required_field')}`,
                },
              ]}
            >
              <Input.TextArea
                style={{ height: 100, resize: 'none' }}
                placeholder={t('compliance_monitoring.impact_to_the_business_placeholder') || ''}
                disabled={!isEdit}
              />
            </Form.Item>
          </div>
          <div className='col-span-2'>
            <Form.Item
              name='remarks'
              label={t('compliance_monitoring.remarks')}
            >
              <Input.TextArea
                style={{ height: 100, resize: 'none' }}
                placeholder={t('compliance_monitoring.remarks_placeholder') || ''}
                disabled={!isEdit}
              />
            </Form.Item>
          </div>
        </div>

        <div className='bg-[#d7d7d7] h-[1px] mb-4 mt-4'/>

        <div className="flex justify-between items-center">
          <Button
            className='cancel-green bg-[#E5EDE5] text-[#55A853] border-[#55A853]'
            htmlType="button"
            onClick={onCancel}
          >
            {t('general.cancel_btn') }
          </Button>
          <Button
            type="text"
            className="bg-[#55A853] text-white font-bold"
            htmlType="button"
            onClick={onSubmit}
            loading={isLoadingPostComplianceMonitoring}
          >
            {t('general.update_btn')}
          </Button>
        </div>

        <div className='my-8'>
          <ActionPlanSection
            id={id}
          />
        </div>

        <div className='my-8'>
          <DocumentSection id={id} />
        </div>
      </Card>
    </div>
  );
};

export default PermitTabComplianceMonitoring;