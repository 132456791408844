import { Base } from './Base';

const PermitGuidance = Base.injectEndpoints({
  endpoints: (build) => ({
    getListPermitGuidance: build.mutation({
      query: () => ({
        url: '/permit-guidances',
        method: 'GET',
      }),
    }),
    getPermitNameByPermitGuidanceId: build.mutation({
      query:(permit_type_id: string | '' = '') => ({
        url: `/permit-guidances/${permit_type_id}/permit-names`,
        method: 'GET'
      })
    })
  }),
});

export const {
  useGetListPermitGuidanceMutation,
  useGetPermitNameByPermitGuidanceIdMutation
} = PermitGuidance;
