import { Button, Form, Modal, Select, Spin, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { OptionType } from 'constant/Types/OptionType';
import moment from 'moment';
import { useGetResetDateMutation } from 'api/ErPicPerformances';
import { useWorklocSearchMutation } from 'api/Options';

export interface IModalFilterRef {
	fetchListResetDate: () => void;
}

const ModalFilter = forwardRef((props: any, ref) => {
  const { modalAction, filter, filterAction } = props;
  const { t } = useTranslation();
  const [locations, setLocations] = useState<OptionType[]>([]);
  const [selectOptions, setSelectOptions] = useState([]);

  const [resetDateMutation, {
    isLoading: isLoadingGetResetDate
  }] = useGetResetDateMutation();
  const [locationList, {
    isLoading: isLoadinglocation
  }] = useWorklocSearchMutation();

  const fetchListResetDate = async () => {
    let listResetDate = await resetDateMutation({}).unwrap();
    listResetDate = listResetDate.map((date: string) => ({
      label: moment(date).format('DD MMM YYYY'),
      value: date
    }));
    setSelectOptions(listResetDate);
  };

  const fetchLocations = async (value: any) => {
    if (value.length > 2 || !value) {
      const payload = {
        search: value ?? '',
        searchFields: 'name'
      };
      let { rows } = await locationList(payload).unwrap();
      const newDataWorklocation = rows.map((el: any) => {
        if (el) {
          return {
            label: el?.name,
            value: el?.name
          };
        }
        return null;
      });

      setLocations(newDataWorklocation.filter((v: any) => v.label));
    }
  };

  useEffect(() => {
    fetchListResetDate();
    fetchLocations('');
  }, []);

  useImperativeHandle(ref, () => ({
    fetchListResetDate
  }));

  return (
    <Modal
      title={null}
      open={modalAction.isModalOpen}
      footer={null}
      width={500}
      onOk={() => modalAction.setIsModalOpen(false)}
      onCancel={() => modalAction.setIsModalOpen(false)}
    >
      <div className="bg-white p-[10px] rounded-lg">
        <p className='font-bold text-[16px] mb-5'>
          {t('manage_group.filter_label')}
        </p>
        <Form layout="vertical">
          <div className='grid grid-cols-1 gap-1'>
            <div className='col-span-1 mb-3'>
              <Form.Item
                label={t('report_er_performance.reset_date')}
                className='font-bold'
              >
                <Select
                  allowClear
                  className='font-normal'
                  style={{ width: '100%' }}
                  onChange={(e) => filter.setFilter({ ...filter.filter, reset_date: e })}
                  value={filter.filter.reset_date}
                  filterOption={false}
                  placeholder={t('report_er_performance.reset_date_placeholder')}
                  notFoundContent={isLoadingGetResetDate ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                  options={selectOptions}
                  loading={isLoadingGetResetDate}
                />
              </Form.Item>
            </div>
            <div className='col-span-1 mb-3'>
              <Form.Item
                label={t('report_er_performance.pic_work_location')}
                className='font-bold'
              >
                <Select
                  mode="multiple"
                  allowClear
                  className='font-normal'
                  style={{ width: '100%' }}
                  options={locations}
                  placeholder={t('report_er_performance.pic_work_location_placeholder')}
                  onChange={(e) => filter.setFilter({...filter.filter, worklocation: e})}
                  value={filter.filter.worklocation}
                  onSearch={fetchLocations}
                  filterOption={false}
                  notFoundContent={isLoadinglocation ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                />
              </Form.Item>
            </div>
            <div className='grid grid-cols-2 gap-4 content-end mt-4'>
              <Button
                type='text'
                className='bg-white border-[#55A853] text-[#55A853] font-bold'
                onClick={filterAction.resetFilter}
                disabled={filterAction.isLoadingFilter}
              >
                {filterAction.isLoadingFilter ? <Spin></Spin> : t('manage_group.reset_btn') || ''}
              </Button>
              <Button
                type='text'
                className='bg-[#55A853] text-white font-bold'
                onClick={() => {
                  filterAction.applyFilter();
                  modalAction.setIsModalOpen(false);
                }}
                disabled={filterAction.isLoadingFilter}
              >
                {filterAction.isLoadingFilter ? <Spin></Spin> : t('manage_group.apply_btn') || ''}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
});

export default ModalFilter;