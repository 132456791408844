import { Modal, Form, Button, Input, Spin, ConfigProvider } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdApplicationProfileMutation, useEditApplicationProfileMutation } from 'api/ApplicationProfile';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { toast } from 'react-toastify';

const ApplicationProfileForm = (props: any) => {
  const {t} = useTranslation();
  const { openModal, setOpenModal, id, canEdit, fetchList } = props;
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const [getByIdAppProfile, {
    error: errorGetByIdAppProfile,
    isError: isErrorGetByIdAppProfile,
    isLoading: isLoadingGetByIdAppProfile
  }] = useIdApplicationProfileMutation();

  const [editAppProfile, {
    error: errorEditAppProfile,
    isError: isErrorEditAppProfile,
    isLoading: isLoadingEditAppProfile
  }] = useEditApplicationProfileMutation();

  const getDetail = async () => {
    await getByIdAppProfile(id).unwrap().then((resp:any) => {
      form.setFieldsValue(resp);
    });
  };

  const handleOk = async () => {
    const body = {
      id: id,
      app_setting: form.getFieldsValue()
    };

    await editAppProfile(body).unwrap().then((resp:any) => {
      setOpenModal(false);
      fetchList();
    });
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (id) {
      getDetail();
    }
  }, [id]);

  useEffect(() => {
    if (isErrorGetByIdAppProfile){
      const errorMsg:any = errorGetByIdAppProfile;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetByIdAppProfile]);
  
  useEffect(() => {
    if (isErrorEditAppProfile){
      const errorMsg:any = errorEditAppProfile;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorEditAppProfile]);

  return(
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#55A853',
        },
      }}
    >
      <Modal
        open={openModal}
        centered
        footer={null}
        width={628}
        onCancel={() => setOpenModal(false)}
      >
        <div className='font-bold text-[16px] mb-5'>
          { canEdit 
            ? t('application_profile.title_form_edit') 
            : t('application_profile.title_form_view')
          }
        </div>
        {
          isLoadingGetByIdAppProfile 
            ? <div className='flex justify-center items-center'><Spin></Spin></div>
            : <Form
              layout="vertical"
              onFinish={handleOk}
              autoComplete="off"
              requiredMark={false}
              form={form}
            >
              <Form.Item
                label={t('application_profile.label_key')}
                name="key"
                className='font-bold'
              >
                <Input
                  disabled
                  placeholder={t('application_profile.placeholder_key') || ''}
                  className='font-normal'
                />
              </Form.Item>
              <Form.Item
                label={t('application_profile.label_value')}
                name="value"
                className='mt-3 font-bold'
              >
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 10 }}
                  placeholder={t('application_profile.placeholder_value') || ''}
                  disabled={!canEdit}
                  className='font-normal'
                />
              </Form.Item>
              <Form.Item
                label={t('application_profile.label_desc')}
                name="description"
                className='mt-3 font-bold'
              >
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 10 }}
                  placeholder={t('application_profile.placeholder_desc') || ''}
                  disabled={!canEdit}
                  className='font-normal'
                />
              </Form.Item>
              <div className='text-center mt-5'>
                <Button
                  type='text'
                  className='mr-4 bg-[#fff] text-[#55A853] border-[#55A853]'
                  onClick={handleCancel}
                >
                  {t('general.cancel_btn')}
                </Button>
                {
                  canEdit && <Button
                    className={'bg-[#55A853] text-white'}
                    htmlType="button"
                    onClick={handleOk}
                    disabled={isLoadingEditAppProfile}
                  >
                    {isLoadingEditAppProfile ? t('general.loading_text') : t('general.update_btn')}
                  </Button>
                }
              </div>
            </Form> 
        }
      </Modal>
    </ConfigProvider>
  );
};

export default ApplicationProfileForm;