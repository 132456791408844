import { Modal, Form, Select, Empty, Spin, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { 
  usePermitNamesListMutation, 
  usePermitTypesListMutation, 
  useUserHrisListMutation,
  useProductDocsListMutation 
} from 'api/Options';
import { OptionType } from 'constant/Types/OptionType';
import { useEffect, useState } from 'react';
import Toast from 'components/Toast';
import { toast } from 'react-toastify';
import { usePostPermitInitiateMutation, usePutPermitInitiateMutation } from 'api/PermitInitiate';
import { transformError } from 'api/Base';

const ModalInitiatePermitRequest = (props:any) => {
  const { modalAction, detail, mode, fetchList } = props;
  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const { Option } = Select;
  const [form] = Form.useForm();
  const [permitNames, setPermitNames] = useState<OptionType[]>([]);
  const [permitTypes, setPermitTypes] = useState<OptionType[]>([]);
  const [referencePermits, setReferencePermits] = useState<OptionType[]>([]);
  const [userHrisData, setUserHrisData] = useState([]);
  const [userHris, setUserHris] = useState([]);
  const [defaultPermitTypes, setDefaultPermitTypes] = useState([]);
  const [defaultPermitNames, setDefaultPermitNames] = useState([]);

  const [permitNameList, {
    isLoading: isLoadingPermitNames
  }] = usePermitNamesListMutation();
  const [permitTypeList, {
    isLoading: isLoadingPermitTypes
  }] = usePermitTypesListMutation();
  const [userHrisList, {
    isLoading: isLoadingUserHris
  }] = useUserHrisListMutation();
  const [productDocsList, {
    isLoading: isLoadingProductDocsList
  }] = useProductDocsListMutation();
  const [postPermitInitiate, {
    error: errorPostPermitInitiate,
    isError: isErrorPostPermitInitiate,
    isSuccess: isSuccessPostPermitInitiate,
    isLoading: isLoadingPostPermitInitiate
  }] = usePostPermitInitiateMutation();
  const [putPermitInitiate, {
    error: errorPutPermitInitiate,
    isError: isErrorPutPermitInitiate,
    isSuccess: isSuccessPutPermitInitiate,
    isLoading: isLoadingPutPermitInitiate
  }] = usePutPermitInitiateMutation();

  const fetchPermitNames = async (value: any) => {
    if (value?.length > 2 || !value) {
      const payload = {
        page: 1,
        pageSize: 100,
        search: value,
        searchFields: 'name',
        processed_by: ''
      }; 
      let { rows } = await permitNameList(payload).unwrap();
      setDefaultPermitNames(rows);
      const newDataPermitName = rows.map((el: any) => {
        if (el) {
          return {
            label: el.name,
            value: el.id
          };
        }
        return null;
      });

      setPermitNames(newDataPermitName.filter((v: any) => v));
    }
  };

  const fetchPermitTypes = async (value: any) => {
    if (value?.length > 2 || !value) {
      const payload = {
        page: 1,
        pageSize: 100,
        search: value,
        processed_by: ''
      }; 
      let { rows } = await permitTypeList(payload).unwrap();
      setDefaultPermitTypes(rows);
      const newDataPermitType = rows.map((el: any) => {
        if (el) {
          return {
            label: el.name,
            value: el.id
          };
        }
        return null;
      });

      setPermitTypes(newDataPermitType.filter((v: any) => v));
    }
  };

  const fetchUserHris = async (value: any, isDetail: any) => {
    setUserHris([]);
    const payload = {
      filters: value ? `fullname@=${value}` : ''
    }; 
    let { data } = await userHrisList(payload).unwrap();
    const filteredData = data.filter((v: any) => v.actingStatus === 'ACTIVE' && !v.endDateSsis);
    setUserHrisData(filteredData);
    setUserHris(filteredData.map((el:any) => ({label: el?.fullName, value: el?.employeeId})));

    if (isDetail) {
      form.setFieldsValue({
        ...form,
        permit_holder_id: filteredData[0].employeeId,
        department_name: filteredData[0].departmentName
      });
    }
  };

  const fetchReferencePermit = async (value: any) => {
    setReferencePermits([]);
    const payload = {
      registration_number: value ?? '',
    }; 
    let data = await productDocsList(payload).unwrap();
    setReferencePermits([...data?.map((el:any) =>  ({value: el?.registration_number, id: el?.id, expired_date: el?.expired_date}))]);
  };

  const onChangeSelectPermitName = (e:any) => {
    form.setFieldsValue({
      ...form.getFieldsValue(), 
      permit_type_id: '',
    });
    const permitNameData: any = defaultPermitNames.find((v: any) => v.id === e);
    if (!form.getFieldValue('permit_type_id')) {
      fetchPermitTypes(permitNameData?.permit_type?.name);
      form.setFieldsValue({
        ...form.getFieldsValue(), 
        permit_type_id: permitNameData?.permit_type_id,
      });
    }
  };

  const onChangePermitType = (e:any) => {
    if (e) {
      const permitTypeData: any = defaultPermitTypes.find((v: any) => v.id === e);
      const newPermitType = permitTypeData.permit_names.map((el: any) => {
        if (el) {
          return {
            label: el.name,
            value: el.id
          };
        }
        return null;
      });

      setPermitNames(newPermitType.filter((v: any) => v));
    }
  };

  const onChangeSelectHris = (e: any) => {
    if (userHrisData.length) {
      const selectedData:any = userHrisData.filter((el:any) => el.employeeId === e);
      if (selectedData.length) {
        form.setFieldsValue({
          ...form.getFieldsValue(),
          department_name: selectedData[0].departmentName
        });
      }
    }
  };

  const handleCancel = () => {
    modalAction.setIsModalOpen(false);
  };

  const onFinish = async () => {
    let body:any = {
      permit_initiate: {
        permit_name_id: form.getFieldValue('permit_name_id') || null,
        permit_holder_id: form.getFieldValue('permit_holder_id') || null,
        permit_reference: form.getFieldValue('permit_reference') || null,
        permit_type_id: form.getFieldValue('permit_type_id') || null,
        department: form.getFieldValue('department_name') || null
      }
    };
    if (mode === 'edit'){
      body = {
        ...body,
        id: detail?.id
      };
      await putPermitInitiate(body).unwrap();
    } else {
      await postPermitInitiate(body).unwrap();
    }
  };

  const onFinishFailed = () => {
    toast.error(
      <Toast
        message={t('general.error_text')}
        detailedMessage={t(
          'manage_group.fill_form_validation_text'
        )}
      />
    );
  };

  useEffect(() => {
    fetchPermitNames('');
    fetchUserHris('', false);
    fetchReferencePermit('');
  }, []);

  useEffect(() => {
    form.resetFields();
    if (detail) {
      form.setFieldsValue({
        ...form,
        permit_name_id: detail?.permit_name_id || null,
        permit_type_id: detail?.permit_type_id || null,
        permit_reference: detail?.registration_number
          ? detail?.registration_number
          : detail?.permit_reference || null,
      });

      fetchUserHris(detail?.requestor || detail?.permit_holder?.display_name, true);
      fetchPermitTypes(detail?.permit_name?.permit_type?.name);
      fetchReferencePermit(detail?.permit_reference);
    }
  }, [modalAction.isModalOpen]);

  useEffect(() => {
    if (isSuccessPostPermitInitiate) {
      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('initiate_permit_request.success_message_submit')}`}
        />
      );
      handleCancel();
    }
  }, [isSuccessPostPermitInitiate]);

  useEffect(() => {
    if (isErrorPostPermitInitiate) {
      const errorMsg: any = errorPostPermitInitiate;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isErrorPostPermitInitiate]);

  useEffect(() => {
    if (isSuccessPutPermitInitiate) {
      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('initiate_permit_request.success_message_update')}`}
        />
      );
      handleCancel();
      fetchList();
    }
  }, [isSuccessPutPermitInitiate]);

  useEffect(() => {
    if (isErrorPutPermitInitiate) {
      const errorMsg: any = errorPutPermitInitiate;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isErrorPutPermitInitiate]);

  return(
    <Modal
      title={null}
      open={modalAction.isModalOpen}
      footer={null}
      width={500}
      onCancel={handleCancel}
      maskClosable={false}
    >
      <div className="bg-white p-[10px] rounded-lg">
        <p className='font-bold text-[16px] mb-5'>
          {t('initiate_permit_request.initiate_title')}
        </p>
        <Form 
          layout="vertical"
          requiredMark={false}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className='mb-3'>
            <Form.Item
              name='permit_name_id'
              label={t('permit_request.request_information.permit_name')}
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                showSearch
                placeholder={t('permit_request.request_information.permit_name_placeholder')}
                onChange={onChangeSelectPermitName}
                filterOption={false}
                onSearch={fetchPermitNames}
                notFoundContent={isLoadingPermitNames ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                disabled={mode === 'view'}
              >
                {permitNames.map(item => (
                  <Option key={item.value} value={item.value} label={item.label[getLanguage()]}>
                    <div>
                      {item.label[getLanguage()]}
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className='mb-3'>
            <Form.Item
              name='permit_type_id'
              label={t('permit_request.request_information.permit_type')}
              tooltip={t('permit_request.request_information.hint_select_processed_by')}
            >
              <Select
                allowClear
                showSearch
                onChange={onChangePermitType}
                placeholder={t('permit_request.request_information.permit_type_placeholder')}
                options={permitTypes}
                filterOption={false}
                onSearch={fetchPermitTypes}
                notFoundContent={isLoadingPermitTypes ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                disabled
              />
            </Form.Item>
          </div>
          <div className='mb-3'>
            <Form.Item
              name='permit_holder_id'
              label={t('initiate_permit_request.permit_holder_label')}
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                options={userHris}
                onSearch={(e) => {fetchUserHris(e, false);}}
                onChange={onChangeSelectHris}
                placeholder={isLoadingUserHris ? t('general.loading_text') : t('initiate_permit_request.permit_holder_placeholder')}
                filterOption={false}
                notFoundContent={isLoadingUserHris ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                disabled={mode === 'view'}
              />
            </Form.Item>
          </div>
          <div className='mb-3'>
            <Form.Item
              name='department_name'
              label={t('permit_request.request_information.department')}
            >
              <Input placeholder={t('permit_request.request_information.department') || ''} disabled />
            </Form.Item>
          </div>
          <div className='mb-3'>
            <Form.Item
              name='permit_reference'
              label={t('permit_request.request_information.reference_permit')}
            >
              <Select
                allowClear
                showSearch
                placeholder={t('permit_request.request_information.reference_permit_select_placeholder') || ''}
                filterOption={false}
                onSearch={fetchReferencePermit}
                options={referencePermits}
                notFoundContent={isLoadingProductDocsList ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                disabled={mode === 'view'}
              />
            </Form.Item>
          </div>
          <div className='text-center mt-10'>
            <Button
              type='text'
              className='bg-white border-[#55A853] text-[#55A853] font-bold mr-2'
              disabled={isLoadingPostPermitInitiate || isLoadingPutPermitInitiate}
              onClick={handleCancel}
            >
              {(isLoadingPostPermitInitiate || isLoadingPutPermitInitiate) ? <Spin></Spin> : t('general.cancel_btn') || ''}
            </Button>
            {
              (mode !== 'view') && <Button
                htmlType='submit'
                className='bg-[#55A853] text-white font-bold'
                disabled={isLoadingPostPermitInitiate || isLoadingPutPermitInitiate}
              >
                {(isLoadingPostPermitInitiate || isLoadingPutPermitInitiate) ? 
                  <Spin></Spin> : 
                  t('permit_request.modal_assign_to.btn_assign') || ''}
              </Button>
            }
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalInitiatePermitRequest;