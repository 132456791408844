import { Base } from './Base';

const Dashboard = Base.injectEndpoints({
  endpoints: (build) => ({
    blockDashboard: build.mutation({
      query: (params:any) => ({
        url: '/dashboards/block-dashboard',
        method: 'GET',
        params
      })
    }),
    permitByLocation: build.mutation({
      query: (params:any) => ({
        url: '/dashboards/permit-by-location',
        method: 'GET',
        params
      })
    }),
    issuerInstitution: build.mutation({
      query: (params:any) => ({
        url: '/dashboards/issuer-institutions',
        method: 'GET',
        params
      })
    }),
    byMonth: build.mutation({
      query: (params:any) => ({
        url: '/dashboards/by-month',
        method: 'GET',
        params
      })
    }),
    permitTier: build.mutation({
      query: (params:any) => ({
        url: '/dashboards/permit-tier',
        method: 'GET',
        params
      })
    }),
    permitBySector: build.mutation({
      query: (params:any) => ({
        url: '/dashboards/permit-by-sector',
        method: 'GET',
        params
      })
    }),
    complianceStatus: build.mutation({
      query: (params:any) => ({
        url: '/dashboards/compliance-status',
        method: 'GET',
        params
      })
    }),
    complianceActivity: build.mutation({
      query: (params:any) => ({
        url: '/dashboards/compliance-activities',
        method: 'GET',
        params
      })
    }),
    getAlignment: build.mutation({
      query: (wokLocCode:any) => ({
        url: '/dashboards/alignments/'+wokLocCode,
        method: 'GET'
      })
    }),
    complianceStatusPerType: build.mutation({
      query: (params:any) => ({
        url: '/dashboards/compliance-status-per-type',
        method: 'GET',
        params
      })
    }),
    companies: build.mutation({
      query: (params:any) => ({
        url: '/dashboards/companies',
        method: 'GET',
        params
      })
    }),
  })
});

export const { 
  useBlockDashboardMutation,
  usePermitByLocationMutation,
  useIssuerInstitutionMutation,
  useByMonthMutation,
  useComplianceActivityMutation,
  useComplianceStatusMutation,
  usePermitBySectorMutation,
  usePermitTierMutation,
  useGetAlignmentMutation,
  useComplianceStatusPerTypeMutation,
  useCompaniesMutation
} = Dashboard;