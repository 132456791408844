import { ListPayloadType } from 'constant/Types/OptionType';
import { Base } from './Base';
import { PermitNameSubmit } from 'constant/Types/PermitName';

const PermitName = Base.injectEndpoints({
  endpoints: (build) => ({
    getPermitNames: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/permit-names/view',
        method: 'GET',
        params
      })
    }),
    detailPermitName: build.mutation({
      query: (id: any) => ({
        url: `/permit-names/detail/${id}`,
        method: 'GET'
      })
    }),
    detailPermitNameObligations: build.mutation({
      query: (id: any) => ({
        url: `/permit-name-obligations/by-permit-name/${id}`,
        method: 'GET'
      })
    }),
    detailPermitNameObligation: build.mutation({
      query: (id: any) => ({
        url: `/permit-name-obligations/detail/${id}`,
        method: 'GET'
      })
    }),
    postPermitName: build.mutation({
      query: (body: PermitNameSubmit) => ({
        url: '/permit-names',
        method: 'POST',
        body
      })
    }),
    postPermitNameObligation: build.mutation({
      query: (body: any) => ({
        url: '/permit-name-obligations',
        method: 'POST',
        body
      })
    }),
    putPermitName: build.mutation({
      query: (body: PermitNameSubmit) => ({
        url: `/permit-names/${body.id}`,
        method: 'PUT',
        body
      })
    }),
    putSortObligation: build.mutation({
      query: (body: any) => ({
        url: '/permit-name-obligations/update-sort',
        method: 'PUT',
        body
      })
    }),
    putPermitNameObligation: build.mutation({
      query: (data: {id?: string, body: any}) => ({
        url: `/permit-name-obligations/${data.id}`,
        method: 'PUT',
        body: data.body
      })
    }),
    postTemplateAttachment: build.mutation({
      query: (data: any) => ({
        url: '/required-document-template-attachments',
        method: 'POST',
        body: data?.body,
      }),
    }),
    delTemplateAttachment: build.mutation({
      query: (id: any) => ({
        url: `/required-document-template-attachments/${id}`,
        method: 'DELETE'
      }),
    }),
    delDocumentTemplate: build.mutation({
      query: (body: any) => ({
        url: `/required-document-templates/${body.id}`,
        method: 'DELETE',
        body
      }),
    }),
    delObligation: build.mutation({
      query: (id: any) => ({
        url: `/permit-name-obligations/${id}`,
        method: 'DELETE',
      }),
    }),
  })
});

export const { 
  useDetailPermitNameMutation,
  useDetailPermitNameObligationsMutation,
  useDetailPermitNameObligationMutation,
  usePostPermitNameMutation,
  usePostPermitNameObligationMutation,
  usePutPermitNameMutation,
  usePutSortObligationMutation,
  usePutPermitNameObligationMutation,
  usePostTemplateAttachmentMutation,
  useDelTemplateAttachmentMutation,
  useDelDocumentTemplateMutation,
  useDelObligationMutation,
  useGetPermitNamesMutation
} = PermitName;