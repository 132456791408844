import Chart from 'react-apexcharts';

const IssuerInstitution = ({ data }:any) => {
  const state = {
    series:  data?.series,
    options: {
      chart:{
        stacked:true,
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        }
      },
      fill: {
        opacity: 1,
        type: 'solid',
        colors: ['#ED9D3F']
      },
      dataLabels: {
        style: {
          colors: ['black']
        }
      },
      xaxis: {
        labels:{
          show: true
        },
        categories:data?.categories,
      },
      yaxis: {
        labels: {
          style: {
            colors: 'black',
            fontSize: '12px',
            fontFamily: 'Noto-Sans, sans-serif',
            fontWeight: 'bold',
          },
        },
      },
      grid: {
        show: true,
        borderColor: '#90A4AE',
        strokeDashArray: 0,
        xaxis: {
          lines: {
            show: false
          }
        },   
        yaxis: {
          lines: {
            show: false
          }
        },  
        row: {
          colors: undefined,
          opacity: 0.5
        },  
        column: {
          colors: undefined,
          opacity: 0.5
        },  
        padding: {
          left: 30
        },
      },
      legend: {
        show: false
      }
    },
  };
  
  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            type="bar"
            height={data?.categories?.length * 63}
            series={state.series}
            options={state.options}
          />
        </div>
      </div>
    </div>
  );
};

export default IssuerInstitution;