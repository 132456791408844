import Chart from 'react-apexcharts';

const FeedbackBar = ({ data }:any) => {
  const state = {
    options: {
      chart: {
        id: 'feedback_bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
          columnWidth: 50
        }
      },
      fill: {
        opacity: 1,
        type: 'solid',
        colors: ['#ED9D3F']
      },
      dataLabels: {
        style: {
          colors: ['black']
        }
      },
      xaxis: {
        labels: {
          show: true,
          rotate: -30,
          rotateAlways: true,
          style: {
            colors: 'black',
            fontSize: '12px',
            fontFamily: 'Noto-Sans, sans-serif',
            fontWeight: 'bold',
          },
        },
        categories: data?.categories
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            colors: 'black',
            fontSize: '12px',
            fontFamily: 'Noto-Sans, sans-serif',
            fontWeight: 'bold',
          },
          formatter: function(val:any) {
            return val.toFixed(0);
          }
        },
      },
      legend: {
        show: false
      }
    },
    series: data?.series
  };
  
  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={state.options}
            series={state.series}
            type="bar"
            height={300}
          />
        </div>
      </div>
    </div>
  );
};

export default FeedbackBar;