import { CalendarOutlined } from '@ant-design/icons';
import { Avatar, Button, Spin } from 'antd';
import moment from 'moment-timezone';
import { useUserLogMutation } from 'api/User';
import { ListPayloadUserLogType } from 'constant/Types/UserType';
import { useEffect, useState } from 'react';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { store } from 'store';
import { useLocation, useNavigate } from 'react-router';
import { hasPermission } from 'utils/Permission';

const UserLog = () => {
  const {t} = useTranslation();
  const user = store.getState().auth.user;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [payloadMobile, setPayloadMobile] = useState<ListPayloadUserLogType>({
    page: 1,
    pageSize: 50,
    sort: '-updated_at',
  });
  const [data, setData] = useState({
    range_days: '-',
    user_log: {
      page: 0,
      pageSize: 50,
      rows: [],
      total: 0,
      totalPages: 0
    }
  });
  const [getUserLog, {
    error: errorGetUserLog,
    isError: isErrorGetUserLog,
    isLoading: isLoadingGetUserLog
  }] = useUserLogMutation();

  const fetchListMobile = async () => {
    let convertPayload:any = payloadMobile;
    let newPayload:any = {};
    for (const key in convertPayload) {
      if (Array.isArray(convertPayload[key])) {
        if (convertPayload[key].length) {
          newPayload[key] = convertPayload[key];
        }
      } else if (convertPayload[key]){
        newPayload[key] = convertPayload[key];
      }
    }
    
    let listUserLog = await getUserLog(newPayload).unwrap();
    const newUserLog = listUserLog?.user_log?.rows.map((el: any, idx: any) => ({...el, prior: el?.key, key: idx }));
    const concatNewUserLog = data?.user_log?.rows.concat(newUserLog);

    listUserLog = {
      ...listUserLog, 
      user_log: {
        ...listUserLog?.user_log,
        rows: concatNewUserLog
      }
    };
  
    setData(listUserLog);
  };

  useEffect(() => {
    const paths = pathname.split('/');
    if (!hasPermission(paths[1]).can_view) {
      navigate('/403');
    }
  }, []);

  useEffect(() => {
    fetchListMobile();
  }, [payloadMobile]);

  useEffect(() => {
    if (isErrorGetUserLog){
      const errorMsg:any = errorGetUserLog;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorGetUserLog]);

  return isLoadingGetUserLog 
    ? (
      <div className='flex justify-center'>
        <Spin></Spin>
      </div>
    ) 
    : (
      <div className='md:hidden'>
        <div className="flex">
          <div className="bg-white px-2 pt-1 pb-2 rounded-lg mx-2">
            <CalendarOutlined className='text-[20px] text-[#55A853]' />
          </div>
          <div className='my-auto'>
            <p className='text-[12px] text-[#3D81DB] bg-[#3D81DB33]/20 py-[1px] px-3 rounded-lg border-2 border-[#3D81DB] ml-2'>
              {data?.range_days || '-'}
            </p>
          </div>
        </div>
        {
          data?.user_log?.rows.map((el:any, idx:any) => (
            <div key={idx} className='bg-white px-2 py-5 my-3 rounded-lg'>
              <div className='grid grid-cols-6'>
                <div className='m-auto col-span-1'>
                  {
                    el?.picture 
                      ? <Avatar size={40} src={el?.picture} />
                      : (<Avatar size={40}>{el?.display_name?.charAt(0)}</Avatar>)
                  }
                </div>
                <div className='col-span-4 pl-4'>
                  <p className='font-bold text-[14px] mb-1'>{el?.display_name}</p>
                  <p className='text-[14px] mb-1'>{el?.department_name || '-'}</p>
                  <div className='flex'>
                    <div className={`${el?.prior === 'P1' ? 'bg-[#55A853]' : el?.prior === 'P2' ? 'bg-[#FEA24C]' : 'bg-[#AAAAAA]'} w-[13px] rounded-xl mr-1 my-[3px]`}></div>
                    <p className='text-[12px] text-[#808080] my-auto'>
                      <span className='text-[#AAAAAA]'>{t('user_log.last_online')} </span>
                      <span className='text-[#BC31AE]'>{el?.last_login ? moment(el?.last_login).tz(user?.timezone?.identifier).utc(true).format('DD-MMM-YYYY HH:mm') : '-'}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
        {
          data?.user_log?.rows.length !== data?.user_log?.total ? 
            (
              <Button
                type='text'
                className='bg-[#55A853] text-white px-10 w-full mt-4'
                disabled={isLoadingGetUserLog}
                onClick={() => {
                  setPayloadMobile({...payloadMobile, page: payloadMobile.page + 1});
                }}
              >
                {isLoadingGetUserLog ? <Spin /> : t('general.load_more') || ''}
              </Button>
            ) : 
            (<div></div>)
        }
      </div>
    );
};

export default UserLog;