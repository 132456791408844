import { Steps, ConfigProvider, Spin } from 'antd';
import { useTranslation } from 'react-i18next';  
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { store } from 'store';
import icon_permit_request from 'icon/icon_permit_request.svg';
import icon_permit_follow_up from 'icon/icon_permit_follow_up.svg';
import icon_permit_tracker from 'icon/icon_permit_tracker.svg';
import icon_permit_acceptance from 'icon/icon_permit_acceptance.svg';

import { useGetHistoryMutation } from 'api/PermitRequest';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';

const History = (props: any) => {
  const { id, triggerChange} = props;
  const user = store.getState().auth.user;
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [histories, setHistories] = useState<any[]>([]);
  
  const [getListHistory, {
    error,
    isError,
    isLoading
  }] = useGetHistoryMutation();

  const items_first = [
    {
      title: <img alt='icon_permit_request' src={icon_permit_request} className='w-[30px] md:w-[50px] mb-1'></img>,
      description: <p className='text-black font-bold text-[13px]'>Permit Request</p>,
    },
    {
      title: <img alt='icon_permit_follow_up' src={icon_permit_follow_up} className='w-[30px] md:w-[50px] mb-1'></img>,
      description: <p className='text-black font-bold text-[13px]'>Permit Follow-up</p>,
    },
    {
      title: <img alt='icon_permit_tracker' src={icon_permit_tracker} className='w-[30px] md:w-[50px] mb-1'></img>,
      description: <p className='text-black font-bold text-[13px]'>Permit Tracker</p>,
    },
    {
      title: <img alt='icon_permit_acceptance' src={icon_permit_acceptance} className='w-[30px] md:w-[50px] mb-1'></img>,
      description: <p className='text-black font-bold text-[13px]'>Permit Acceptance</p>,
    },
  ];

  const fetchListHistory = async () => {
    await getListHistory(id).unwrap().then((resp: any) => {
      let current = 0;
      while(resp?.steps[current+1]?.histories?.length){
        current++;
      }
      setCurrentStep(current);

      let histories = [];
      for(let i = 0; i < resp?.histories?.length; i++){
        histories.push({
          title: <div className='mx-5 mt-2'>
            <p className='text-[13px] text-[#55A853] font-bold leading-5'>{moment(resp?.histories[i].created_at).tz(user?.timezone?.identifier).utc(true).format('DD MMM YYYY HH:mm')}</p>
            <p className='text-[13px] font-bold leading-5'>{resp?.histories[i].message}</p>
            <p className='text-[13px] mb-3 leading-5'>{t('permit_request.history_conversation.comment_title')} : {resp?.histories[i]?.comment || '-'}</p>
          </div>
        });
      }

      setHistories(histories);
    });
  };

  useEffect(() => {
    if (id) {
      fetchListHistory();
    }
  }, [triggerChange, id, user]);

  useEffect(() => {
    if (isError) {
      const errorMsg:any = error;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isError]);

  return(
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#55A853',
        },
      }}
    >
      <Spin spinning={isLoading} className='px-5 pt-3 pb-5 bg-white'>
        <div className="px-5 pt-7 pb-3 bg-[#F5F5F5] rounded-xl">
          <Steps 
            current={currentStep} 
            size="small" 
            labelPlacement="vertical" 
            items={items_first} 
            className='md:mt-[80px] first_steps'
          />
        </div>
        <Steps 
          current={histories.length} 
          progressDot
          direction="vertical" 
          items={histories} 
          className='px-10 mt-[30px] second_steps'/>
      </Spin>
    </ConfigProvider>
  );
};
    
export default History;