import { useTranslation } from 'react-i18next';

const LoadingComponent = () => {
  const { t } = useTranslation();
  return (
    <div className='bg-[#55A853] h-screen flex justify-center items-center'>
      <p className='text-white text-[25px] font-bold'>{t('general.loading_text')}</p>
    </div>
  );
};

export default LoadingComponent;
