import { Base } from './Base';
import { BodyPostPermitRequestIndicator, PayloadPermitRequestByWorkLocation } from 'constant/Types/PermitRequestReport';

const PermitRequest = Base.injectEndpoints({
  endpoints: (build) => ({
    listPermitRequestReportIndicator: build.mutation({
      query: () => ({
        url: '/permit-request-reports/indicator',
        method: 'GET'
      })
    }),
    getPermitRequestReportIndicatorById: build.mutation({
      query: (id: string) => ({
        url: '/permit-request-reports/by-permit-request/'+id,
        method: 'GET'
      })
    }),
    postPermitRequestReportIndicator: build.mutation({
      query: (body: BodyPostPermitRequestIndicator) => ({
        url: '/permit-request-reports',
        method: 'POST',
        body
      })
    }),
    listPermitRequestReportByWorkLocation: build.mutation({
      query: (params: PayloadPermitRequestByWorkLocation) => ({
        url: '/permit-request-reports/by-work-location/'+params.id,
        method: 'GET',
        params
      })
    }),
  })
});

export const { 
  useListPermitRequestReportIndicatorMutation,
  useGetPermitRequestReportIndicatorByIdMutation,
  usePostPermitRequestReportIndicatorMutation,
  useListPermitRequestReportByWorkLocationMutation
} = PermitRequest;
