import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/Hooks';
import ViewOthers from './ViewOthers';
import ViewErPic from './ViewErPic';
import { useLocation, useNavigate } from 'react-router';
import { hasPermission } from 'utils/Permission';
import { MdListAlt, MdOutlineViewTimeline } from 'react-icons/md';

const PermitTracker = () => {
  const { user } = useAppSelector((state) => state.auth);
  const [isVip, setIsVip] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState<string>('table');

  const setView = () => {
    const found = user?.roles?.findIndex((el: any) => el?.name?.toLowerCase()?.includes('er '));
    const isAdminViewData = user?.roles?.some((el: any) => el?.is_vip);
    setIsVip(found !== -1 || isAdminViewData);
  };

  useEffect(() => {
    const paths = pathname.split('/');
    if (!hasPermission(paths[1]).can_view) {
      navigate('/403');
    }
    setView();
  });

  return (
    <div>
      <div className='max-md:hidden flex gap-2 mb-4'>
        <div 
          onClick={() => setViewMode('table')} 
          className={`p-1 ${viewMode === 'table' ? 'bg-green-200' : 'bg-white'} rounded cursor-pointer`}>
          <MdListAlt className='text-4xl'/>
        </div>
        <div 
          onClick={() => setViewMode('gantt')} 
          className={`p-1 ${viewMode === 'gantt' ? 'bg-green-200' : 'bg-white'} rounded cursor-pointer`}>
          <MdOutlineViewTimeline className='text-4xl'/>
        </div>
      </div>
      <div>
        <div className={`${viewMode === 'gantt' ? 'max-md:hidden' : 'hidden'}`}><ViewErPic /></div>
        <div className={`${viewMode === 'table' ? 'block' : 'md:hidden'}`}><ViewOthers /></div>
      </div>
    </div>
  );
};

export default PermitTracker;