import { DropdownTypeItems } from './Types/DropdownType';
import { OptionType } from './Types/OptionType';
import { SelectTypeItems } from './Types/SelectType';

const DB_CREDENTIAL_TOKEN: string = 'db_credential_token';
const DB_CREDENTIAL_IS_FRESH_INSTALL: string = 'db_credential_fresh_install_state';
const DB_CREDENTIAL_REFRESH_TOKEN: string = 'db_credential_refresh_token';
const DB_CREDENTIAL_USER: string = 'db_credential_user';
const REDUX_ROOT: string = 'persist:root';
const KEY_ME: string = 'me';
enum EMPTY {
  STRING = '',
  NUMBER = 0
}

enum VARIANT {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning'
}

enum POSITION {
  TOP = 'top',
  CENTER = 'center'
}

enum MAX {
  ELLIPSIS = 25
}

const LANGOPT: DropdownTypeItems = [
  { label: 'English', key: 'en' },
  { label: 'Indonesia', key: 'id' }
];

const ENTRIESLIST: SelectTypeItems = [
  { label: '10', value: 10 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
  { label: '250', value: 250 }
];

const STATUS: SelectTypeItems = [
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
];

const SORT_BY_PERMIT_TRACKER: SelectTypeItems = [
  { label: 'Last Update', value: '-updated_at' },
  { label: 'Oldest', value: 'updated_at' },
  { label: 'Permit Priority', value: 'permit_name.priority' },
  { label: 'Expired Date', value: 'expired_date' },
];

const PERMIT_EDIT_PERMISSION = [
  {
    key: 'Group A',
    permit_info: false,
    list_of_doc: false
  },
  {
    key: 'Group B',
    permit_info: true,
    list_of_doc: true
  },
  {
    key: 'Group C',
    list_of_doc: false,
    permit_info: true
  }
];

enum DEFAULT {
  ERROR = 'Failed connect to server.'
}

const TYPEUPLOAD:Array<string> = ['png', 'jpg', 'jpeg', 'tiff', 'pdf', 'dxf', 'dwg', 'shp', 'rar', 'zip', 'xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx'];

enum MAXFILESIZE {
  VAL_ON_BYTE = 10000000,
  CURRENT_MEMORY = 'MB',
  VAL_CONVERT_BYTE_TO_CURRENT = 10
}

const CLUSTER_TYPE = [
  {
    label: 'Site',
    value: 'Site'
  },
  {
    label: 'Cluster',
    value: 'Cluster'
  }
];

const PERMIT_TIER_TYPE = [
  {
    label: 'High',
    value: 'High'
  },
  {
    label: 'Middle',
    value: 'Middle'
  },
  {
    label: 'Low',
    value: 'Low'
  }
];

const STATUS_OBLIGATION = [
  {
    label: 'In Progress',
    value: 'In Progress'
  },
  {
    label: 'Completed',
    value: 'Completed'
  }
];

const DOMAIN_LIST = [
  {
    label: 'banpuindo.co.id',
    value: 'banpuindo.co.id'
  },
  {
    label: 'banpu.co.th',
    value: 'banpu.co.th'
  },
  {
    label: 'banpu.com.cn',
    value: 'banpu.com.cn'
  }
];

const LIST_ROLE_CRM = [
  'CRM Head',
  'CRM Admin',
  'CRM'
];

const RISK_ASSESSMENT_OPTIONS: OptionType[] = [
  {
    label: 'Low',
    value: 'Low',
  },
  {
    label: 'Medium',
    value: 'Medium',
  },
  {
    label: 'High',
    value: 'High',
  },
  {
    label: 'Critical',
    value: 'Critical',
  },
];

const STATUS_COMPLIANCE_MONITORING_OPTIONS: OptionType[] = [
  {
    label: 'Drafting',
    value: 'Drafting',
  },
  {
    label: 'Preparation',
    value: 'Preparation',
  },
  {
    label: 'On Process',
    value: 'On Process',
  },
  {
    label: 'Completed',
    value: 'Completed',
  },
];

const COLOR_STATUS_COMPLIANCE_MONITORING = [
  {
    value: 'drafting',
    color: '#F7B648',
  },
  {
    value: 'completed',
    color: '#659711',
  },
  {
    value: 'preparation',
    color: '#FF7B31',
  },
  {
    value: 'on process',
    color: '#FF7B31',
  },
  {
    value: 'cancel',
    color: '#930B16',
  },
];

const DATA_COLOR_COMPLIANCE_STATUS = [
  {
    value: 'compliance',
    color: '#659711',
  },
  {
    value: 'compliance risk',
    color: '#F7B648',
  },
  {
    value: 'not compliance',
    color: '#930B16',
  },
];

const DATA_COLOR_HEALTH_STATUS_CRONJOB = [
  {
    value: 'health',
    color: '#659711',
  },
  {
    value: 'not health',
    color: '#930B16',
  },
];

const DATA_COLOR_STATUS_CRONJOB = [
  {
    value: 'active',
    color: '#659711',
  },
  {
    value: 'inactive',
    color: '#930B16',
  },
];

const DATA_COLOR_STATUS_CRONJOB_LOG = [
  {
    value: 'ok',
    color: '#659711',
  },
  {
    value: 'success',
    color: '#659711',
  },
  {
    value: 'error',
    color: '#930B16',
  },
];

const START_YEAR = 2023;
const NUM_OF_YEARS = 1;
const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const MONTHS_PER_YEAR = 12;
const QUARTERS_PER_YEAR = 4;
const MONTHS_PER_QUARTER = 12;
const NUM_OF_MONTHS = NUM_OF_YEARS * MONTHS_PER_YEAR;
const MAX_TRACK_START_GAP = 4;
const MAX_ELEMENT_GAP = 8;
const MAX_MONTH_SPAN = 8;
const MIN_MONTH_SPAN = 2;
const NUM_OF_TRACKS = 20;
const MAX_NUM_OF_SUBTRACKS = 5;

export {
  DB_CREDENTIAL_TOKEN,
  DB_CREDENTIAL_IS_FRESH_INSTALL,
  DB_CREDENTIAL_REFRESH_TOKEN,
  DB_CREDENTIAL_USER,
  REDUX_ROOT,
  KEY_ME,
  EMPTY,
  VARIANT,
  POSITION,
  MAX,
  DEFAULT,
  LANGOPT,
  ENTRIESLIST,
  STATUS,
  TYPEUPLOAD,
  MAXFILESIZE,
  PERMIT_EDIT_PERMISSION,
  START_YEAR,
  NUM_OF_YEARS,
  MONTH_NAMES,
  MONTHS_PER_YEAR,
  QUARTERS_PER_YEAR,
  MONTHS_PER_QUARTER,
  NUM_OF_MONTHS,
  MAX_TRACK_START_GAP,
  MAX_ELEMENT_GAP,
  MAX_MONTH_SPAN,
  MIN_MONTH_SPAN,
  NUM_OF_TRACKS,
  MAX_NUM_OF_SUBTRACKS,
  SORT_BY_PERMIT_TRACKER,
  CLUSTER_TYPE,
  PERMIT_TIER_TYPE,
  STATUS_OBLIGATION,
  DOMAIN_LIST,
  LIST_ROLE_CRM,
  RISK_ASSESSMENT_OPTIONS,
  STATUS_COMPLIANCE_MONITORING_OPTIONS,
  COLOR_STATUS_COMPLIANCE_MONITORING,
  DATA_COLOR_COMPLIANCE_STATUS,
  DATA_COLOR_HEALTH_STATUS_CRONJOB,
  DATA_COLOR_STATUS_CRONJOB,
  DATA_COLOR_STATUS_CRONJOB_LOG
};