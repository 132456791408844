import { ListPayloadType } from 'constant/Types/ComplianceMonitoring';
import { Base } from './Base';

const PermitType = Base.injectEndpoints({
  endpoints: (build) => ({
    getListComplianceMonitoring: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/permit-request-compliances',
        method: 'GET',
        params
      })
    }),
    getStatus: build.mutation({
      query: () => ({
        url: '/permit-request-compliances/status',
        method: 'GET'
      })
    }),
    getComplianceMatrix: build.mutation({
      query: () => ({
        url: '/compliance-matrixs',
        method: 'GET'
      })
    }),
    getDetailComplianceMonitoring: build.mutation({
      query: (id: any) => ({
        url: `/permit-request-compliances/by-permit-request/${id}`,
        method: 'GET'
      })
    }),
    getDetailAttachment: build.mutation({
      query: (id: any) => ({
        url: `/permit-request-compliance-attachments/by-permit-request/${id}`,
        method: 'GET'
      })
    }),
    getActionPlan: build.mutation({
      query: (id: any) => ({
        url: `/action-plans/by-permit-request/${id}`,
        method: 'GET'
      })
    }),
    getCommentByAction: build.mutation({
      query: (params: any) => ({
        url: '/action-plan-comments/by-action-plan',
        method: 'GET',
        params
      })
    }),
    postComplianceMonitoring: build.mutation({
      query: (data: any) => ({
        url: '/permit-request-compliances',
        method: 'POST',
        body: data,
      }),
    }),
    postAttachment: build.mutation({
      query: (body: any) => ({
        url: '/permit-request-compliance-attachments',
        method: 'POST',
        body
      })
    }),
    postActionPlan: build.mutation({
      query: (data: any) => ({
        url: '/action-plans',
        method: 'POST',
        body: data,
      }),
    }),
    postActionPlanComment: build.mutation({
      query: (data: any) => ({
        url: '/action-plan-comments',
        method: 'POST',
        body: data,
      }),
    }),
    putActionPlan: build.mutation({
      query: ({id, body}: {id: string, body: any}) => ({
        url: `/action-plans/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    putActionPlanCheck: build.mutation({
      query: ({id, body}: {id: string, body: any}) => ({
        url: `/action-plans/checked/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    delAttachment: build.mutation({
      query: (id: any) => ({
        url: `/permit-request-compliance-attachments/${id}`,
        method: 'DELETE'
      }),
    }),
    delActionPlan: build.mutation({
      query: (id: any) => ({
        url: `/action-plans/${id}`,
        method: 'DELETE',
      }),
    }),
    getDownloadExcelCompliance: build.mutation({
      query: (params: any) => ({
        url: '/permit-request-compliances/download_excel',
        method: 'GET',
        params
      })
    }),
  })
});

export const { 
  useGetListComplianceMonitoringMutation,
  useGetStatusMutation,
  useGetComplianceMatrixMutation,
  useGetDetailComplianceMonitoringMutation,
  useGetDetailAttachmentMutation,
  useGetActionPlanMutation,
  useGetCommentByActionMutation,
  usePostComplianceMonitoringMutation,
  usePostAttachmentMutation,
  usePostActionPlanMutation,
  usePostActionPlanCommentMutation,
  usePutActionPlanMutation,
  usePutActionPlanCheckMutation,
  useDelAttachmentMutation,
  useDelActionPlanMutation,
  useGetDownloadExcelComplianceMutation,
} = PermitType;