import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router';
import { EditFilled } from '@ant-design/icons';

import SimpleDataTable from 'components/SimpleDataTable';
import { useListQuery } from 'api/Cluster';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { hasPermission } from 'utils/Permission';
import ModalForm from './ModalForm';
import { ClusterListType } from 'constant/Types/Cluster';
import { WorkLocation } from 'constant/Types/WorkLocation';
import { usePostClusterMutation, usePutClusterMutation } from 'api/Cluster';

const Cluster = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const [canUpdate, setCanUpdate] = useState(false);
  const [canCreate, setCanCreate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState('');
  const columns: ColumnsType<ClusterListType> = [
    {
      title: t('clusters.column.action'),
      dataIndex: 'id',
      key: 'id',
      sorter: false,
      render: (data, record) => (
        <div>
          {
            canUpdate &&
            <Button
              type='text'
              className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0'
              onClick={() => handleShowModal(record?.name, record?.work_locations, record?.id)}
              disabled={!canUpdate}
            >
              <EditFilled style={{ fontSize: '20px' }} className='text-[#55A853] p-1' />
            </Button>
          }
        </div>
      ),
    },
    {
      title: t('clusters.column.cluster_name'),
      dataIndex: 'name',
      key: 'name',
      sorter: false,
      render: (data, record) => {
        return (<div>{data || ''}</div>);
      }
    },
    {
      title: t('clusters.column.work_location'),
      dataIndex: 'work_locations',
      key: 'work_locations',
      sorter: false,
      render: (data, record) => {
        return (<div>{data.map((el:any) => el.name).join(', ')}</div>);
      }
    },
  ];

  const {
    data: dataCluster,
    isLoading: isLoadingCluster,
    isError: isErrorCluster,
    error: errorCluster,
    refetch: refetchCluster
  } = useListQuery(undefined, { refetchOnMountOrArgChange: true });

  const [postCluster, {
    error: errorPostCluster,
    isError: isErrorPostCluster,
    isSuccess: isSuccessPostCluster,
    isLoading: isLoadingPostCluster
  }] = usePostClusterMutation();

  const [putCluster, {
    error: errorPutCluster,
    isError: isErrorPutCluster,
    isSuccess: isSuccessPutCluster,
    isLoading: isLoadingPutCluster
  }] = usePutClusterMutation();

  const handleShowModal = (
    clusterName: string | '' = '', 
    workLocation: Array<WorkLocation> | [] = [],
    id: string | '' = ''
  ) => {
    form.setFieldsValue({
      name: clusterName,
      work_locations: workLocation.map((el:WorkLocation) => el.id)
    });
    setId(id);
    setShowModal(true);
  };

  const handleSubmit = async () => {
    let payload = form.getFieldsValue();
    if (id) {
      payload = {
        ...payload,
        id: id
      };
      await putCluster(payload).unwrap();
    } else {
      await postCluster(payload).unwrap();
    }
  };

  useEffect(() => {
    const paths = pathname.split('/');
    setCanUpdate(hasPermission(paths[1]).can_update);
    setCanCreate(hasPermission(paths[1]).can_update);
  });

  useEffect(() => {
    if (isErrorCluster) {
      const errorMsg: any = errorCluster;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorCluster, errorCluster]);

  useEffect(() => {
    if (isErrorPostCluster) {
      const errorMsg: any = errorPostCluster;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorPostCluster, errorPostCluster]);

  useEffect(() => {
    if (isErrorPutCluster) {
      const errorMsg: any = errorPutCluster;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorPutCluster, errorPutCluster]);

  useEffect(() => {
    if (isSuccessPostCluster) {
      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('general.success_text')}`}
        />
      );
      setShowModal(false);
      refetchCluster();
    }
  }, [isSuccessPostCluster]);

  useEffect(() => {
    if (isSuccessPutCluster) {
      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('general.success_text')}`}
        />
      );
      setShowModal(false);
      refetchCluster();
    }
  }, [isSuccessPutCluster]);

  return(
    <div className='w-full p-3'>
      <Button 
        className='bg-[#55A853] text-white font-bold' 
        disabled={!canCreate}
        onClick={() => handleShowModal()}>
        {t('clusters.btn_add')}
      </Button>

      <div className='mt-3'>
        <SimpleDataTable
          columns={columns}
          dataSource={dataCluster?.rows || []}
          loading={isLoadingCluster}
        />
      </div>

      <ModalForm
        id={id}
        form={form}
        modalAction={{
          showModal:showModal,
          setShowModal:setShowModal
        }}
        handleSubmit={handleSubmit}
        loading={{
          isLoadingSubmit: isLoadingPostCluster || isLoadingPutCluster
        }}
      />
    </div>
  );
};

export default Cluster;