import type { DocumentType } from 'constant/Types/DocumentType';
import PouchDB from 'pouchdb';

class Base<T extends DocumentType> {

  private db: any | null = null;

  constructor(name: string) {
    this.db = new PouchDB<T>(name);
  }

  putData(data: T): Promise<T> {
    /**
     * We need to check document's `_rev` first,
     * And put it back to properly update its value
     **/
    return new Promise((resolve, reject) => {
      this.db.get(data._id)
        .then((value: any) => {
          const editedValue = {
            ...value,
            ...data
          };

          this.db.put(editedValue)
            .then((putValue: any) => resolve(putValue))
            .catch((error: any) => reject(error));
        })
        .catch((error: any) => {
          if (error.status === 404) {
            this.db.put(data)
              .then((putValue: any) => resolve(putValue))
              .catch((error: any) => reject(error));
            
            return;
          }

          reject(error);
        });
    });
  };

  getData(id: T['_id']): Promise<T> {
    return new Promise((resolve, reject) => {
      this.db.get(id)
        .then((value: any) => resolve(value))
        .catch((error: any) => reject(error));
    });
  }

  /**
   * remove need document's '_id' and '_rev',
   * to easily provide that, documentation guide us to
   * simply 'get' the document first then pass the value inside
   * 'remove' function
   *
   * refer to : https://pouchdb.com/api.html#delete_document
   **/
  deleteData(id: T['_id']): Promise<T> {
    return new Promise((resolve, reject) => {
      this.db.get(id)
        .then((value: any) => {
          this.db.remove(value)
            .then((data: any) => resolve(data))
            .catch((error: any) => reject(error));
        })
        .catch((error: any) => reject(error));
    });
  }
}

export default Base;