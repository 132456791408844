import { useEffect, useState } from 'react';
import { Form, Spin, Select, DatePicker, Button, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaCircle } from 'react-icons/fa';
import TextArea from 'antd/es/input/TextArea';
import { useAppSelector } from 'store/Hooks';
import { useListPermitRequestReportIndicatorMutation, usePostPermitRequestReportIndicatorMutation } from 'api/PermitRequestReport';
import Toast from 'components/Toast';
import { toast } from 'react-toastify';
import { transformError } from 'api/Base';
import { BodyPostPermitRequestIndicator } from 'constant/Types/PermitRequestReport';
import dayjs from 'dayjs';

const Report = (props: any) => {
  interface Indicator {
    key: string,
    labelcolor: string,
    value: string,
    hexacolor: string
  }

  const { 
    id, 
    triggerChange, 
    detail, 
    detailReport,
    fetchReport,
    disabled = false,
  } = props;
  const { t } = useTranslation ();
  const { Option } = Select;
  const [formReport] = Form.useForm();
  const { user } = useAppSelector((state) => state.auth);
  const listStatusEditReport = ['open', 'processed'];
  const [indicators, setIndicators] = useState<Indicator[]>([]);

  const [getListPermitRequestReportIndicator, {
    error: errorGetListPermitRequestReportIndicator,
    isError: isErrorGetListPermitRequestReportIndicator,
    isLoading: isLoadingGetListPermitRequestReportIndicator
  }] = useListPermitRequestReportIndicatorMutation();

  const [postPermitRequestReportIndicator, {
    isLoading: isLoadingPostPermitRequestReportIndicator
  }] = usePostPermitRequestReportIndicatorMutation();

  const handleFinish = async () => {
    formReport.validateFields().then(async () => {
      const formSend: BodyPostPermitRequestIndicator = {
        permit_request_report: {
          ...formReport.getFieldsValue(),
          expected_date: formReport.getFieldValue('expected_date') ? 
            dayjs(formReport.getFieldValue('expected_date')).startOf('month').format('YYYY-MM-DD') : null,
          permit_request_id: id
        }
      };

      await postPermitRequestReportIndicator(formSend).unwrap().then(() => {
        toast.success(
          <Toast
            message={t('general.success_text')}
            detailedMessage={`${t('permit_request.request_information.success_message_save_draft')}`}
          />
        );
        window.location.reload();
      }).catch((errorMsg:any) => {
        toast.error(<Toast message={t('general.error_text')} detailedMessage={`${errorMsg?.data?.message}`} />);
      });
    }).catch((errorMsg) => {
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${errorMsg?.data?.message}`} />);
    });
  };

  const fetchIndicator = async () => {
    const data = await getListPermitRequestReportIndicator({}).unwrap();
    setIndicators(data);
  };

  useEffect(() => {
    fetchIndicator();
  }, []);

  useEffect(() => {
    if (id) {
      formReport.resetFields();
      fetchReport();
    }
  }, [triggerChange]);

  useEffect(() => {
    if (detailReport?.data){
      formReport.setFieldsValue({
        ...detailReport.data,
        expected_date: detailReport? dayjs(detailReport?.data?.expected_date) : null
      });
    }
  }, [detailReport?.data]);

  useEffect(() => {
    if (isErrorGetListPermitRequestReportIndicator) {
      const errorMsg: any = errorGetListPermitRequestReportIndicator;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isErrorGetListPermitRequestReportIndicator]);

  return(
    <div className="px-5 py-2">
      <Spin tip="Loading" size="large" spinning={detailReport?.isLoading}>
        <Form
          layout="vertical"
          autoComplete="off"
          form={formReport}
          requiredMark={false}
          name='form-report'
          disabled={disabled || (detail?.pic?.username !== user?.username) || !listStatusEditReport.includes(detail?.status?.toLowerCase())}
        >
          <div className='grid grid-cols-1 max-md:grid-cols-1 md:grid-cols-2 gap-5'>
            <div>
              <div className='md:col-span-1 max-md:col-span-2'>
                <Form.Item
                  name='indicator'
                  label={t('permit_request.history_conversation.report.indicator_title')}
                  rules={[{ required: true }]}
                  className='font-bold'
                >
                  <Select
                    placeholder={t('permit_request.history_conversation.report.indicator_placeholder')}
                    className='font-normal'
                    notFoundContent={isLoadingGetListPermitRequestReportIndicator ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                  >
                    {indicators.map(item => (
                      <Option key={item.key} value={item.key}>
                        <div className='flex'>
                          <FaCircle className={'my-auto'} style={{color: item.hexacolor}}/>
                          <p className='ml-2'>{item.value}</p>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className='md:col-span-1 max-md:col-span-2 mt-[25px]'>
                <Form.Item
                  name='expected_date'
                  label={t('permit_request.history_conversation.report.expected_granted_title')}
                  rules={[{ required: true }]}
                  className='font-bold'
                >
                  <DatePicker 
                    picker="month" 
                    className='w-full font-normal' 
                    format={'MMM-YYYY'}
                    placeholder={t('permit_request.history_conversation.report.expected_granted_placeholder') || ''}
                  />
                </Form.Item>
              </div>
            </div>
            <div>
              <div className='md:col-span-1 max-md:col-span-2'>
                <Form.Item
                  name='item'
                  label={t('permit_request.history_conversation.report.item_title')}
                  rules={[{ required: true }]}
                  className='font-bold'
                >
                  <TextArea
                    className='font-normal'
                    autoSize={{ minRows: 1, maxRows: 5 }}
                    placeholder={t('permit_request.history_conversation.report.item_placeholder') || ''}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          {
            ((detail?.pic?.username !== user?.username) || !listStatusEditReport.includes(detail?.status?.toLowerCase())) ? (<></>) : 
              (<div className='text-right mt-5'>
                <Button
                  className={'bg-[#55A853] text-white'}
                  htmlType="button"
                  onClick={handleFinish}
                  disabled={isLoadingPostPermitRequestReportIndicator}
                >
                  {isLoadingPostPermitRequestReportIndicator ? <Spin/> : t('general.save_btn')}
                </Button>
              </div>)
          }
        </Form>
      </Spin>
    </div>
  );
};

export default Report;