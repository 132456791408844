import { Tabs, Form, Spin } from 'antd';
import type { TabsProps } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import Toast from 'components/Toast';
import { toast } from 'react-toastify';
import { hasPermission } from 'utils/Permission';
import dayjs from 'dayjs';
import { transformError } from 'utils/ErrorTransformer';
import { useSearchParams } from 'react-router-dom';
import { hexToRgba } from 'utils/Utility';
import History from 'pages/Create/PermitLicenseRequest/HistoryConversation/History';
import Report from 'pages/Create/PermitLicenseRequest/HistoryConversation/Report';
import Conversation from 'pages/Create/PermitLicenseRequest/HistoryConversation/Conversation';
import { useGetPermitRequestReportIndicatorByIdMutation } from 'api/PermitRequestReport';
import { useGetComplianceMatrixMutation, useGetDetailComplianceMonitoringMutation } from 'api/ComplianceMonitoring';
import { DATA_COLOR_COMPLIANCE_STATUS } from 'constant';
import { OptionType } from 'constant/Types/OptionType';
import PermitTabComplianceMonitoring from './Permit';

interface IRiskData {
  category: string;
  color: string;
}

const FormComplianceMonitoring = () => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const complianceStatus = Form.useWatch('compliance_status', form);
  const likelihood = Form.useWatch('likelihood', form);
  const impact = Form.useWatch('impact', form);

  const [getDetail, {
    error: errorDetail,
    isError: isErrorDetail,
    isLoading: isLoadingDetail
  }] = useGetDetailComplianceMonitoringMutation();
  const [getPermitRequestReportIndicatorById] = useGetPermitRequestReportIndicatorByIdMutation();
  const [getComplianceMatrix, {isLoading: isLoadingGetComplianceMatrix}] = useGetComplianceMatrixMutation();

  const [activateTab, setActiveTab] = useState('1');
  const [canUpdate, setCanUpdate] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [detail, setDetail] = useState<unknown>(null);
  const [refetchData, setRefetchData] = useState(false);
  const [triggerChange, setTriggerChange] = useState(false);
  const [dataReport, setDataReport] = useState({});
  const [dataMatrix, setDataMatrix] = useState<any[]>([]);
  const [optionLikelihood, setOptionLikelihood] = useState<OptionType[]>([]);
  const [optionImpact, setOptionImpact] = useState<OptionType[]>([]);
  const [currentRiskData, setCurrentRiskData] = useState<IRiskData | null> (null); 

  const onChange = (key: string) => {
    setTriggerChange(!triggerChange);
  };

  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
    <div>
      <DefaultTabBar
        {...props}
        className='px-10 rounded-xl text-[#AAAAAA]'
        style={{ background: '#FFF' }}
      />
    </div>
  );

  const onTabClick = (e: any) => {
    if (e === '2' && !isEdit && !canUpdate) {
      toast.warning(<Toast message={t('general.warning_text')} detailedMessage={t('permit_monitoring.cant_move_to_tab_obligation')} />);
      return;
    }

    if (e === '3') {
      setRefetchData(!refetchData);
    }

    setActiveTab(e);
  };

  const onFinish = () => {};

  const fetchDetail = async () => {
    let dataDetail = await getDetail(id).unwrap();
    dataDetail = {
      ...dataDetail,
      description: dataDetail?.permit_request_compliances?.description || '',
      request_no: dataDetail?.request_number || '',
      permit_name: dataDetail?.permit_name?.name[getLanguage()] || '',
      compliance_status: dataDetail?.permit_request_compliances?.compliance_status || '',
      status: dataDetail?.grouping_status || '',
      revised_target: dataDetail?.permit_request_compliances?.revised_target ? dayjs(dataDetail.permit_request_compliances.revised_target) : null,
      original_target: dataDetail?.original_target ? dayjs(dataDetail.original_target) : null,
      submitted_date: dataDetail?.created_at ? dayjs(dataDetail.created_at) : null,
      er_pic: dataDetail?.pic?.display_name || '',
      sector: dataDetail?.permit_type?.name || '',
      permit_location: dataDetail?.permit_location?.name || '',
      permit_holder: dataDetail?.creator?.display_name || '',
      likelihood: dataDetail?.permit_request_compliances?.likelihood || 0,
      impact: dataDetail?.permit_request_compliances?.impact || 0,
      impact_to_business: dataDetail?.permit_request_compliances?.impact_to_business || '',
      remarks: dataDetail?.permit_request_compliances?.remarks || '',
    };
    setDetail(dataDetail);
    form.setFieldsValue(dataDetail);
  };

  const fetchReport = async () => {
    if (id) {
      const data = await getPermitRequestReportIndicatorById(id).unwrap();
      setDataReport(data);
    }
  };

  const fetchComplianceMatrix = async () => {
    const response = await getComplianceMatrix({}).unwrap();
  
    const dataFiltered = response?.rows.map((row: any) => ({
      likelihood: row.likelihood,
      impact: row.impact,
      hexacolor: row.category?.hexacolor || '',
      category: row.category?.category || '',
    })) || [];
  
    const uniqueLikelihoods = new Map<number, { label: number; value: number }>();
    const uniqueImpacts = new Map<number, { label: number; value: number }>();
  
    dataFiltered.forEach((row: any) => {
      if (!uniqueLikelihoods.has(row.likelihood)) {
        uniqueLikelihoods.set(row.likelihood, { label: row.likelihood, value: row.likelihood });
      }
      if (!uniqueImpacts.has(row.impact)) {
        uniqueImpacts.set(row.impact, { label: row.impact, value: row.impact });
      }
    });
  
    const sortedLikelihoods = Array.from(uniqueLikelihoods.values()).sort((a, b) => a.value - b.value);
    const sortedImpacts = Array.from(uniqueImpacts.values()).sort((a, b) => a.value - b.value);
  
    setOptionLikelihood(sortedLikelihoods);
    setOptionImpact(sortedImpacts);
    setDataMatrix(dataFiltered);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('tab.permit'),
      children:
        <PermitTabComplianceMonitoring
          id={id}
          form={
            {
              setFieldsValue: form.setFieldsValue,
              form: form
            }
          }
          isView={isView}
          isEdit={isEdit}
          detail={detail}
          dataOptions={
            {
              isLoading: isLoadingGetComplianceMatrix,
              likelihood: optionLikelihood,
              impact: optionImpact,
            }
          }
        />,
    },
    {
      key: '2',
      label: t('permit_request.history_conversation.conversation_title'),
      children: <Conversation id={id} triggerChange={triggerChange} detail={detail}/>,
    },
    {
      key: '3',
      label: t('permit_request.history_conversation.history_title'),
      children: <History id={id} triggerChange={triggerChange} />,
    },
    {
      key: '4',
      label: t('permit_request.history_conversation.report_title'),
      children: <Report 
        id={id} 
        triggerChange={triggerChange} 
        detail={detail} 
        detailReport={{
          data: dataReport,
          isLoading: false
        }}
        fetchReport={fetchReport}
        disabled={true}
      />,
    },
  ];

  const getStyleBadge = (value: string, dataColor: any[]) => {
    if(value){
      const foundData = dataColor.find(data => value.toLowerCase().includes(data.value.toLowerCase()));
      return foundData
        ? {
          backgroundColor: hexToRgba(foundData.color, 0.2),
          borderColor: foundData.color,
          color: foundData.color,
        }
        : {};
    }
    return {};
  };

  useEffect(() => {
    if (likelihood && impact) {
      const data = dataMatrix.find(
        (item: { likelihood: number; impact: number; hexacolor: string }) =>
          item.likelihood === likelihood && item.impact === impact
      );
  
      setCurrentRiskData({
        category: data?.category || '',
        color: data?.hexacolor || '',
      });
    }
  }, [likelihood, impact, detail]);

  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchDetail();
      fetchReport();
    }
  }, [id]);

  useEffect(() => {
    fetchComplianceMatrix();
  }, []);

  useEffect(() => {
    if (isErrorDetail) {
      const errorMsg: any = errorDetail;
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
    }
  }, [isErrorDetail]);

  useEffect(() => {
    const paths = pathname.split('/');
    const permissions = hasPermission(paths[1]);
    
    if (!permissions.can_view) {
      navigate('/403');
      return;
    }
    setCanUpdate(permissions.can_update);
  
    if (id) {
      const isEditPath = pathname.includes('edit');
      const isDetailPath = pathname.includes('detail');
  
      if (isEditPath) {
        setIsEdit(true);
        setIsView(false);
        if (!permissions.can_update) {
          navigate(`/compliance-monitoring/detail/${id}`);
        }
      } else if (isDetailPath) {
        setIsEdit(false);
        setIsView(true);
        if (permissions.can_update) {
          navigate(`/compliance-monitoring/edit/${id}`);
        }
      }
      fetchDetail();
    }
  }, [pathname, id, navigate]);
  
  return (
    <Spin tip="Loading" size="large" spinning={isLoadingDetail}>
      <Form
        className='h-[100px]'
        layout="vertical"
        autoComplete="off"
        form={form}
        requiredMark={false}
        onFinish={onFinish}
      >
        <div className='px-6 py-3 text-[12px] bg-white rounded-lg mb-4 flex flex-row justify-between'>
          <div className='flex flex-col items-start gap-2'>
            <span>{t('compliance_monitoring.compliance_status')}</span>
            <span
              style={complianceStatus ? getStyleBadge(complianceStatus?.toLowerCase(), DATA_COLOR_COMPLIANCE_STATUS) : {}}
              className={'py-1 px-2  rounded-lg border border-solid text-xs text-center'}
            >
              {complianceStatus || '-'}
            </span>
          </div>
          <div className='flex flex-col items-end gap-2'>
            <span>{t('compliance_monitoring.risk_assessment_status')}</span>
            <span
              style={(likelihood && impact) ? ({
                backgroundColor: hexToRgba(currentRiskData?.color || '', 0.2),
                borderColor: currentRiskData?.color,
                color: currentRiskData?.color,
              }) : {}}
              className={'py-1 px-2  rounded-lg border border-solid text-xs text-center'}
            >
              {currentRiskData?.category || '-'}
            </span>
          </div>
        </div>
        <Tabs
          activeKey={activateTab}
          renderTabBar={renderTabBar}
          items={items}
          onTabClick={onTabClick}
          onChange={onChange}
        />
      </Form>
    </Spin>
  );
};

export default FormComplianceMonitoring;