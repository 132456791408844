import { useTranslation } from 'react-i18next';
import { Button, Switch, Modal } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { EyeOutlined, EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import Toast from 'components/Toast';
import { ButtonDataTableTypeItems } from 'constant/Types/DataTableConfigType';
import DataTable from 'components/DataTable';
import { useListQuery } from 'api/Cluster';
import { useListRolePaginationMutation, useActivateRoleMutation } from 'api/Role';
import { transformError } from 'utils/ErrorTransformer';
import { ListPayloadType } from 'constant/Types/RoleType';
import ModalFilter from './ModalFilter';
import { hasPermission } from 'utils/Permission';

const ManageGroupList = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { confirm } = Modal;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    total: 0,
    roles: []
  });
  const {
    data: dataCluster,
    isLoading: isLoadingCluster,
    isError: isErrorCluster,
    error: errorCluster
  } = useListQuery(undefined, { refetchOnMountOrArgChange: true });
  const [roleList, {
    error: errorRoleList,
    isError: isErrorRoleList
  }] = useListRolePaginationMutation();
  const [activateRoles, {
    error: errorActivateRoles,
    isError: isErrorActivateRoles,
    isSuccess: isSuccessActivateRoles
  }] = useActivateRoleMutation();
  const [mapDataCluster, setMapDataCluster] = useState([]);
  const [filter, setFilter] = useState({
    status: [],
    cluster: []
  });
  const [payload, setPayload] = useState<ListPayloadType>({
    page: 1,
    pageSize: 50,
    search: '',
    searchFields: 'name',
    sort: 'name',
    cluster_id: '',
    status: ''
  });
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [canView, setCanView] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canCreate, setCanCreate] = useState(false);

  interface DataType {
    key: any;
    is_active: any;
    name: any;
    roleClusters: any;
  }

  const buttonConfig: ButtonDataTableTypeItems = [
    {
      name: t('manage_group.add_group_btn'),
      link: 'test',
      className: 'bg-[#55A853] text-white font-bold',
      onClick: () => { navigate('/manage-group/create'); }
    }
  ];

  const fetchList = async () => {
    setLoading(true);
    let convertPayload: any = payload;
    let newPayload: any = {};
    for (const key in convertPayload) {
      if (convertPayload[key]) {
        newPayload[key] = convertPayload[key];
      }
    }

    let roles: any = await roleList(newPayload).unwrap();

    roles = { ...roles, roles: roles.rows.map((el: any, idx: any) => ({ ...el, key: idx })) };
    setData(roles);
    setLoading(false);
    setIsLoadingFilter(false);
  };

  const showDeleteConfirm = (data: any, value: any) => {
    const payloadActivate = {
      id: value.id,
      params: {
        id: value.id,
        is_active: !data
      }
    };

    confirm({
      title: t('manage_group.confirm_text_one') + (data ? t('general.inactivate_text') : t('general.activate_text')) + t('manage_group.confirm_text_two'),
      icon: <ExclamationCircleFilled />,
      okText: t('manage_group.yes_btn'),
      okType: 'danger',
      cancelText: t('manage_group.no_btn'),
      async onOk() {
        await activateRoles(payloadActivate).unwrap();
        setPayload({ ...payload });
      },
    });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: t('manage_group.column.action'),
      key: 'action',
      width: 150,
      render: (_, record: any) => (
        <div>
          {
            canView &&
            <Button type='text' className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0'>
              <EyeOutlined style={{ fontSize: '20px' }} className='text-[#55A853] p-1' onClick={() => navigate(`/manage-group/detail/${record?.id}`)} />
            </Button>
          }
          {
            canEdit &&
            <Button type='text' className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0'>
              <EditOutlined style={{ fontSize: '20px' }} className='text-[#55A853] p-1' onClick={() => navigate(`/manage-group/edit/${record?.id}`)} />
            </Button>
          }
        </div>
      ),
    },
    {
      title: t('manage_group.column.is_active'),
      dataIndex: 'is_active',
      key: 'is_active',
      sorter: true,
      width: 150,
      render: (data, value) => {
        return (
          <div>
            {
              canEdit &&
              <Switch checked={data} className='bg-slate-300' onChange={() => showDeleteConfirm(data, value)} />
            }
          </div>
        );
      },
    },
    {
      title: t('manage_group.column.group_name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      defaultSortOrder: 'ascend'
    },
    {
      title: t('manage_group.column.cluster_type'),
      dataIndex: 'cluster_type',
      key: 'cluster_type',
      sorter: true,
      render: (data) => (
        data ? <div className='bg-[#D3E3CE] text-[#1D6916] font-bold text-center rounded-lg w-40'>{data}</div> : <></>
      )
    },
  ];

  const applyFilter = () => {
    setIsLoadingFilter(true);
    setPayload({ ...payload, page: 1, cluster_id: filter.cluster, status: filter.status });
  };

  const resetFilter = () => {
    setIsLoadingFilter(true);
    setFilter({ status: [], cluster: [] });
    setPayload({ ...payload, cluster_id: [], status: [] });
  };

  useEffect(() => {
    fetchList();

    const paths = pathname.split('/');
    if (!hasPermission(paths[1]).can_view) {
      navigate('/403');
    }
    setCanView(hasPermission(paths[1]).can_view);
    setCanEdit(hasPermission(paths[1]).can_update);
    setCanCreate(hasPermission(paths[1]).can_create);
  }, [payload]);

  useEffect(() => {
    if (isErrorRoleList) {
      const errorMsg: any = errorRoleList;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorRoleList, errorRoleList]);

  useEffect(() => {
    if (isErrorActivateRoles) {
      const errorMsg: any = errorActivateRoles;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data?.code === 42210 ? errorMsg?.data?.error.errors[0] : errorMsg?.data || errorMsg).message}`} />);
    }

    if (isSuccessActivateRoles) {
      toast.success(<Toast message={t('general.success_text')} detailedMessage={t('manage_group.succes_edit_form')} />);
      navigate('/manage-group');
    }
  }, [isErrorActivateRoles, isSuccessActivateRoles]);

  useEffect(() => {
    if (!isLoadingCluster) {
      if (dataCluster) {
        const newDataCluster = dataCluster.rows.map((el: any) => {
          return {
            label: el.name,
            value: el.id
          };
        });
        setMapDataCluster(newDataCluster);
        return;
      }

      if (isErrorCluster) {
        const errorMsg: any = errorCluster;

        toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
        return;
      }
    }
  }, [dataCluster, isLoadingCluster, isErrorCluster, errorCluster]);

  return (
    <div>
      <ModalFilter
        modalAction={{
          isModalOpen: isModalOpen,
          setIsModalOpen: setIsModalOpen
        }}
        filter={{
          filter: filter,
          setFilter: setFilter
        }}
        filterAction={{
          isLoadingCluster: isLoadingCluster,
          isLoadingFilter: isLoadingFilter,
          resetFilter: resetFilter,
          applyFilter: applyFilter
        }}
        mapDataCluster={mapDataCluster}
      />
      <DataTable
        columns={columns}
        data={data.roles}
        buttonConfig={canCreate ? buttonConfig : []}
        loading={loading}
        totalData={data.total}
        payload={{
          payload: payload,
          setPayload: setPayload
        }}
        modalOpen={{
          isModalOpen: isModalOpen,
          setIsModalOpen: setIsModalOpen
        }}
        scrollWidth={1000}
      />
    </div>
  );
};

export default ManageGroupList;