import { Button, Form, Input, Modal, Spin, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cronstrue from 'cronstrue';
import { isValidCron } from 'cron-validator';
import { useGetDetailCronjobMutation, usePutCronjobMutation } from 'api/ManageCronjob';
import { toast } from 'react-toastify';
import Toast from 'components/Toast';

interface IModalExecProps {
  modalAction: any;
  data: any;
  canUpdate?: boolean;
  callback?: () => void; 
}

const ModalExec = ({modalAction, data: dataProp, canUpdate = false, callback}: IModalExecProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const cronExpression = Form.useWatch('cron_expression', form);
  const status = Form.useWatch('status', form);
  const initialForm = {
    cron_expression: '',
    status: false,
  };
  const [readableCron, setReadableCron] = useState('');

  const [getDetailCronjob, {
    isLoading,
  }] = useGetDetailCronjobMutation();
  const [putCronjob, {isLoading: isLoadingPutCronjob}] = usePutCronjobMutation();

  const validateCronExpression = (rule: any, value: string) => {
    const specialCronExpressions = ['@yearly', '@annually', '@monthly', '@weekly', '@daily', '@midnight', '@hourly'];
  
    if (!value || isValidCron(value) || specialCronExpressions.includes(value)) {
      return Promise.resolve();
    }
  
    return Promise.reject('Invalid cron expression!');
  };

  const handleCancel = () => {
    modalAction.setShowModal(false);
  };

  const fetchExec = async (id: string) => {
    const response = await getDetailCronjob(id).unwrap();
    form.setFieldsValue({
      cron_expression: response?.cron_time || '',
      status: response?.is_active || false,
    });
  };

  const handleSubmitFinal = async (values: any) => {
    const submitForm = {
      cron: {
        is_active: values.status || false,
        cron_time: values.cron_expression || '',
      },
    };
  
    try {
      await putCronjob({ id: dataProp?.id, body: submitForm }).unwrap();
      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('manage_cronjob.success.update_cronjob')}`}
        />
      );
      handleCancel();
      if(callback){
        callback();
      }
    } catch {
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={t('manage_cronjob.error.update_cronjob')}
        />
      );
    }
  };

  const handleSubmitForm = () => {
    form.validateFields()
      .then((values) => {
        handleSubmitFinal(values);
      })
      .catch((_errorInfo) => {
        toast.error(
          <Toast
            message={t('general.error_text')}
            detailedMessage={t('manage_cronjob.error.validation')}
          />
        );
      });
  };

  const calculateExpression = (expression: string) => {
    if(expression){
      form.validateFields(['cron_expression'])
        .then((values) => {
          const { cron_expression } = values;
          const description = cronstrue.toString(cron_expression, {use24HourTimeFormat: true});
          setReadableCron(description);
        });
    }
  };

  useEffect(() => {
    if(dataProp?.id){
      fetchExec(dataProp.id);
    }
  }, [dataProp?.id]);

  useEffect(() => {
    form.setFieldsValue(initialForm);
  }, []);

  useEffect(() => {
    calculateExpression(cronExpression);
  }, [cronExpression]);

  return(
    <Modal
      title={null}
      open={modalAction.showModal}
      footer={null}
      width={700}
      onCancel={() => modalAction.setShowModal(false)}
    >
      <Spin tip="Loading" size="large" spinning={isLoading}>
        <p className='font-bold text-[16px] mb-5'>
          {canUpdate ? t('manage_cronjob.edit_cron_service') : t('manage_cronjob.detail_cron_service')}
        </p>
        <div>
          <Form
            layout="vertical"
            autoComplete="off"
            form={form}
            requiredMark={false}
          >
            <div className='flex flex-col gap-4'>
              <Form.Item
                label={t('manage_cronjob.cron_name')}
              >
                <Input 
                  value={dataProp?.cronName || ''}
                  disabled
                />
              </Form.Item>
              <Form.Item
                label={t('manage_cronjob.cron_expression')}
                name='cron_expression'
                rules={[{ required: true, validator: validateCronExpression }]}
              >
                <Input 
                  className='w-36' 
                  value={cronExpression}
                  onChange={(e) => form.setFieldValue('cron_expression', e.target.value)}
                  disabled={!canUpdate}
                />
              </Form.Item>
              <div className='flex flex-col gap-2'>
                <span>{t('manage_cronjob.result_cron_expression')}</span>
                <span className='font-bold text-lg'>{readableCron}</span>
              </div>
              <div className='h-[1px] bg-[#ADB3BE]'/>
              <Form.Item
                label={t('manage_cronjob.status')}
                name="status"
              >
                <div className='flex items-center gap-2'>
                  <Switch 
                    checked={status} 
                    onChange={(value) => form.setFieldValue('status', value)} 
                    disabled={!canUpdate}
                  />
                  <span>{status ? t('manage_cronjob.active') : t('manage_cronjob.inactive')}</span>
                </div>
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className='mt-8 flex justify-center gap-2'>
          <Button
            className='cancel-green bg-[#E5EDE5] text-[#55A853] border-[#55A853]'
            htmlType="button"
            onClick={handleCancel}
          >
            {t('general.cancel_btn')}
          </Button>
          <Button
            className="bg-[#55A853] text-white font-bold"
            htmlType="button"
            onClick={handleSubmitForm}
            loading={isLoadingPutCronjob}
          >
            {t('general.save_btn')}
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};

export default ModalExec;