import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ReceiverListEmail = (props:any) => {
  const { to, cc, limit } = props;
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  
  const totalNames = [...to, ...cc];
  const shouldTruncate = totalNames.length > limit;

  const displayedTo = isExpanded ? to : to.slice(0, limit);
  const remainingSlots = limit - displayedTo.length;
  const displayedCc = isExpanded ? cc : cc.slice(0, remainingSlots);

  const fullText = (
    <div>
      {to.length > 0 && (
        <>
          <p className='font-bold'>{t('email_notification.to')}:</p>
          <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>{to.map((person:string, index:number) => <li key={`to-${index}`}>{person}</li>)}</ul>
        </>
      )}
      {cc.length > 0 && (
        <>
          <p className='font-bold'>{t('email_notification.cc')}:</p>
          <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>{cc.map((person:string, index:number) => <li key={`cc-${index}`}>{person}</li>)}</ul>
        </>
      )}
    </div>
  );

  const truncatedText = (
    <div>
      {displayedTo.length > 0 && (
        <>
          <p className='font-bold'>{t('email_notification.to')}:</p>
          <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>{displayedTo.map((person:string, index:number) => <li key={`to-${index}`}>{person}</li>)}</ul>
        </>
      )}
      {displayedCc.length > 0 && (
        <>
          <p className='font-bold'>{t('email_notification.cc')}:</p>
          <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>{displayedCc.map((person:string, index:number) => <li key={`cc-${index}`}>{person}</li>)}</ul>
        </>
      )}
    </div>
  );

  return (
    <div>
      {isExpanded || !shouldTruncate ? fullText : truncatedText}
      {shouldTruncate && (
        <p className='text-[#54A853] cursor-pointer' onClick={() => setIsExpanded(!isExpanded)}>
          {!isExpanded ? t('general.see_more') : t('general.less')}
        </p>
      )}
    </div>
  );
};

export default ReceiverListEmail;
