import { useState, useEffect } from 'react';
import { Form, Input, Switch, Checkbox, Select, Row, Col, Button, Spin, ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate, useLocation, useParams } from 'react-router';

import Toast from 'components/Toast';
import { useListMenuQuery, useGetMenusByRoleIdMutation } from 'api/Menu';
import { usePostRoleMutation, useGetRoleByIdMutation, usePutRoleMutation } from 'api/Role';
import { transformError } from 'utils/ErrorTransformer';
import { CLUSTER_TYPE } from 'constant';

const ManageGroupAdd = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [listMenu, setListMenu] = useState<any[]>([]);
  const [isActive, setIsActive] = useState(false);
  const [isAdminView, setIsAdminView] = useState(false);
  const [isVip, setIsVip] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    data: dataMenus,
    isLoading: isLoadingMenus,
    isError: isErrorMenus,
    error: errorMenus
  } = useListMenuQuery(undefined, { refetchOnMountOrArgChange: true });
  const [postRole, {
    error: errorPostRole,
    isSuccess: isSuccessPostRole,
    isError: isErrorPostRole
  }] = usePostRoleMutation();
  const [putRole, {
    error: errorPutRole,
    isSuccess: isSuccessPutRole,
    isError: isErrorPutRole
  }] = usePutRoleMutation();
  const [getRoleById, {
    isLoading: isLoadingGetRoleById,
    error: errorGetRoleById,
    isError: isErrorGetRoleById,
    data: dataGetRoleById,
  }] = useGetRoleByIdMutation();
  const [getMenuByRoleId, {
    isLoading: isLoadingGetMenuByRoleId,
    error: errorMenuByRoleId,
    isError: isErrorMenuByRoleId
  }] = useGetMenusByRoleIdMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const onChangeCheckMenu = (param: any, idx: any) => {
    const newArr = listMenu.map((el: any, id: any) => {
      if (id === idx) {
        const dataRolePermission = el.role_menu_permission;
        const dataParam = el.role_menu_permission[param];
        const newRolePermission = { ...dataRolePermission, [param]: !dataParam };
        return { ...el, role_menu_permission: newRolePermission };
      }

      return el;
    });

    setListMenu([...newArr]);
  };

  const onFinish = async () => {
    setIsLoading(true);

    const sendForm: any = {
      role: {
        name: form.getFieldValue('name'),
        is_admin: isAdminView,
        is_vip: isVip,
        is_active: isActive,
        cluster_type: form.getFieldValue('cluster_type') || null,
        permissions: listMenu.map(el => el.role_menu_permission)
      }
    };

    if (location.pathname.includes('edit')) {
      sendForm.id = id;
      await putRole(sendForm).unwrap();
      return;
    }
    await postRole(sendForm).unwrap();
  };

  const onFinishFailed = () => {
    toast.error(<Toast message={t('general.error_text')} detailedMessage={t('manage_group.fill_form_validation_text')} />);
  };

  const onChangeIsVip = () => {
    setIsVip(!isVip);
  };

  const getRoleData = async (id: any) => {
    await getRoleById(id).unwrap();
  };

  const getMenusById = async (id: any) => {
    const dataMenusId = await getMenuByRoleId(id).unwrap();
    setListMenu([...dataMenusId]);
  };

  useEffect(() => {
    if (location.pathname.includes('detail')) {
      setIsDetail(true);
    }

    if (id) {
      getRoleData(id);
    }
  }, []);

  useEffect(() => {
    if (!isLoadingMenus) {
      const errorMsg: any = errorMenus;

      if (dataMenus) {
        if (location.pathname.includes('create')) {
          setListMenu([...dataMenus]);
        }
      }

      if (isErrorMenus) {
        toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data || errorMenus).message}`} />);
        return;
      }
    }
  }, [dataMenus, isLoadingMenus, isErrorMenus, errorMenus]);

  useEffect(() => {
    if (isErrorPostRole) {
      const errorMsg: any = errorPostRole;

      setIsLoading(false);
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data?.code === 42210 ? errorMsg?.message.errors[0] : errorMsg?.data || errorMsg).message}`} />);
    }

    if (isSuccessPostRole) {
      setIsLoading(false);
      toast.success(<Toast message={t('general.success_text')} detailedMessage={t('manage_group.succes_add_form')} />);
      navigate('/manage-group');
    }
  }, [isErrorPostRole, isSuccessPostRole]);

  useEffect(() => {
    if (!isLoadingGetRoleById) {
      const errorMsg: any = errorGetRoleById;

      if (dataGetRoleById) {
        if (dataGetRoleById?.error) {
          toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(dataGetRoleById?.data).message}`} />);
          return;
        }

        form.setFieldsValue({ ...form, name: dataGetRoleById?.name, cluster_type: dataGetRoleById?.cluster_type });
        setIsActive(dataGetRoleById?.is_active);
        setIsVip(dataGetRoleById?.is_vip);
        setIsAdminView(dataGetRoleById?.is_admin);
        getMenusById(dataGetRoleById?.id);
      }

      if (isErrorGetRoleById) {
        toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data || errorGetRoleById).message}`} />);
        return;
      }
    }
  }, [dataGetRoleById, isLoadingGetRoleById, isErrorGetRoleById, errorGetRoleById]);

  useEffect(() => {
    if (isErrorPutRole) {
      const errorMsg: any = errorPutRole;

      setIsLoading(false);
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data?.code === 42210 ? errorMsg?.message.errors[0] : errorMsg?.data || errorMsg).message}`} />);
    }

    if (isSuccessPutRole) {
      setIsLoading(false);
      toast.success(<Toast message={t('general.success_text')} detailedMessage={t('manage_group.succes_edit_form')} />);
      navigate('/manage-group');
    }
  }, [isErrorPutRole, isSuccessPutRole]);

  useEffect(() => {
    if (isErrorGetRoleById) {
      const errorMsg: any = errorGetRoleById;

      setIsLoading(false);
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data?.code === 42210 ? errorMsg?.message.errors[0] : errorMsg?.data || errorMsg).message}`} />);
    }
  }, [isErrorGetRoleById]);

  useEffect(() => {
    if (isErrorMenuByRoleId) {
      const errorMsg: any = errorMenuByRoleId;

      setIsLoading(false);
      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data?.code === 42210 ? errorMsg?.message.errors[0] : errorMsg?.data || errorMsg).message}`} />);
    }
  }, [isErrorMenuByRoleId]);

  return (
    <Spin spinning={isLoadingGetRoleById}>
      <Form layout='vertical' form={form} name="group_form" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <div className="bg-white p-[16px] md:p-[24px] rounded-lg mb-5">
          <p className='font-bold text-[16px] mb-5'>{t('manage_group.group_info_label')}</p>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
            <div className='col-span-1'>
              <Form.Item
                label={t('manage_group.group_name_label')}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('manage_group.group_info_label') + t('manage_group.required_field') || '',
                  },
                ]}
                className='font-bold'
              >
                <Input className='font-normal' placeholder={t('manage_group.group_name_placeholder') || ''} disabled={isDetail} />
              </Form.Item>
            </div>
            <div className='col-span-1'>
              <Form.Item
                label={t('manage_group.column.is_active')}
                className='font-bold'
              >
                <Switch className='bg-slate-300' checked={isActive} onChange={() => setIsActive(!isActive)} disabled={isDetail} />
              </Form.Item>
            </div>
          </div>
          <hr className='mt-5 mb-5' style={{ height: '10px', borderWidth: '0', color: 'gray', backgroundColor: '#E5EDE5' }} />
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5'>
            <div className='col-span-1'>
              <Form.Item
                label={t('manage_group.admin_view_label')}
                className='font-bold'
              >
                <Checkbox checked={isAdminView} onChange={() => {
                  setIsAdminView(!isAdminView);
                  form.setFieldsValue({ ...form, cluster_type: null });
                }} disabled={isDetail}></Checkbox>
              </Form.Item>
            </div>
            <div className='col-span-1'>
              <Form.Item
                label='VIP'
                className='font-bold'
              >
                <Checkbox checked={isVip} onChange={onChangeIsVip} disabled={isDetail}></Checkbox>
              </Form.Item>
            </div>
            <div className='col-span-1 md:col-span-2'>
              <Form.Item
                label={t('manage_group.cluster_type_label')}
                name='cluster_type'
                className='font-bold'
              >
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  className='font-normal'
                  placeholder={t('manage_group.cluster_type_placeholder')}
                  disabled={isDetail || isAdminView}
                  options={CLUSTER_TYPE}
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <Spin spinning={isLoadingGetMenuByRoleId || isLoadingMenus}>
          <div className="bg-white p-[16px] md:p-[24px] rounded-lg mb-5 overflow-auto">
            <div className='text-[12px] w-[700px] lg:w-full'>
              <div className='grid grid-cols-12 bg-[#E5EDE5] py-[12px] px-[10px] rounded-lg font-bold'>
                <p className='col-span-8'>Menu</p>
                <p>Create</p>
                <p>Read</p>
                <p>Update</p>
                <p>Delete</p>
              </div>
              {listMenu.map((data: any, idx: any) => (
                <div key={idx} className={`grid grid-cols-12 py-[15.5px] px-[10px] ${idx % 2 === 0 ? '' : 'bg-[#F1FDF1]'}`}>
                  <p className={`col-span-8 font-normal  ${data.level === 1 ? 'pl-10' : ''}`}>{data.name}</p>
                  <Checkbox checked={data?.role_menu_permission?.can_create} onChange={() => onChangeCheckMenu('can_create', idx)} disabled={isDetail}></Checkbox>
                  <Checkbox checked={data?.role_menu_permission?.can_view} onChange={() => onChangeCheckMenu('can_view', idx)} disabled={isDetail}></Checkbox>
                  <Checkbox checked={data?.role_menu_permission?.can_update} onChange={() => onChangeCheckMenu('can_update', idx)} disabled={isDetail}></Checkbox>
                  <Checkbox checked={data?.role_menu_permission?.can_delete} onChange={() => onChangeCheckMenu('can_delete', idx)} disabled={isDetail}></Checkbox>
                </div>
              ))}
            </div>
          </div>
        </Spin>
        <div className='flex flex-row justify-end mt-5 pb-10'>
          <Form.Item>
            <Row className='h-full'>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Button
                  className='cancel-green bg-[#E5EDE5] text-[#55A853] border-[#55A853]'
                  htmlType="button"
                  onClick={() => { navigate('/manage-group'); }}
                >
                  {t('general.cancel_btn')}
                </Button>
                {!isDetail ? <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: 'white',
                    },
                  }}>
                  <Button
                    className='submit ml-5 bg-[#55A853] text-white'
                    htmlType="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? <Spin /> : t('general.submit_btn') || ''}
                  </Button>
                </ConfigProvider> : <></>}
              </Col>
            </Row>
          </Form.Item>
        </div>
      </Form>
    </Spin>
  );
};

export default ManageGroupAdd;