import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { toast } from 'react-toastify';
import { Spin } from 'antd';
import { useCompaniesMutation } from 'api/Dashboard';

const Companies = (props:any) => {
  const { year } = props;

  interface Company {
    id?: string,
    name: string,
    code: string,
    image: string
  }

  const { t } = useTranslation();
  const [data, setData] = useState<Company[]>([]);
  const navigate = useNavigate();

  const [companies, {
    isLoading: isLoadingCompanies,
    isError: isErrorCompanies,
    error: errorCompanies
  }] = useCompaniesMutation();

  const handleClickDetailCompany = (id:any) => {
    navigate(`/dashboard/company-detail/${id}/${year}`);
  };

  const fetchList = async () => {
    const payload = {
      year
    };
    const data = await companies(payload).unwrap();
    
    setData(data.map((el: any) => ({
      id: el?.id,
      name: el?.name,
      image: el?.logo_image || null,
      code: el?.code
    })));
  };

  useEffect(() => {
    fetchList();
  }, []);


  useEffect(() => {
    if (isErrorCompanies) {
      const errorMsg: any = errorCompanies;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorCompanies]);

  return(
    <Spin tip="Loading" size="large" spinning={isLoadingCompanies}>
      <div>
        {
          !data.length ? 
            (
              <div className='flex justify-center'>
                <div>
                  <img src='images/img_no_data.svg' alt='img-no-data'/>
                  <p className='mt-5 text-[#AAAAAA] text-[12px] text-center'>{t('general.no_data')}</p>
                </div>
              </div>
            ) 
            : 
            (
              <div className='grid md:grid-cols-2 lg:grid-cols-5 xl:grid-cols-6 gap-5'>
                {
                  data.map((el: Company) => (
                    <div className='border-[1px] h-[200px] border-[#EDEDED] rounded-lg flex justify-center items-center'>
                      <div>
                        {
                          el.image ? 
                            (
                              <div className='flex justify-center mb-3'>
                                <img 
                                  src={(process.env.REACT_APP_BASE_URL + 'images/' + el.image)} 
                                  alt=''
                                  loading='lazy'
                                  width={120}
                                />
                              </div>
                            ) 
                            : 
                            (<p className='text-center font-bold text-[40px] mb-3'>{el.code}</p>)
                        }
                        <p className='text-center font-bold mb-3'>{el.name}</p>
                        <div className='flex justify-center text-[#54A853] cursor-pointer' onClick={() => {handleClickDetailCompany(el.id);}}>
                          <p>{t('dashboard.companies.btn_detail')}</p>
                          <FaArrowRight className='ml-2 my-auto' />
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            )
        }
      </div>
    </Spin>
  );
};

export default Companies;