import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Collapse, Input, Spin } from 'antd';
import { useEffect, useState } from 'react';
import ProgressBar from 'components/ProgressBar';
import moment from 'moment';
import { CiCircleChevDown } from 'react-icons/ci';
import { CiCircleChevRight } from 'react-icons/ci';
import { IoMdClose } from 'react-icons/io';
import { useAppSelector } from 'store/Hooks';
import { useDelActionPlanMutation, useGetActionPlanMutation, useGetCommentByActionMutation, usePostActionPlanCommentMutation, usePostActionPlanMutation, usePutActionPlanCheckMutation, usePutActionPlanMutation } from 'api/ComplianceMonitoring';
import { toast } from 'react-toastify';
import Toast from 'components/Toast';
import { HiOutlinePencil } from 'react-icons/hi';
import { FaChevronDown } from 'react-icons/fa';

interface IActionPlanSectionProps{
  id: string;
}

interface IComment {
  name: string;
  time: Date;
  message: string;
}

interface IActionPlan {
  id: string;
  name: string;
  check: boolean;
  isEdit: boolean;
  comments: IComment[];
  total_comments: number;
}

interface INewActionPlan {
  name: string;
  isLoadingSubmit: boolean;
}

interface INewActionPlanComment {
  message: string;
  isLoadingSubmit: boolean;
}

const ActionPlanSection = ({id}: IActionPlanSectionProps) => {
  const { user } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();

  const [getActionPlan, { isLoading: isLoadingGetActionPlan }] = useGetActionPlanMutation();
  const [getCommentByAction, { isLoading: isLoadingGetCommentByAction}] = useGetCommentByActionMutation();
  const [postActionPlan] = usePostActionPlanMutation();
  const [postActionPlanComment] = usePostActionPlanCommentMutation();
  const [putActionPlan] = usePutActionPlanMutation();
  const [putActionPlanCheck] = usePutActionPlanCheckMutation();
  const [deleteActionPlan] = useDelActionPlanMutation();

  const [activeKey, setActiveKey] = useState<string[]>([]);
  const [actionPlans, setActionPlans] = useState<IActionPlan[]>([]);
  const [newComments, setNewComments] = useState<INewActionPlanComment[]>([]);
  const [editActionPlans, setEditActionPlans] = useState<INewActionPlan[]>([]);
  const [newActionPlans, setNewActionPlans] = useState<INewActionPlan[]>([]);
  const [currentCheckedAction, setCurrentCheckedAction] = useState<string>('');
  const [currentProgress, setCurrentProgress] = useState<number>(0);
  const [viewMoreIndex, setViewMoreIndex] = useState<number>(0);

  const addNewActionPlan = () => {
    setNewActionPlans((prev) => [
      ...prev,
      {
        name: '',
        isLoadingSubmit: false,
      }
    ]);
  };

  const updateNewActionPlanName = (index: number, value: string) => {
    setNewActionPlans(prev =>
      prev.map((item, i) => i === index ? { ...item, name: value } : item)
    );
  };

  const submitNewActionPlan = async (index: number) => {
    const newAction = newActionPlans[index];
    const submitForm = {
      action_plan: {
        permit_request_id: id,
        action_plan: newAction.name || '',
      }
    };
  
    try {
      setNewActionPlans(prev =>
        prev.map((item, i) => i === index ? { ...item, isLoadingSubmit: true } : item)
      );
      
      const response = await postActionPlan(submitForm).unwrap();
      setActionPlans((prev) => [
        ...prev,
        {
          id: response.id,
          name: newAction.name,
          check: false,
          isEdit: false,
          comments: [],
          total_comments: 0,
        }
      ]);
      setNewActionPlans(prev => prev.filter((_, i) => i !== index));
    } catch {
      setNewActionPlans(prev =>
        prev.map((item, i) => i === index ? { ...item, isLoadingSubmit: false } : item)
      );
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={t('compliance_monitoring.error.action_plan_create_failed')}
        />
      );
    }
  };

  const handleNameSubmit = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && newActionPlans[index].name.trim() !== '') {
      submitNewActionPlan(index);
    }
  };

  const toggleCollapse = (key: string) => {
    setActiveKey(prev => prev.includes(key) ? prev.filter(item => item !== key) : [...prev, key]);
  };

  const removeActionPlan = async (index: number) => {
    try {
      await deleteActionPlan(actionPlans[index].id).unwrap();
      setActionPlans(prev => prev.filter((_, i) => i !== index));
      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('compliance_monitoring.success.action_plan_deleted')}`}
        />
      );
    } catch {
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={t('compliance_monitoring.error.action_plan_delete_failed')}
        />
      );
    }
  };

  const updateActionPlanCheck = async (index: number, value: boolean) => {
    try {
      const response = await putActionPlanCheck({
        id: actionPlans[index].id,
        body: {
          check_action: {
            is_check: value
          }
        }
      }).unwrap();
      setActionPlans(prev =>
        prev.map((item, i) => i === index ? { ...item, check: value } : item)
      );
      if(value){
        setCurrentCheckedAction(actionPlans[index].name);
      } else {
        setCurrentCheckedAction(response.last_check_action);
      }
      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('compliance_monitoring.success.action_plan_checked')}`}
        />
      );
    } catch {
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={t('compliance_monitoring.error.action_plan_check_failed')}
        />
      );
    }
  };

  const addComment = async (index: number) => {
    const comment = newComments[index]?.message.trim();
    if (!comment) return;
  
    try {
      setNewComments(prev =>
        prev.map((item, i) => i === index ? { ...item, isLoadingSubmit: true } : item)
      );
  
      const submitForm = {
        action_plan_comment: {
          action_plan_id: actionPlans[index].id,
          comment,
        }
      };
  
      const response = await postActionPlanComment(submitForm).unwrap();
      setActionPlans(prev =>
        prev.map((item, i) => 
          i === index ? {
            ...item, 
            comments: [
              {
                name: user?.display_name || 'New User',
                message: comment,
                time: new Date(response.created_at),
              },
              ...item.comments,
            ]
          } : item
        )
      );
      setNewComments(prev =>
        prev.map((item, i) => i === index ? { ...item, message: '', isLoadingSubmit: false } : item)
      );
    } catch {
      setNewComments(prev =>
        prev.map((item, i) => i === index ? { ...item, isLoadingSubmit: false } : item)
      );
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={t('compliance_monitoring.error.comment_add_failed')}
        />
      );
    }
  };

  const startEditingActionPlan = (index: number) => {
    setActionPlans(prev =>
      prev.map((item, i) => i === index ? { ...item, isEdit: true } : item)
    );
  };
  
  const updateEditActionPlanName = (index: number, value: string) => {
    setEditActionPlans(prev =>
      prev.map((item, i) => i === index ? { ...item, name: value } : item)
    );
  };

  const submitEditActionPlan = async (index: number) => {
    const editedActionPlan = editActionPlans[index];
    const submitForm = {
      action_plan: {
        action_plan: editedActionPlan.name || '',
      }
    };
  
    try {
      setEditActionPlans(prev =>
        prev.map((item, i) => i === index ? { ...item, isLoadingSubmit: true } : item)
      );
  
      await putActionPlan({ id: actionPlans[index].id, body: submitForm }).unwrap();
      setActionPlans(prev =>
        prev.map((item, i) => i === index ? { ...item, name: editedActionPlan.name, isEdit: false } : item)
      );

      toast.success(
        <Toast
          message={t('general.success_text')}
          detailedMessage={`${t('compliance_monitoring.success.action_plan_updated')}`}
        />
      );
    } catch {
      setEditActionPlans(prev =>
        prev.map((item, i) => i === index ? { ...item, isLoadingSubmit: false } : item)
      );
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={t('compliance_monitoring.error.action_plan_update_failed')}
        />
      );
    }
  };
  
  const handleEditNameSubmit = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && editActionPlans[index].name.trim() !== '') {
      submitEditActionPlan(index);
    }
  };

  const handleViewMoreComment = async (index: number) => {
    try {
      setViewMoreIndex(index);
      const payload = {
        action_plan_id: actionPlans[index].id,
        page: 1,
        pageSize: actionPlans[index].comments.length + 10,
        sort: '-created_at'
      };

      const response = await getCommentByAction(payload).unwrap();

      setActionPlans(prev =>
        prev.map((item, i) => i === index ? { 
          ...item, 
          comments: response?.rows?.map((comment: any) => ({
            name: comment.user?.display_name || 'New User',
            time: new Date(comment.created_at),
            message: comment.comment || '',
          })) || [] 
        } : item)
      );
    } catch (error) {
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={t('compliance_monitoring.fetch_more_comment_failure')}
        />
      );
    }
  };

  const fetchActionPlans = async (id: string) => {
    try {
      const response = await getActionPlan(id).unwrap();
      
      const formatted_data: IActionPlan[] = response?.actions?.map((row: any) => ({
        id: row.id || '',
        name: row.action_plan || '',
        check: row.is_checked || false,
        is_edit: false,
        comments: row.comments?.rows?.map((comment: any) => ({
          name: comment.user.display_name || '',
          time: new Date(comment.created_at),
          message: comment.comment || '',
        })) || [],
        total_comments: row.comments?.total || 0,
      })) || [];
  
      setActionPlans(formatted_data);
      setCurrentCheckedAction(response.last_checked);
      setCurrentProgress(response.progress || 0);
    } catch (error) {
      toast.error(
        <Toast
          message={t('general.error_text')}
          detailedMessage={t('compliance_monitoring.fetch_action_plans_failure')}
        />
      );
    }
  };
  
  
  useEffect(() => {
    if (id) {
      fetchActionPlans(id);
    }
  }, [id]);
  
  useEffect(() => {
    setNewComments(actionPlans.map(() => ({
      message: '',
      isLoadingSubmit: false,
    })));
    setEditActionPlans(actionPlans.map((data) => ({
      name: data.name,
      isLoadingSubmit: false,
    })));
    
    const totalChecked = actionPlans.reduce((acc, curr) => acc + (curr.check ? 1 : 0), 0);
    const progress = actionPlans.length > 0 ? (totalChecked * 100 / actionPlans.length) : 0;
    setCurrentProgress(progress);
  }, [actionPlans]);

  return (
    <Spin tip="Loading" size="large" spinning={isLoadingGetActionPlan}>
      <div>
        <div className='bg-[#FDF0D8] p-3 rounded-lg flex items-center justify-between'>
          <span className='font-bold text-base'>{ t('compliance_monitoring.action_plan') }</span>
        </div>
        <div className='mt-4 mb-8'>
          <div className='flex gap-2 items-center mb-2'>
            <span>{t('compliance_monitoring.progress')}</span>
            <span>/</span>
            <span>{currentCheckedAction || '-'}</span>
          </div>
          <ProgressBar percent={currentProgress} color="#9c50fe" />
        </div>
        <div className='bg-[#d7d7d7] h-[1px] mb-4 mt-4'/>
        <div className="font-bold">{t('compliance_monitoring.checklist')}</div>
        <div className="mt-4">
          {actionPlans.map((data, index) => (
            <Collapse 
              key={index} 
              activeKey={activeKey}
              expandIconPosition="end" 
              bordered={false} 
              className="bg-[#f9f9fa] mb-4"
            >
              <Collapse.Panel 
                header={
                  <div className='flex justify-between items-center gap-4'>
                    {data.isEdit ? 
                      (
                        <>
                          <div className='flex-grow'>
                            <input
                              type="text"
                              placeholder={t('compliance_monitoring.add_an_item') || ''}
                              value={editActionPlans[index].name}
                              onChange={(e) => updateEditActionPlanName(index, e.target.value)}
                              onKeyDown={(e) => handleEditNameSubmit(index, e)}
                              className="w-full bg-transparent border-none outline-none p-0 m-0 placeholder-gray-400"
                            />
                          </div>
                          <Button
                            type="text"
                            className='cancel-green bg-[#E5EDE5] text-[#55A853] border-[#55A853]'
                            htmlType="button"
                            onClick={() => submitEditActionPlan(index)}
                            loading={editActionPlans[index].isLoadingSubmit}
                          >
                            {t('general.save_btn')}
                          </Button>
                        </>
                      ) : 
                      (
                        <>
                          <div className='flex gap-4 flex-grow'>
                            <Checkbox checked={data.check} onChange={(e) => updateActionPlanCheck(index, e.target.checked)} />
                            <div className='flex-grow flex gap-8 items-center'>
                              <span className="font-bold">{data.name}</span>
                              {!data.check && data.comments.length === 0 && (
                                <div onClick={() => startEditingActionPlan(index)}>
                                  <HiOutlinePencil className='text-xl' />
                                </div>
                              )}
                            </div>
                          </div>
                          {!data.check && data.comments.length === 0 && (
                            <div onClick={() => removeActionPlan(index)}>
                              <IoMdClose className='text-2xl text-red-500' />
                            </div>
                          )}
                          <div onClick={() => toggleCollapse(index.toString())}>
                            {activeKey.includes(index.toString()) ? (
                              <CiCircleChevDown className='text-xl' />
                            ) : (
                              <CiCircleChevRight className='text-xl' />
                            )}
                          </div>
                        </>
                      )
                    }
                  </div>
                } 
                key={index}
              >
                <div className='mt-2 flex flex-col gap-4'>
                  {data.comments.map((comment, idx) => (
                    <div key={idx}>
                      <div className='flex items-center gap-2'>
                        <span className='font-bold'>{comment.name}</span>
                        <span className='text-[#9e9e9e]'>{moment(comment.time).format('dddd [at] hh:mm A')}</span>
                      </div>
                      <div>{comment.message}</div>
                    </div>
                  ))}
                  {
                    data.total_comments > data.comments.length && (
                      <div 
                        className='flex justify-center items-center gap-4 border-2 cursor-pointer hover:bg-slate-100 p-1' 
                        onClick={() => handleViewMoreComment(index)}
                      >
                        {isLoadingGetCommentByAction && viewMoreIndex === index 
                          ? (<div>loading</div>) 
                          : (
                            <>
                              <span>View More</span>
                              <FaChevronDown />
                            </>
                          )}
                      </div>
                    )}
                </div>
                <div className='flex gap-4 mt-4'>
                  <Input.TextArea 
                    autoSize 
                    className='flex-grow' 
                    placeholder={t('compliance_monitoring.add_comment') || ''} 
                    value={newComments?.[index]?.message || ''}
                    onChange={(e) => {
                      const updatedNewComments = [...newComments];
                      updatedNewComments[index].message = e.target.value;
                      setNewComments(updatedNewComments);
                    }}
                  />
                  <Button
                    type="text"
                    className='cancel-green bg-[#E5EDE5] text-[#55A853] border-[#55A853]'
                    htmlType="button"
                    onClick={() => addComment(index)}
                    loading={newComments[index]?.isLoadingSubmit}
                  >
                    {t('general.send')}
                  </Button>
                </div>
              </Collapse.Panel>
            </Collapse>
          ))}
          {newActionPlans.map((data, index) => (
            <div key={index} className='flex justify-between items-center gap-4 bg-[#f9f9fa] mb-4 p-4'>
              <div className='flex-grow'>
                <input
                  type="text"
                  placeholder={t('compliance_monitoring.add_an_item') || ''}
                  value={data.name}
                  onChange={(e) => updateNewActionPlanName(index, e.target.value)}
                  onKeyDown={(e) => handleNameSubmit(index, e)}
                  className="w-full bg-transparent border-none outline-none p-0 m-0 placeholder-gray-400"
                />
              </div>
              <Button
                type="text"
                className='cancel-green bg-[#E5EDE5] text-[#55A853] border-[#55A853]'
                htmlType="button"
                onClick={() => submitNewActionPlan(index)}
                loading={data.isLoadingSubmit}
              >
                {t('general.save_btn')}
              </Button>
            </div>
          ))}
        </div>
        <div>
          <Button
            type="text"
            className="bg-[#55A853] text-white font-bold"
            htmlType="button"
            onClick={addNewActionPlan}
          >
            {t('compliance_monitoring.add_item')}
          </Button>
        </div>
      </div>
    </Spin>
  );
};

export default ActionPlanSection;